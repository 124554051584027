import { Component } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { PdfService } from 'data-access';
import { selectSelectedOrganization } from 'data-access';
import { filter } from 'rxjs';
import { environment, IOrganization, IPdfTemplate } from 'shared';

@Component({
  selector: 'app-project-diary',
  templateUrl: './project-diary.component.html',
  styleUrls: ['./project-diary.component.scss']
})
export class ProjectDiaryComponent {
  organization?: IOrganization;
  pdfTemplates?: IPdfTemplate[];
  selectedTemplateId?: string;
  isProduction: boolean = environment.production;

  constructor(private pdfService: PdfService, private store: Store) {
    this.store
      .pipe(
        select(selectSelectedOrganization),
        filter(org => org != undefined)
      )
      .subscribe(org => {
        this.organization = org;
        this.pdfService.getPdfTemplates().subscribe(templates => {
          if (templates) {
            this.pdfTemplates = templates;
          }
        });
      });
  }

  selectTemplate(templateId: string) {
    this.selectedTemplateId = templateId;
  }
}
