import { Component, Inject } from '@angular/core';
import { AbstractDiaryEntryForm } from '../../abstract-diary-entry-form/abstract-diary-entry-form.component';
import { DiaryEntryType, IObservantEntry, IObservantEntryRequest, RESOURCE_KEY } from 'shared';
import { IObservantDiaryEntryFormGroup } from '../../abstract-diary-entry-form/abstract-entry-form-groups.interface';
import { FormBuilder } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { Store } from '@ngrx/store';
import { ResourceService } from 'data-access';

@Component({
  selector: 'app-observant-diary-entry-form',
  templateUrl: './observant-diary-entry-form.component.html',
  styleUrls: ['./observant-diary-entry-form.component.scss']
})
export class ObservantDiaryEntryFormComponent extends AbstractDiaryEntryForm<
  IObservantEntry,
  IObservantEntryRequest,
  IObservantDiaryEntryFormGroup
> {
  constructor(
    @Inject(RESOURCE_KEY) protected override resourceService: ResourceService,
    protected override store: Store,
    private fb: FormBuilder,
    private datePipe: DatePipe
  ) {
    super(resourceService, store);
  }

  getEntryRequestObj(): IObservantEntryRequest {
    return {
      type: DiaryEntryType.OBSERVANT,
      date: this.datePipe.transform(this.form.controls.date.value, 'YYYY-mm-dd')!,
      observations: this.entryForm.controls.observations.value,
      minTemp: this.entryForm.controls.minTemp.value,
      maxTemp: this.entryForm.controls.maxTemp.value,
      otherMeasures: this.entryForm.controls.otherMeasures.value,
      weather: this.entryForm.controls.weather.value
    };
    throw new Error('Method not implemented.');
  }
}

//
// private convertToObservantEntry(form: FormGroup<IObservantDiaryEntryFormGroup>): IObservantEntryRequest {
//   return {
//     type: DiaryEntryType.OBSERVANT,
//     date: this.datePipe.transform(form.get('date')?.value, 'YYYY-mm-dd') ?? undefined,
//
//     observations: form.get('observations')?.value ?? undefined,
//
//     minTemp: form.get('minTemp')?.value ?? undefined,
//     maxTemp: form.get('maxTemp')?.value ?? undefined,
//     otherMeasures: form.get('otherMeasures')?.value ?? undefined,
//     weather: form.get('weather')?.value ?? ''
//   };
// }
