import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest } from 'rxjs';
import { IDiaryObject, IProject } from 'shared';
import { ProjectsService } from '../../services/projects.service';

@Component({
  selector: 'app-project-landing',
  templateUrl: './project-landing.component.html',
  styleUrls: ['./project-landing.component.scss']
})
export class ProjectLandingComponent implements OnInit {
  project?: IProject;
  previewDiaryObjects: IDiaryObject[] = [];
  previewDiaryObjectsLoading = true;

  constructor(private projectService: ProjectsService, private router: Router, private routes: ActivatedRoute) {
    combineLatest([this.projectService.getProjects(), this.routes.paramMap]).subscribe(([projects, params]) => {
      // try to resolve the project by query params if it is undefined
      if (this.project === undefined && projects !== undefined) {
        const project = projects.find(project => project.id === params.get('projectId'));
        if (project) {
          this.projectService.selectProject(project);
        } else {
          this.router.navigate(['../not-found'], { relativeTo: this.routes });
        }
      }
    });
  }

  ngOnInit() {
    this.projectService.getSelectedProject().subscribe(project => {
      this.project = project;
    });
  }

  castString(str: string): string {
    if (!str || str.trim() === '') {
      return '/';
    } else {
      return str;
    }
  }

  openPage(url: string) {
    this.router.navigateByUrl(url);
  }
}
