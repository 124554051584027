import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import { IPostProjectRequest, IProject } from 'shared';

export enum ProjectActionTypes {
  // single project actions
  LoadProject = '[Project] Load',
  LoadProjectSuccess = '[Project] Load Success',
  LoadProjectError = '[Project] Load Error',

  AddProject = '[Project] Add Trigger',
  AddProjectSuccess = '[Project] Add Success',
  AddProjectError = '[Project] Add Error',

  UpdateProject = '[Project] Update Trigger',
  UpdateProjectSuccess = '[Project] Update Success',
  UpdateProjectError = '[Project] Update Error',

  SelectProject = '[Project] Select',
  UnselectProject = '[Project] Unselect',

  // all project actions
  LoadAllProjects = '[Projects] Load All',
  ReloadAllProjects = '[Projects] Reload All',
  LoadAllProjectsSuccess = '[Projects] All Loaded Success',
  LoadAllProjectsError = '[Projects] All Loaded Error',

  // state control
  ResetProjectState = '[Projects] Reset State',
  ResetSelectedProjectState = '[Project] Reset Selected Project States',
  RestoreLastProject = '[Project] Restore Project From LocalStorage',
  RestoreLastProjectSuccess = '[Project] Restore Project Success',
  RestoreLastProjectError = '[Project] Restore Project Error'
}

/** LOAD PROJECT */

export class LoadProject implements Action {
  readonly type = ProjectActionTypes.LoadProject;
}

export class LoadProjectSuccess implements Action {
  readonly type = ProjectActionTypes.LoadProjectSuccess;

  constructor(public payload: { project: IProject }) {}
}

export class LoadProjectError implements Action {
  readonly type = ProjectActionTypes.LoadProjectError;

  constructor(public payload: { error: any }) {}
}

/** ADD PROJECT */

export class AddProject implements Action {
  readonly type = ProjectActionTypes.AddProject;

  constructor(public payload: { project: IPostProjectRequest }) {}
}

export class AddProjectSuccess implements Action {
  readonly type = ProjectActionTypes.AddProjectSuccess;

  constructor(public payload: { project: IProject }) {}
}

export class AddProjectError implements Action {
  readonly type = ProjectActionTypes.AddProjectError;

  constructor(public payload: { error: HttpErrorResponse }) {}
}

/** UPDATE PROJECT */

export class UpdateProject implements Action {
  readonly type = ProjectActionTypes.UpdateProject;

  constructor(public payload: { project: IProject }) {}
}

export class UpdateProjectSuccess implements Action {
  readonly type = ProjectActionTypes.UpdateProjectSuccess;

  constructor(public payload: { project: IProject }) {}
}

export class UpdateProjectError implements Action {
  readonly type = ProjectActionTypes.UpdateProjectError;

  constructor(public payload: { error: HttpErrorResponse }) {}
}

/** SELECT PROJECT */

export class SelectProject implements Action {
  readonly type = ProjectActionTypes.SelectProject;

  constructor(public payload: { projectId: string }) {}
}

export class UnselectProject implements Action {
  readonly type = ProjectActionTypes.UnselectProject;
}

/** LOAD PROJECTs */

export class LoadAllProjects implements Action {
  readonly type = ProjectActionTypes.LoadAllProjects;
}

export class ReloadAllProjects implements Action {
  readonly type = ProjectActionTypes.ReloadAllProjects;
}

export class LoadAllProjectsSuccess implements Action {
  readonly type = ProjectActionTypes.LoadAllProjectsSuccess;

  constructor(public payload: { projects: IProject[] }) {}
}

export class LoadAllProjectsError implements Action {
  readonly type = ProjectActionTypes.LoadAllProjectsError;

  constructor(public payload: { error: any }) {}
}

/** STATE CONTROL */

export class ResetProjectState implements Action {
  readonly type = ProjectActionTypes.ResetProjectState;
}

export class ResetSelectedProjectState implements Action {
  readonly type = ProjectActionTypes.ResetSelectedProjectState;
}

export class RestoreLastProject implements Action {
  readonly type = ProjectActionTypes.RestoreLastProject;
}

export class RestoreLastProjectSuccess implements Action {
  readonly type = ProjectActionTypes.RestoreLastProjectSuccess;

  constructor(public payload: { project: IProject }) {}
}

export class RestoreLastProjectError implements Action {
  readonly type = ProjectActionTypes.RestoreLastProjectError;
}

export type ProjectActions =
  | LoadProject
  | LoadProjectSuccess
  | LoadProjectError
  | AddProject
  | AddProjectSuccess
  | AddProjectError
  | UpdateProject
  | UpdateProjectSuccess
  | UpdateProjectError
  | SelectProject
  | UnselectProject
  | LoadAllProjects
  | ReloadAllProjects
  | LoadAllProjectsSuccess
  | LoadAllProjectsError
  | ResetProjectState
  | ResetSelectedProjectState
  | RestoreLastProject
  | RestoreLastProjectSuccess
  | RestoreLastProjectError;
