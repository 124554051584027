import { Component, ElementRef, HostBinding, Inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { distinctUntilChanged, Observable, Subject, take, takeUntil } from 'rxjs';
import { ICompany, IMember, IProject, IUser, RESOURCE_KEY } from 'shared';
import { ResourceService, selectSelectedProject } from 'data-access';
import { Store } from '@ngrx/store';
import { FormControl, FormGroup } from '@angular/forms';
import { IDiarySearchComponentParams, DiarySearchService } from '../diary-search-service/diary-search.service';
import { Calendar } from 'primeng/calendar';

export interface IDiarySearchFormGroup {
  query: FormControl<string | null | undefined>;
  companies: FormControl<ICompany[] | null | undefined>;
  users: FormControl<IUser[] | null | undefined>;
  dateRange: FormControl<Date[] | null | undefined>;
}

@Component({
  selector: 'app-diary-search',
  templateUrl: './diary-search.component.html',
  styleUrls: ['./diary-search.component.scss']
})
export class DiarySearchComponent implements OnDestroy {
  protected project?: IProject;

  @ViewChild('calendarInput') calendarInput?: Calendar;
  @Input() isMobile: boolean = false;
  @HostBinding('class.mobile-diary-search-box') get mobileClass() {
    return !this.isMobile;
  }
  private readonly destroyer$ = new Subject<void>();

  protected diarySearchForm: FormGroup<IDiarySearchFormGroup> = new FormGroup({
    query: new FormControl<string | null | undefined>(undefined),
    companies: new FormControl<ICompany[] | null | undefined>([], []),
    users: new FormControl<IUser[] | null | undefined>([]),
    dateRange: new FormControl<Date[] | null | undefined>([])
  });
  protected calendarDefaultDate = new Date();

  constructor(
    @Inject(RESOURCE_KEY) private resourceService: ResourceService,
    private store: Store,
    private diarySearchService: DiarySearchService,
    private elementRef: ElementRef
  ) {
    this.diarySearchService
      .searchParams()
      .pipe(takeUntil(this.destroyer$), distinctUntilChanged())
      .subscribe(searchParams => {
        this.setFormParams(searchParams);
      });
    this.diarySearchForm.valueChanges.pipe(distinctUntilChanged()).subscribe(parmas => {
      this.diarySearchService.setSearchParams(parmas as IDiarySearchComponentParams);
    });
    this.store.select(selectSelectedProject).subscribe(project => {
      this.project = project;
      if (project) {
        this.calendarDefaultDate = project.endDate;
      }
    });
  }

  setFormParams(searchParams?: IDiarySearchComponentParams) {
    this.diarySearchForm.controls.query.setValue(searchParams?.query);
    this.diarySearchForm.controls.companies.setValue(searchParams?.companies);
    this.diarySearchForm.controls.users.setValue(searchParams?.users);
    this.diarySearchForm.controls.dateRange.setValue(searchParams?.dateRange);
  }

  protected fetchCompanies: (query: string) => Observable<ICompany[]> = (query: string): Observable<ICompany[]> => {
    return this.resourceService.getProjectCompanies(this.project!.id, query);
  };

  protected fetchProjectMembers: () => Observable<IMember[]> = (): Observable<IMember[]> => {
    return this.resourceService.getProjectMembersObs(this.project!.id);
  };

  public getComponentSize(): { height: number; width: number } {
    const element = this.elementRef.nativeElement as HTMLElement;
    return {
      height: element.offsetHeight,
      width: element.offsetWidth
    };
  }

  protected getCalendarSelectedDateRange(): Date[] | undefined {
    const dates = this.diarySearchForm.controls.dateRange.value;
    if (dates?.some(date => date !== null)) {
      return this.diarySearchForm.controls.dateRange.value!;
    }
    return undefined;
  }

  protected clearCalendarInput() {
    this.diarySearchForm.controls.dateRange.setValue(undefined);
  }

  clearFilters() {
    this.setFormParams();
  }

  getMaxTopDate(): Date {
    let date = new Date();
    if (this.project) {
      if (date > this.project.endDate) {
        date = this.project.endDate;
      }
    }
    return date;
  }

  ngOnDestroy(): void {
    this.destroyer$.next();
    this.destroyer$.complete();
  }
}
