import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import * as OrganizationActions from 'data-access';
import { Store } from '@ngrx/store';
import { selectSelectedOrganization } from 'data-access';
import { filter, take } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OrganizationResolverService implements Resolve<any> {
  constructor(private store: Store) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const orgId = route.paramMap.get('organizationId') ?? '';
    if (orgId) {
      this.store.dispatch(OrganizationActions.restoreOrganizationAction({ organizationId: orgId }));
      return this.store.select(selectSelectedOrganization).pipe(
        filter(org => !!org),
        filter(org => org?.id === orgId),
        take(1)
      );
    }
    return undefined;
  }
}
