<div class="page">
  <div class="page-content">
    <p-progressSpinner *ngIf="loading; else ProjectDetailsTemplate"></p-progressSpinner>
  </div>
</div>

<ng-template #ProjectDetailsTemplate>
  <div class="details-row justify-content-between align-items-start no-wrap">
    <div class="details-container">
      <h3>Projektname</h3>
      <p>{{ project?.name }}</p>
    </div>
    <button pButton class="p-button-raised p-button-sm" label="Bearbeiten" (click)="onEditProject()"></button>
  </div>

  <div class="details-row">
    <div class="details-container">
      <h3>Laufzeit</h3>
      <p>{{ project?.startDate | date : 'dd.MM.yyyy' }} - {{ project?.endDate | date : 'dd.MM.yyyy' }}</p>
    </div>
  </div>

  <div class="details-row">
    <div class="details-container">
      <h3>Bauauftrag</h3>
      <p>{{ project?.constructionTask }}</p>
    </div>
    <div class="details-container">
      <h3>Projektnummer</h3>
      <p>{{ project?.number }}</p>
    </div>
  </div>

  <div class="details-row">
    <div class="details-container">
      <h3>Auftraggeber</h3>
      <p>{{ project?.commissioningAuthority }}</p>
    </div>
    <div class="details-container">
      <h3>Bauüberwachende Stelle</h3>
      <p>{{ project?.supervisoryAuthority }}</p>
    </div>
  </div>

  <div class="details-row">
    <div class="details-container">
      <h3>Projektbezeichnung</h3>
      <p>{{ project?.description }}</p>
    </div>
  </div>
</ng-template>
