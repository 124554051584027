import { NgModule } from '@angular/core';
import { SharedPageModule } from 'shared';
import { MatChipsModule } from '@angular/material/chips';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { ProjectUserInputComponent } from './project-user-input.component';

@NgModule({
  imports: [MatChipsModule, SharedPageModule, CommonModule, FormsModule, DropdownModule],
  exports: [ProjectUserInputComponent],
  declarations: [ProjectUserInputComponent]
})
export class ProjectUserInputModule {}
