import { NgModule } from '@angular/core';
import { FileDownloadDialogComponent } from './file-download-dialog/file-download-dialog.component';
import { ProgressBarModule } from 'primeng/progressbar';
import { AppMessageModule } from '../../modules/message/app-message.module';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [CommonModule, ProgressBarModule, AppMessageModule],
  declarations: [FileDownloadDialogComponent],
  exports: []
})
export class FileDownloadModule {}
