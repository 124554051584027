<div class="page">
  <div class="page-content">
    <p-progressSpinner *ngIf="loading"></p-progressSpinner>

    <div *ngIf="!loading">
      <h2 class="mb-4 mt-0">Vollständige PDF-Exports</h2>
      <div class="flex flex-column gap-4 mb-2">
        <app-project-template-export-item
          *ngFor="let template of templates"
          [template]="template"></app-project-template-export-item>
      </div>

      <!--      <h2>Angepasste PDF-Exports</h2>-->
    </div>
  </div>
</div>
