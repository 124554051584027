import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectSelectedOrganization } from 'data-access';
import { Observable, throwError } from 'rxjs';
import { catchError, filter, map, switchMap, take } from 'rxjs/operators';
import { ORGANIZATION_TOKEN_SERVICE } from 'shared';
import { CoreOrganizationInterceptor, TokenService } from 'data-access';

@Injectable()
export class WebOrganizationInterceptor extends CoreOrganizationInterceptor implements HttpInterceptor {
  constructor(
    @Inject(ORGANIZATION_TOKEN_SERVICE) protected organizationTokenService: TokenService,
    protected store: Store
  ) {
    super();
    this.store.select(selectSelectedOrganization).subscribe(org => (this.organization = org));
  }

  intercept(request: any, next: HttpHandler): Observable<HttpEvent<any>> {
    // check if the url starts with the organizationUrl -> otherwise add it
    const url = this.setOrganizationUrl(request);
    request = request.clone({ url });

    // Check if we need additional token logic or not
    if (this.isInBlockedList(request.url)) {
      return next.handle(request);
    } else {
      return this.organizationTokenService.getAccessToken().pipe(
        filter(token => token != undefined),
        take(1),
        switchMap(token =>
          next.handle(this.addToken(request, token!)).pipe(
            catchError(err => {
              if (err instanceof HttpErrorResponse) {
                switch (err.status) {
                  //case 0:
                  // all non standard error responses
                  //this.router.navigateByUrl("/not-responding")
                  // case 400:
                  //     return this.handle400Error(err);
                  case 401:
                    return this.handle401Error(request, next);
                  default:
                    return throwError(() => err);
                }
              } else {
                return throwError(() => err);
              }
            }),
            map((val: HttpEvent<any>) => {
              if (val instanceof HttpResponse) {
                const body = val.body;
                this.convert(body);
              }
              return val;
            })
          )
        )
      );
    }
  }

  protected handle401Error(request: any, next: any): Observable<any> {
    return this.organizationTokenService.reAuthorize().pipe(
      filter(token => token != undefined),
      switchMap(token => next.handle(this.addToken(request, token)))
    );
  }
}
