import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { DOCUMENT } from '@angular/common';
import { Directive, ElementRef, EventEmitter, Inject, Input, OnInit, Output, Renderer2 } from '@angular/core';

import { DropzoneOverlayComponent } from './dropzone-overlay/dropzone-overlay.component';

@Directive({
  selector: '[dropzone]',
  exportAs: 'dropzone'
})
export class DropzoneDirective implements OnInit {
  @Output() onFilesDropped: EventEmitter<File[]> = new EventEmitter<File[]>();
  @Output() onObjectDropped: EventEmitter<any> = new EventEmitter<any>();
  @Input() validDropTypes?: string[];

  hiddenInput?: HTMLInputElement;

  private _overlayRef?: OverlayRef;

  constructor(
    private _elementRef: ElementRef,
    private overlay: Overlay,
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {
    this._elementRef.nativeElement.addEventListener('dragenter', this.showOverlay.bind(this), false);
    this.initHiddenInput();
  }

  private initHiddenInput() {
    if (!this.hiddenInput) {
      this.hiddenInput = this.document.createElement('input');
      this.hiddenInput.type = 'file';
      this.hiddenInput.hidden = true;
      this.hiddenInput.multiple = true;
      if (this.validDropTypes) {
        this.hiddenInput.accept = this.validDropTypes.toString();
      }
      this.hiddenInput.addEventListener('change', this.onFileDrop.bind(this));
      this.renderer.appendChild(this._elementRef.nativeElement, this.hiddenInput);
    }
  }

  private showOverlay() {
    if (!this._overlayRef) {
      let elemRect: DOMRect = this._elementRef.nativeElement.getBoundingClientRect();

      const positionStrategy = this.overlay
        .position()
        .global()
        .left(elemRect.left + 'px')
        .top(elemRect.top + 'px');

      this._overlayRef = this.overlay.create({
        positionStrategy,
        width: elemRect.width,
        height: elemRect.height
      });
      this._overlayRef.overlayElement.addEventListener('dragleave', this.hideOverlay.bind(this), false);
      this._overlayRef.overlayElement.addEventListener('drop', this.onDrop.bind(this), false);
      this._overlayRef.overlayElement.addEventListener('dragover', this.onDragOver.bind(this), false);
    }
    if (this._overlayRef && !this._overlayRef.hasAttached()) {
      this._overlayRef.attach(new ComponentPortal(DropzoneOverlayComponent));
    }
  }

  private hideOverlay() {
    if (this._overlayRef) {
      this._overlayRef.detach();
      this._overlayRef = undefined;
    }
  }

  private onDragOver(e: any) {
    e.stopPropagation();
    e.preventDefault();
    e.dataTransfer.dropEffect = 'copy';
  }
  private onDrop(e: any) {
    e.preventDefault();
    e.stopPropagation();
    let object = e.dataTransfer.getData('object');
    if (object) {
      this.onObjectDropped.emit(object);
    } else {
      this.onFileDrop(e);
    }
    this.hideOverlay();
  }

  public triggerFileInput() {
    if (this.hiddenInput) {
      this.hiddenInput.value = '';
      this.hiddenInput.click();
    }
  }

  private onFileDrop(e: any) {
    let fileList: FileList = e.dataTransfer ? e.dataTransfer.files : e.target.files;
    let files = Array.from(fileList);
    // optional filter for non valid file types
    if (this.validDropTypes != undefined) {
      files = files.filter(file => this.validDropTypes?.includes(file.type));
    }
    if (files.length > 0) {
      this.onFilesDropped.emit(files);
    }
  }
}
