import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { IMember } from 'shared';

export const memberAdapter: EntityAdapter<IMember> = createEntityAdapter<IMember>({});

export const initialMembersState: MemberState = memberAdapter.getInitialState({
  allMembersLoaded: false
});

export interface MemberState extends EntityState<IMember> {
  allMembersLoaded: boolean;
}

export function getInitialMember(): Partial<IMember> {
  return {
    id: undefined,
    name: undefined,
    picture: undefined,
    email: undefined,
    firstName: undefined,
    lastName: undefined,
    phone: undefined,
    roles: [],
    license: undefined
  };
}
