import { Action } from '@ngrx/store';
import { ICompany } from 'shared';

export enum CompanyActionTypes {
  // project companies actions
  LoadProjectCompanies = '[Companies] Load Project Companies',
  LoadProjectCompaniesSuccess = '[Companies] Load Project Companies Success',
  LoadProjectCompaniesError = '[Companies] Load Project Companies Error',
  AddProjectCompanies = '[Companies] Add Project Companies',

  // project companies actions
  RemoveProjectCompanies = '[Companies] Remove Project Companies',

  // state control
  ResetCompaniesState = '[Companies] Reset State'
}

export class LoadProjectCompanies implements Action {
  readonly type = CompanyActionTypes.LoadProjectCompanies;
}

export class LoadProjectCompaniesSuccess implements Action {
  readonly type = CompanyActionTypes.LoadProjectCompaniesSuccess;

  constructor(public payload: { companies: ICompany[] }) {}
}

export class LoadProjectCompaniesError implements Action {
  readonly type = CompanyActionTypes.LoadProjectCompaniesError;

  constructor(public payload: { error: any }) {}
}

export class AddProjectCompanies implements Action {
  readonly type = CompanyActionTypes.AddProjectCompanies;

  constructor(public payload: { companies: ICompany[] }) {}
}

export class RemoveProjectCompanies implements Action {
  readonly type = CompanyActionTypes.RemoveProjectCompanies;

  constructor(public payload: { companyIds: string[] }) {}
}

export class ResetCompaniesState implements Action {
  readonly type = CompanyActionTypes.ResetCompaniesState;
}

export type CompanyActions =
  | LoadProjectCompanies
  | LoadProjectCompaniesSuccess
  | LoadProjectCompaniesError
  | AddProjectCompanies
  | RemoveProjectCompanies
  | ResetCompaniesState;
