import {
  AfterContentInit,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  DoCheck,
  EventEmitter,
  Inject,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  Self,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ControlValueAccessor, FormControl, NgControl, ReactiveFormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslateModule } from '@ngx-translate/core';
import { RESOURCE_KEY, TouchedDirtyDirective } from 'shared';
import { MultiSelect, MultiSelectModule } from 'primeng/multiselect';
import { PrimeTemplate, SelectItem } from 'primeng/api';
import {
  BehaviorSubject,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  map,
  Observable,
  ReplaySubject,
  Subject,
  takeUntil,
  throwError
} from 'rxjs';
import { ResourceService } from 'data-access';

@Component({
  selector: 'lazy-multiselect',
  templateUrl: './lazy-multiselect.component.html',
  styleUrls: ['./lazy-multiselect.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    TranslateModule,
    TouchedDirtyDirective,
    MultiSelectModule
  ]
})
export class LazyMultiselectComponent<T> implements OnInit, AfterContentInit, DoCheck, OnDestroy, ControlValueAccessor {
  @Input() label: string = '';
  @Input() filter: boolean = false;
  @Input() queryParams: any = {};
  @Input() filterDebounce: number = 300;
  @Input() filterPlaceHolder: string = '';
  @Input() placeHolder: string = '';
  @Input() valueLabel?: string;
  @Input() fetchPath?: string;
  @Input() icon?: string;
  @Output() onSelectionChange: EventEmitter<T> = new EventEmitter<T>();
  protected selectedItemsTemplate?: TemplateRef<any>;
  protected itemTemplate?: TemplateRef<any>;
  protected loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  protected searchStr$: ReplaySubject<string> = new ReplaySubject<string>(1);
  protected options$: BehaviorSubject<T[]> = new BehaviorSubject<T[]>([]);
  protected control?: FormControl<T[] | null | undefined>;
  protected selectedOptions: BehaviorSubject<T[]> = new BehaviorSubject<T[]>([]);
  protected options: SelectItem<T | undefined>[] = [];
  private initialLoadPerformed: boolean = false;
  private readonly _destroyer$ = new Subject<void>();

  @ViewChild('multiSelect') multiSelect?: MultiSelect;
  @ContentChildren(PrimeTemplate) protected templates?: QueryList<PrimeTemplate>;
  @Input() fetchFunction: (query: string) => Observable<T[]> = (query: string) => {
    if (this.fetchPath) {
      if (query) {
        this.queryParams.query = query;
      } else {
        delete this.queryParams.query;
      }
    }
    return throwError(() => new Error('No fetchPath or fetchFunction was given'));
  };
  @Input() itemComparator: (item1: T | undefined, item2: T | undefined) => boolean = (item1, item2) => {
    return this.idItemComparator(item1, item2);
  };

  constructor(
    @Inject(RESOURCE_KEY) private resourceService: ResourceService,
    @Self() public controlDir: NgControl,
    private ngZone: NgZone,
    private cd: ChangeDetectorRef
  ) {
    controlDir.valueAccessor = this;
    this.control = new FormControl<T[] | undefined>([], []);
    this.searchStr$.pipe(takeUntil(this._destroyer$), debounceTime(this.filterDebounce)).subscribe(query => {
      this.loading$.next(true);
      return this.fetchFunction(query)
        .pipe(takeUntil(this._destroyer$))
        .subscribe({
          next: fetchedOptions => {
            // make sure that all selected options are also in the new fetched options
            const selectedOptions = this.selectedOptions.value ?? [];
            const distinctFetchedOptions = fetchedOptions.filter(
              fetchedOption => !selectedOptions.some(o => this.itemComparator(o, fetchedOption))
            );
            const options = [...selectedOptions, ...distinctFetchedOptions];

            this.options$.next(options);
            this.loading$.next(false);
          },
          error: err => {
            this.options$.next([]);
            this.loading$.next(false);
            throw new Error(err);
          }
        });
    });
  }

  get optionObs(): Observable<SelectItem<T | undefined>[]> {
    return combineLatest([
      this.options$.asObservable(),
      this.selectedOptions.asObservable(),
      this.loading$.asObservable()
    ]).pipe(
      takeUntil(this._destroyer$),
      distinctUntilChanged(),
      map(([options, selectedOptions, loading]) => {
        if (loading) {
          const arr: any[] = [{ value: undefined, disabled: true, isLoader: true }];
          if (selectedOptions) {
            arr.unshift(
              ...selectedOptions.map(co => {
                return { value: co, disabled: true, isLoader: false };
              })
            );
            // { value: currentOptions, disabled: true, isLoader: false });
          }
          return arr;
        }
        return options.map(option => {
          return { value: option, disabled: loading, isLoader: false };
        });
      })
    );
  }

  ngOnInit(): void {
    this.control?.setValidators(this.controlDir.control!.validator);
  }

  ngDoCheck() {
    if (!this.control?.touched && this.controlDir.control?.touched) {
      this.control?.markAsTouched();
    }
  }

  ngAfterContentInit() {
    (this.templates as QueryList<PrimeTemplate>).forEach(item => {
      switch (item.getType()) {
        case 'item':
          this.itemTemplate = item.template;
          break;

        case 'selectedItems':
          this.selectedItemsTemplate = item.template;
          break;
      }
    });
    this.optionObs.pipe(takeUntil(this._destroyer$)).subscribe(options => {
      this.options = [...options];
      if (this.multiSelect) {
        this.multiSelect._filteredOptions = options;
      }
    });
    this.control?.valueChanges.pipe(takeUntil(this._destroyer$)).subscribe(value => {
      this.selectedOptions.next(value!);
    });
  }

  ngOnDestroy(): void {
    this._destroyer$.next();
    this._destroyer$.complete();
  }

  public reset(): void {
    this.initialLoadPerformed = false;
    this.options$.next([]);
  }

  public writeValue(values: T[]) {
    this.control?.setValue(values);
    this.selectedOptions.next(values);
    if (values != null) {
      // filter options that are already there
      const newOptions = this.options$.value;
      const distinctValues = values.filter(val => !newOptions.some(opt => this.itemComparator(opt, val)));
      if (distinctValues.length > 0) {
        newOptions.unshift(...distinctValues);
        this.options$.next(newOptions);
      }
    }
  }

  public setDisabledState(disabled: boolean) {
    disabled ? this.control?.disable() : this.control?.enable();
  }

  public onChange = (value: T) => {};

  public onTouched: () => void = () => {};

  public registerOnChange(fn: any): void {
    this.control?.valueChanges.subscribe(fn);
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  initData() {
    if (!this.initialLoadPerformed) {
      this.searchStr$.next('');
      this.initialLoadPerformed = true;
    }
  }

  public hasItemSelected(): boolean {
    return (
      this.selectedOptions.value !== undefined &&
      this.selectedOptions.value !== null &&
      this.selectedOptions.value.length > 0
    );
  }

  private idItemComparator(item1: T | undefined, item2: T | undefined): boolean {
    // If both items are undefined, return true
    if (item1 === undefined && item2 === undefined) {
      return true;
    }
    // If only one of them is undefined, return false
    if (item1 === undefined || item2 === undefined) {
      return false;
    }
    return (item1 as { id: any }).id === (item2 as { id: any }).id;
  }
}
