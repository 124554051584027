import { ImageActions, ImageActionTypes } from './image.actions';
import { imagesAdapter, ImagesState, initialImagesState } from './image.state';

export function imagesReducer(state = initialImagesState, action: ImageActions): ImagesState {
  switch (action.type) {
    case ImageActionTypes.LoadImageSuccess:
      return imagesAdapter.addOne(action.payload.image, state);

    case ImageActionTypes.LoadAllImagesSuccess:
      return imagesAdapter.addMany(action.payload.images, {
        ...state,
        allImagesLoaded: true
      });

    case ImageActionTypes.ResetImagesState:
      return imagesAdapter.getInitialState(initialImagesState);

    case ImageActionTypes.AddImageSuccess:
      return imagesAdapter.addOne(action.payload.image, state);

    case ImageActionTypes.UpdateImage:
      return imagesAdapter.updateOne(
        {
          id: action.payload.image.id,
          changes: action.payload.image
        },
        state
      );

    case ImageActionTypes.RemoveImage:
      return imagesAdapter.removeOne(action.payload.id, state);

    default: {
      return state;
    }
  }
}
