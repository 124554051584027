import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dropzone-overlay',
  templateUrl: './dropzone-overlay.component.html',
  styleUrls: ['./dropzone-overlay.component.scss']
})
export class DropzoneOverlayComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
