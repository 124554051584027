import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors } from '@angular/forms';

@Directive({
  selector: '[positiveNumberRequired]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: PositiveNumberValidator,
      multi: true
    }
  ]
})
export class PositiveNumberValidator {
  constructor() {}

  validate(control: AbstractControl): ValidationErrors | null {
    if (control.value != undefined && control.value >= 0) {
      return null;
    }
    return { invalidNumberError: null };
  }
}
