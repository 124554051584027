import { Directive, ElementRef, HostBinding } from '@angular/core';

@Directive({
  standalone: true,
  selector: '[touchedDirty]'
})
export class TouchedDirtyDirective {
  @HostBinding('class.ng-dirty') isDirty = false;

  constructor(elRef: ElementRef) {
    this.observer.observe(elRef.nativeElement, {
      attributes: true,
      attributeFilter: ['class'],
      childList: false,
      characterData: false
    });
  }

  observer = new MutationObserver((event: any) => {
    const classes: DOMTokenList = event[0].target.classList;
    this.isDirty = classes.contains('ng-touched');
  });
}
