import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProjectDiaryRoutingModule } from './project-diary-routing.module';
import { ProjectDiaryComponent } from './project-diary.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { SharedPageModule } from 'libs/shared/src/lib/modules/shared-page.module';
import { SharedModule } from 'shared';
import { FeatureDiaryModule } from '../../../diary/feature-diary.module';

@NgModule({
  imports: [
    SharedPageModule,
    CommonModule,
    FormsModule,
    ProjectDiaryRoutingModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatProgressBarModule,
    DragDropModule,
    ReactiveFormsModule,
    FeatureDiaryModule,
    SharedModule
  ],
  providers: [],
  declarations: [ProjectDiaryComponent]
})
export class ProjectDiaryModule {}
