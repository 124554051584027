import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, combineLatest, map, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AnnouncementsService {
  private lastAnnouncementsDate: BehaviorSubject<Date> = new BehaviorSubject<Date>(
    new Date(Date.parse('2024-08-06T01:00:00'))
  );
  private lastAnnouncementsChecked: BehaviorSubject<Date | undefined | null> = new BehaviorSubject<
    Date | undefined | null
  >(undefined);

  constructor() {
    const dateStr = localStorage.getItem('lastAnnouncementsCheckDate');
    if (dateStr) {
      this.lastAnnouncementsChecked.next(new Date(Date.parse(dateStr)));
    }
  }

  public setAnnouncementsRead() {
    const date = this.lastAnnouncementsDate.value;
    localStorage.setItem('lastAnnouncementsCheckDate', date.toISOString());
    this.lastAnnouncementsChecked.next(date);
  }

  public hasNewAnnouncement(): Observable<boolean> {
    return combineLatest([this.lastAnnouncementsDate, this.lastAnnouncementsChecked]).pipe(
      map(([lastAnnouncementsDate, lastAnnouncementsChecked]) => {
        if (!lastAnnouncementsChecked) {
          return true;
        }
        return lastAnnouncementsDate.getTime() > lastAnnouncementsChecked?.getTime();
      })
    );
  }
}
