<div class="image-flex">
  <div
    class="image-item"
    *ngFor="let image of images; let i = index; trackBy: trackByImageId"
    (click)="openImageViewer(i)">
    <img [src]="image.id | secure : project!.id : false | async | handleUndefinedImage" [alt]="image.description" />
    <div class="image-item-description">
      <p>{{ image.description }}</p>
    </div>
  </div>
</div>
