import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { IUploadTask } from 'shared';

export const uploadAdapter: EntityAdapter<IUploadTask> = createEntityAdapter<IUploadTask>({});

export const initialUploadState: UploadState = uploadAdapter.getInitialState({
  uploadInProgress: false
});

export interface UploadState extends EntityState<IUploadTask> {
  uploadInProgress: boolean;
}
