import { selectAllProjectMembersLoaded } from './member.selectors';
import {
  LoadAllProjectMembers,
  LoadAllProjectMembersError,
  LoadAllProjectMembersSuccess,
  MemberActionTypes
} from './member.actions';
import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { catchError, filter, map, mergeMap, of, withLatestFrom } from 'rxjs';
import { selectSelectedProject } from 'data-access';
import { RESOURCE_KEY } from 'shared';
import { ResourceService } from 'data-access';

@Injectable()
export class MemberEffects {
  constructor(
    private actions$: Actions,
    @Inject(RESOURCE_KEY) private resourceService: ResourceService,
    private store: Store
  ) {}

  loadAllProjectMembers$ = createEffect(() =>
    this.actions$.pipe(
      ofType<LoadAllProjectMembers>(MemberActionTypes.LoadAllProjectMembers),
      withLatestFrom(
        this.store.pipe(select(selectAllProjectMembersLoaded)),
        this.store.pipe(select(selectSelectedProject))
      ),
      filter(([action, allMembersLoaded, project]) => !allMembersLoaded && project != undefined),
      mergeMap(([action, allMembersLoaded, project]) =>
        this.resourceService.getProjectMembersObs(project?.id!).pipe(
          map(members => new LoadAllProjectMembersSuccess({ members: members })),
          catchError(error => of(new LoadAllProjectMembersError({ error: error })))
        )
      )
    )
  );
}
