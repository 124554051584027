import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hexToRGBA'
})
export class hexToRGBAPipe implements PipeTransform {
  ALPHA = 0.5;

  transform(hex: string) {
    var r = parseInt(hex.slice(1, 3), 16),
      g = parseInt(hex.slice(3, 5), 16),
      b = parseInt(hex.slice(5, 7), 16);
    return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + this.ALPHA + ')';
  }
}

@Pipe({
  name: 'hexToRGBTint'
})
export class hexToRGBTintPipe implements PipeTransform {
  TINT_FACTOR = 0.3;

  transform(hex: string) {
    var r = parseInt(hex.slice(1, 3), 16) + (255 - parseInt(hex.slice(1, 3), 16)) * this.TINT_FACTOR;
    var g = parseInt(hex.slice(3, 5), 16) + (255 - parseInt(hex.slice(3, 5), 16)) * this.TINT_FACTOR;
    var b = parseInt(hex.slice(5, 7), 16) + (255 - parseInt(hex.slice(5, 7), 16)) * this.TINT_FACTOR;
    return 'rgb(' + r + ', ' + g + ', ' + b + ')';
  }
}

@Pipe({
  name: 'hexToRGB'
})
export class hexToRGBPipe implements PipeTransform {
  TINT_FACTOR = 0.1;

  transform(hex: string) {
    var r = parseInt(hex.slice(1, 3), 16),
      g = parseInt(hex.slice(3, 5), 16),
      b = parseInt(hex.slice(5, 7), 16);
    return 'rgb(' + r + ', ' + g + ', ' + b + ')';
  }
}
