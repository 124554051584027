import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { selectSelectedProject } from 'data-access';
import { DIALOG_SERVICE_KEY, DialogService, IProject } from 'shared';

import {
  ProjectDetailsDialogData,
  ProjectEditDialogComponent
} from './project-edit-dialog/project-edit-dialog.component';

@Component({
  selector: 'app-project-details',
  templateUrl: './project-details.component.html',
  styleUrls: ['./project-details.component.scss']
})
export class ProjectDetailsComponent implements OnInit {
  loading = true;
  public project?: IProject;

  constructor(private store: Store, @Inject(DIALOG_SERVICE_KEY) private dialogService: DialogService) {}

  ngOnInit() {
    this.store.select(selectSelectedProject).subscribe(data => {
      this.project = data;
      this.loading = false;
    });
  }

  onEditProject() {
    this.dialogService.openDialog<ProjectEditDialogComponent, ProjectDetailsDialogData, IProject>(
      ProjectEditDialogComponent,
      { data: { project: this.project } }
    );
  }
}
