<h3 class="divider-header">Schicht</h3>
<form [formGroup]="entryForm" (ngSubmit)="form.valid ? saveEntry() : form.markAllAsTouched()" *ngIf="entryForm">
  <div class="inputs-scroll">
    <div class="input-row">
      <div class="field flex-b-50">
        <lazy-dropdown
          [fetchFunction]="fetchCompanies"
          [filter]="true"
          formControlName="company"
          aria-describedby="companyHelp"
          [style]="{ width: '100%' }"
          label="{{ 'contractor-subcontractor' | translate }}"
          id="companyInput"
          touchedDirty
          class="p-inputwrapper-filled">
          <ng-template let-company pTemplate="item">
            <div class="flex align-items-center gap-2">
              <div>{{ company.name }}</div>
            </div>
          </ng-template>
          <ng-template let-company pTemplate="selectedItem">
            <div class="flex align-items-center gap-2">
              <div>{{ company.name }}</div>
            </div>
          </ng-template>
        </lazy-dropdown>
        <small
          id="companyHelp"
          *ngIf="entryForm.controls.company?.invalid && entryForm.controls.company?.touched"
          class="p-error"
          >{{ 'invalid-input' | translate }}</small
        >
      </div>
      <div class="input-row no-wrap flex-b-50">
        <div class="field">
          <div class="p-float-label">
            <p-calendar
              id="shiftStartInput"
              formControlName="shiftStart"
              [touchUI]="mobile"
              [readonlyInput]="mobile"
              [timeOnly]="true"
              [hourFormat]="'24'"
              [stepMinute]="5"
              [showIcon]="true"
              appendTo="body"
              icon="pi pi-clock"
              [style]="{ width: '100%' }"
              aria-describedby="shiftStartHelp"
              class="p-inputwrapper-filled"
              touchedDirty>
            </p-calendar>
            <label for="shiftStartInput">{{ 'start-of-shift' | translate }}</label>
          </div>
          <small
            id="shiftStartHelp"
            *ngIf="form.get('shiftStart')?.invalid && form.get('shiftStart')?.touched"
            class="p-error"
            >{{ 'invalid-input' | translate }}</small
          >
        </div>
        <div class="field">
          <div class="p-float-label">
            <p-calendar
              formControlName="shiftEnd"
              [touchUI]="mobile"
              [readonlyInput]="mobile"
              [timeOnly]="true"
              [hourFormat]="'24'"
              [stepMinute]="5"
              [showIcon]="true"
              id="shiftEndInput"
              aria-describedby="shiftEndInput"
              appendTo="body"
              touchedDirty
              icon="pi pi-clock"
              [style]="{ width: '100%' }"
              class="p-inputwrapper-filled">
            </p-calendar>
            <label for="shiftEndInput">{{ 'end-of-shift' | translate }}</label>
          </div>
          <small
            id="shiftEndHelp"
            *ngIf="form.get('shiftEnd')?.invalid && form.get('shiftEnd')?.touched"
            class="p-error"
            >{{ 'invalid-input' | translate }}</small
          >
        </div>
      </div>
    </div>

    <!--#############  WEATHER  ##############-->

    <h3 class="divider-header">{{ 'weather' | translate }}</h3>
    <div class="input-row">
      <div class="input-row field flex-b-50">
        <div class="p-float-label">
          <textarea
            pInputTextarea
            formControlName="weather"
            [autoResize]="true"
            id="weatherInput"
            class="p-filled w-full"
            aria-describedby="weatherHelp"
            touchedDirty
            rows="1"></textarea>
          <label for="weatherInput">{{ 'weather' | translate }}</label>
        </div>
        <small id="weatherHelp" *ngIf="form.get('weather')?.invalid && form.get('weather')?.touched" class="p-error">{{
          'invalid-input' | translate
        }}</small>
      </div>
      <div class="input-row flex-b-50">
        <div class="field">
          <div class="p-float-label">
            <p-inputNumber
              formControlName="minTemp"
              id="minTempInput"
              class="p-inputwrapper-filled w-full"
              aria-describedby="minTempHelp"
              suffix=" ℃"
              touchedDirty
              styleClass="w-full"
              [style]="{ width: '100%' }"
              [inputStyle]="{ width: '100%' }">
            </p-inputNumber>
            <label for="minTempInput">{{ 'min-temp' | translate }}</label>
          </div>
          <small
            id="minTempHelp"
            *ngIf="form.get('minTemp')?.invalid && form.get('minTemp')?.touched"
            class="p-error"
            >{{ 'invalid-input' | translate }}</small
          >
        </div>
        <div class="field">
          <div class="p-float-label">
            <p-inputNumber
              formControlName="maxTemp"
              id="maxTempInput"
              aria-describedby="maxTempHelp"
              class="p-inputwrapper-filled w-full"
              suffix=" ℃"
              touchedDirty
              styleClass="w-full"
              [style]="{ width: '100%' }"
              [inputStyle]="{ width: '100%' }">
            </p-inputNumber>
            <label for="maxTempInput">{{ 'max-temp' | translate }}</label>
          </div>
          <small
            id="maxTempHelp"
            *ngIf="form.get('maxTemp')?.invalid && form.get('maxTemp')?.touched"
            class="p-error"
            >{{ 'invalid-input' | translate }}</small
          >
        </div>
      </div>
    </div>
    <div>
      <div class="field">
        <div class="p-float-label">
          <textarea
            pInputTextarea
            formControlName="otherMeasures"
            [autoResize]="true"
            id="otherMeasuresInput"
            class="p-filled w-full"
            touchedDirty
            rows="1"
            aria-describedby="otherMeasuresHelp">
          </textarea>
          <label for="otherMeasuresInput">{{ 'other-measurements' | translate }}</label>
        </div>
        <small
          id="otherMeasuresHelp"
          class="p-error"
          *ngIf="form.get('otherMeasures')?.invalid && form.get('otherMeasures')?.touched"
          >Error</small
        >
      </div>
    </div>

    <!--#############  WORKFORCE  ##############-->

    <h3 class="divider-header">{{ 'staff' | translate }}</h3>
    <div class="input-row">
      <div class="input-row no-wrap flex-b-50">
        <div class="field">
          <div class="p-float-label">
            <textarea
              pInputTextarea
              formControlName="workforce"
              id="workforceInput"
              [autoResize]="true"
              rows="1"
              aria-describedby="workforceHelp"
              class="p-filled w-full"
              touchedDirty>
            </textarea>
            <label for="workforceInput">{{ 'workforce' | translate }}</label>
          </div>
          <small
            id="workforceHelp"
            *ngIf="form.get('workforce')?.invalid && form.get('workforce')?.touched"
            class="p-error"
            >{{ 'invalid-input' | translate }}</small
          >
        </div>
        <div class="field">
          <div class="p-float-label">
            <textarea
              pInputTextarea
              formControlName="sipo"
              id="sipoInput"
              [autoResize]="true"
              rows="1"
              aria-describedby="sipoHelp"
              class="p-filled w-full"
              touchedDirty>
            </textarea>
            <label for="sipoInput">{{ 'sipo' | translate }}</label>
          </div>
          <small id="sipoHelp" *ngIf="form.get('sipo')?.invalid && form.get('sipo')?.touched" class="p-error">{{
            'invalid-input' | translate
          }}</small>
        </div>
      </div>
      <div class="field flex-b-50">
        <div class="p-float-label">
          <textarea
            pInputTextarea
            formControlName="specials"
            [autoResize]="true"
            id="specialsInput"
            class="p-filled w-full"
            aria-describedby="specialsHelp"
            touchedDirty
            rows="1"></textarea>
          <label for="specialsInput">{{ 'specials' | translate }}</label>
        </div>
        <small
          id="specialsHelp"
          *ngIf="form.get('specials')?.invalid && form.get('specials')?.touched"
          class="p-error"
          >{{ 'invalid-input' | translate }}</small
        >
      </div>
    </div>

    <!--#############  WORK  ##############-->

    <h3 class="divider-header">{{ 'procedure' | translate }}</h3>
    <div class="flex">
      <div class="field">
        <div class="p-float-label">
          <textarea
            pInputTextarea
            formControlName="doneWork"
            [autoResize]="true"
            id="doneWorkInput"
            class="p-filled w-full"
            aria-describedby="doneWorkHelp"
            touchedDirty
            rows="3"></textarea>
          <label for="doneWorkInput">{{ 'work-performed' | translate }}</label>
        </div>
        <small
          id="doneWorkHelp"
          *ngIf="form.get('doneWork')?.invalid && form.get('doneWork')?.touched"
          class="p-error"
          >{{ 'invalid-input' | translate }}</small
        >
      </div>
    </div>

    <div class="flex">
      <div class="field">
        <div class="p-float-label">
          <textarea
            pInputTextarea
            formControlName="machines"
            [autoResize]="true"
            id="machinesInput"
            class="p-filled w-full"
            aria-describedby="machinesHelp"
            touchedDirty
            rows="2"></textarea>
          <label for="machinesInput">{{ 'equipment-component-materials' | translate }}</label>
        </div>
        <small
          id="machinesHelp"
          *ngIf="form.get('machines')?.invalid && form.get('machines')?.touched"
          class="p-error"
          >{{ 'invalid-input' | translate }}</small
        >
      </div>
    </div>

    <div class="flex">
      <div class="field">
        <div class="p-float-label">
          <textarea
            pInputTextarea
            formControlName="supply"
            [autoResize]="true"
            id="supplyInput"
            class="p-filled w-full"
            aria-describedby="supplyHelp"
            touchedDirty
            rows="2"></textarea>
          <label for="supplyInput">{{ 'supply-and-disposal' | translate }}</label>
        </div>
        <small id="supplyHelp" *ngIf="form.get('supply')?.invalid && form.get('supply')?.touched" class="p-error">{{
          'invalid-input' | translate
        }}</small>
      </div>
    </div>
  </div>

  <!--  <app-message-->
  <!--    *ngIf="savedSuccessful"-->
  <!--    type="success"-->
  <!--    [summary]="'project' | translate"-->
  <!--    [detail]="'saved-successfully' | translate"-->
  <!--    (close)="savedSuccessful = false"></app-message>-->

  <!--  <app-message-->
  <!--    *ngIf="httpError"-->
  <!--    [summary]="'project' | translate"-->
  <!--    [detail]="httpError.error?.message ?? 'Eintrag konnte nicht gespeichert werden'"-->
  <!--    (close)="httpError = undefined"></app-message>-->
</form>
