import { HttpHeaders, HttpRequest } from '@angular/common/http';
import { IOrganization } from 'shared';

const TIME_FIELDS: string[] = ['endDate', 'startDate', 'modifiedAt', 'createdAt', 'shiftStart', 'shiftEnd', 'date'];

export class CoreOrganizationInterceptor {
  protected organization?: IOrganization;

  setOrganizationUrl(request: HttpRequest<any>) {
    let url = request.url;
    if (!!this.organization?.api && !request.url.startsWith('http')) {
      url = this.organization.api;
      // make sure url does not end with a "/"
      if (url.endsWith('/')) {
        url = url.slice(0, -1);
      }
      url += request.url.startsWith('/') ? request.url : '/' + request.url;
    }

    return url;
  }

  // Filter out URLs where you don't want to add the token!
  protected isInBlockedList(url: string): boolean {
    if (url.endsWith('/status')) {
      return true;
    }
    return false;
  }

  protected addToken(req: HttpRequest<any>, token?: string): HttpRequest<any> {
    if (token) {
      return req.clone({
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + token
        })
      });
    }
    return req;
  }

  convert(body: any) {
    if (body === null || body === undefined) {
      return body;
    }
    if (typeof body !== 'object') {
      return body;
    }
    for (const key of Object.keys(body)) {
      const value = body[key];
      if (TIME_FIELDS.includes(key)) {
        // TODO add regex for time field detection
        if (['shiftStart', 'shiftEnd'].includes(key)) {
          body[key] = new Date();
          body[key].setHours(value.substring(0, 2), value.substring(3, 5), value.substring(6, 8));
        } else {
          // date string
          body[key] = new Date(value);
          // correct the timezone offset
          body[key].setTime(body[key].getTime() + body[key].getTimezoneOffset() * 60 * 1000);
        }
      } else if (typeof value === 'object') {
        this.convert(value);
      }
    }
  }
}
