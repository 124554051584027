import { Component, Inject, OnInit } from '@angular/core';
import { AbstractDiaryEntryForm } from '../../abstract-diary-entry-form/abstract-diary-entry-form.component';
import { FormControl } from '@angular/forms';
import { IRegularDiaryEntryFormGroup } from '../../abstract-diary-entry-form/abstract-entry-form-groups.interface';
import { DiaryEntryType, ICompany, IRegularEntry, IRegularEntryRequest, RESOURCE_KEY } from 'shared';
import { Observable } from 'rxjs';
import { DatePipe } from '@angular/common';
import { Store } from '@ngrx/store';
import { ResourceService } from 'data-access';

@Component({
  selector: 'app-regular-diary-entry-form',
  templateUrl: './regular-diary-entry-form.component.html',
  styleUrls: ['./regular-diary-entry-form.component.scss']
})
export class RegularDiaryEntryFormComponent
  extends AbstractDiaryEntryForm<IRegularEntry, IRegularEntryRequest, IRegularDiaryEntryFormGroup>
  implements OnInit
{
  constructor(
    @Inject(RESOURCE_KEY) protected override resourceService: ResourceService,
    private datePipe: DatePipe,
    protected override store: Store
  ) {
    super(resourceService, store);
  }

  ngOnInit(): void {}

  close(): void {}

  fetchCompanies: (query: string) => Observable<ICompany[]> = (query: string): Observable<ICompany[]> => {
    return this.resourceService.getProjectCompanies(this.project!.id, query);
  };

  protected get companyControl(): FormControl {
    return this.form.get('entry')?.get('company') as FormControl;
  }

  getEntryRequestObj(): IRegularEntryRequest {
    return {
      type: DiaryEntryType.REGULAR,
      date: this.datePipe.transform(this.form.controls.date?.value, 'YYYY-MM-dd')!,

      company: this.entryForm.controls.company?.value?.id,
      shiftEnd: this.datePipe.transform(this.entryForm.get('shiftEnd')?.value, 'HH:mm') ?? undefined,
      shiftStart: this.datePipe.transform(this.entryForm.get('shiftStart')?.value, 'HH:mm') ?? undefined,

      workforce: this.entryForm.controls.workforce.value,
      sipo: this.entryForm.controls.sipo.value,
      specials: this.entryForm.controls.specials.value,

      maxTemp: this.entryForm.controls.maxTemp.value,
      minTemp: this.entryForm.controls.minTemp.value,
      otherMeasures: this.entryForm.controls.otherMeasures.value,
      weather: this.entryForm.controls.weather.value,

      doneWork: this.entryForm.controls.doneWork.value,
      machines: this.entryForm.controls.machines.value,
      supply: this.entryForm.controls.supply.value
    };
  }
}
