import {
  ComponentRef,
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  ViewContainerRef
} from '@angular/core';
import { SelectOverlayComponent } from './select-overlay/select-overlay.component';

@Directive({
  selector: '[selectable]',
  exportAs: 'selectable',
  host: {
    '[style.cursor]': '"pointer"',
    '[style.position]': '"relative"'
  }
})
export class SelectableDirective implements OnInit, OnChanges {
  @Output() onSelect: EventEmitter<void> = new EventEmitter<void>();
  @Output() onUnselect: EventEmitter<void> = new EventEmitter<void>();
  @Input('selected') selectedModel = true;
  @Output() selectedChange = new EventEmitter<boolean>();

  set selected(val: boolean) {
    this.selectedModel = val;
    this.selectedChange.emit(val);
  }

  get selected(): boolean {
    return this.selectedModel;
  }

  private componentRef?: ComponentRef<SelectOverlayComponent>;

  constructor(
    private elementRef: ElementRef,
    private viewContainerRef: ViewContainerRef,
    private renderer: Renderer2
  ) {}

  ngOnInit() {
    this.elementRef.nativeElement.addEventListener('click', this.toggleSelection.bind(this));
  }

  toggleSelection(event: any) {
    if (this.selectedModel) {
      this.unselect(event);
    } else {
      this.select();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['selectedModel']?.currentValue !== changes['selectedModel']?.previousValue) {
      if (changes['selectedModel']?.currentValue) {
        this.select();
      } else {
        this.unselect();
      }
    }
  }

  private hideOverlay() {
    if (this.componentRef != undefined) {
      this.renderer.removeChild(this.elementRef.nativeElement, this.componentRef.location.nativeElement);
    }
  }

  private attachOverlay() {
    if (!this.componentRef) {
      this.componentRef = this.viewContainerRef.createComponent(SelectOverlayComponent);
      //this.componentRef.location.nativeElement.addEventListener("click", this.unselect.bind(this), false)
    }
    this.renderer.appendChild(this.elementRef.nativeElement, this.componentRef.location.nativeElement);
  }

  public select(): void {
    this.selected = true;
    this.attachOverlay();
    this.onSelect.emit();
  }

  public unselect(event?: PointerEvent): void {
    if (event != undefined) {
      event.stopPropagation();
    }
    this.hideOverlay();
    this.selected = false;
    this.onUnselect.emit();
  }
}
