import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { EditRoleDialogComponent, EditRoleDialogData } from './edit-role-dialog/edit-role-dialog.component';
import { MatTable } from '@angular/material/table';
import { ErrorService, ResourceService } from 'data-access';
import { DIALOG_SERVICE_KEY, DialogService, IRole, PopoverService, RESOURCE_KEY } from 'shared';

@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.scss']
})
export class RolesComponent implements OnInit {
  @ViewChild('rolesTable') rolesTable!: MatTable<any>;
  hasError = false;
  loading = true;
  roles: IRole[] = [];

  constructor(
    @Inject(RESOURCE_KEY) private resourceService: ResourceService,
    @Inject(DIALOG_SERVICE_KEY) private dialog: DialogService,
    private errorService: ErrorService,
    private popoverService: PopoverService
  ) {}

  ngOnInit() {
    this.loadPageData();
  }

  loadPageData() {
    this.loading = true;
    this.hasError = false;
    this.resourceService.getRoles().subscribe({
      error: err => {
        this.roles = [];
        this.hasError = true;
        this.loading = false;
      },
      next: roles => {
        this.roles = roles;
        this.hasError = false;
        this.loading = false;
      }
    });
  }

  deleteRole(role: IRole) {
    this.popoverService.setState(true);
    this.resourceService.deleteRole(role.id).subscribe({
      error: err => {
        this.errorService.showError('Rolle konnte nicht gelöscht werden', err);
      },
      next: () => {
        let index: number = this.roles.findIndex(r => r.id == role.id);
        if (index >= 0) {
          this.roles.splice(index, 1);
          this.rolesTable.renderRows();
        }
      }
    });
  }

  editRole(role?: IRole) {
    this.dialog
      .openDialog<EditRoleDialogComponent, EditRoleDialogData, IRole>(EditRoleDialogComponent, {
        data: { roleId: role?.id }
      })
      .afterDismissed()
      .subscribe((updatedRole: IRole | undefined) => {
        if (updatedRole !== undefined) {
          const index: number = this.roles.findIndex(itRole => updatedRole.id === itRole.id);
          if (index >= 0) {
            this.roles[index] = updatedRole;
          } else {
            this.roles.unshift(updatedRole);
          }
          this.rolesTable.renderRows();
        }
      });
  }
}
