import { Directive, ElementRef, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors } from '@angular/forms';

@Directive({
  selector: '[weatherValidator]',
  providers: [{ provide: NG_VALIDATORS, useExisting: WeatherValidator, multi: true }]
})
export class WeatherValidator {
  @Input() minTemp: number | undefined = undefined;
  @Input() maxTemp: number | undefined = undefined;

  constructor(private inputRef: ElementRef) {}

  validate(control: AbstractControl): ValidationErrors | null {
    if (control.value == undefined && control.value! instanceof Number) {
      return { invalidNumberError: null };
    }
    if (
      this.inputRef.nativeElement.name == 'minTempInput' &&
      this.maxTemp != undefined &&
      this.maxTemp < control.value
    ) {
      return { invalidNumberError: null };
    } else if (
      this.inputRef.nativeElement.name == 'maxTempInput' &&
      this.minTemp != undefined &&
      this.minTemp > control.value
    ) {
      return { invalidNumberError: null };
    }
    return null;
  }
}
