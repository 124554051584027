<div class="slide-container" [class.slided]="slided">
  <div class="slide">
    <div class="item">
      <h2>{{ selectedOrganization?.displayName }}</h2>
      <span class="id-field">id: {{ selectedOrganization?.id }}</span>
    </div>
    <hr />
    <button pButton disabled class="menu-button p-button-sm p-button-text">
      <span class="mat-outlined">add</span>
      <p class="noselect">{{ 'create-organisation' | translate }}</p>
    </button>

    <hr />

    <button pButton disabled class="menu-button p-button-sm p-button-text">
      <span class="mat-outlined">settings</span>
      <p class="noselect">{{ 'settings' | translate }}</p>
    </button>

    <button pButton class="menu-button p-button-sm p-button-text" (click)="slided = !slided">
      <span class="mat-outlined">cached</span>
      <p class="noselect">Andere Organisation</p>
      <span class="mat-outlined ml-auto">chevron_right</span>
    </button>
  </div>

  <div class="slide">
    <div class="flex flex-row align-items-center">
      <button
        pButton
        class="p-button-icon-only p-button-text p-button-sm"
        (click)="slided = !slided"
        icon="mat-outlined mat-chevron-left"></button>
      <h2 class="text-center ml-1 mr-1">{{ 'organisation-change' | translate }}</h2>
    </div>

    <div class="field mb-2">
      <div class="p-float-label">
        <input
          type="text"
          pInputText
          [formControl]="inputForm"
          id="organizationSearchInput"
          touchedDirty
          class="p-filled w-full" />
        <label for="organizationSearchInput">Suche</label>
      </div>
    </div>

    <div class="flex flex-column overflow-y-auto">
      <button
        pButton
        class="organization-button p-button-lg mb-1"
        *ngFor="let organization of filteredOrgs"
        [class.p-button-text]="selectedOrganization?.id !== organization.id"
        [routerLink]="'/organizations/' + organization.id">
        <p>{{ organization?.displayName }}</p>
        <span *ngIf="selectedOrganization?.id === organization.id" class="mat-outlined">check</span>
      </button>
    </div>
  </div>
</div>
