<div class="page">
  <div class="page-content flex flex-column">
    <h1 class="no-wrap flex-shrink-0">{{ 'add' | translate }}</h1>
    <form
      #memberForm="ngForm"
      (ngSubmit)="memberForm.valid ? addProjectMember() : memberForm.control.markAllAsTouched()">
      <project-user-input-component
        #memberInputComponent
        [(ngModel)]="newMember"
        [roles]="roles"
        [excludedUserIds]="memberIds"
        #memberInput="ngModel"
        [name]="'memberInput'">
      </project-user-input-component>
      <div class="flex justify-content-end">
        <button pButton class="p-button-sm" [label]="'add' | translate"></button>
      </div>
    </form>

    <h1>{{ 'members' | translate }}</h1>
    <div style="min-height: 0">
      <p-table
        [value]="members"
        scrollHeight="flex"
        [tableStyle]="{ 'min-width': '50rem' }"
        [responsive]="true"
        [scrollable]="true">
        <ng-template pTemplate="header">
          <tr>
            <th>{{ 'name' | translate }}</th>
            <th>{{ 'roles' | translate }}</th>
            <th></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-member>
          <tr>
            <td>
              <div class="member-display">
                <div class="member-avatar">
                  <img [attr.src]="member.picture" />
                </div>
                <div class="member-info">
                  <p id="memberName">{{ member.name }}</p>
                  <p id="memberEmail">{{ member.email }}</p>
                </div>
              </div>
            </td>
            <td>
              <mat-chip-listbox selectable="false" multiple="true">
                <mat-chip *ngFor="let role of member.roles" [matTooltip]="role.description">
                  {{ role.name }}
                  <button
                    matChipRemove
                    (click)="removeRole(member, role)"
                    *ngIf="!((user | async)?.id == member.id && role?.defaultRole)">
                    <mat-icon>cancel</mat-icon>
                  </button>
                </mat-chip>
                <mat-chip id="addButton">
                  <button matChipRemove [popoverTrigger]="addRoleInput" [popoverContext]="{ member: member }">
                    <i-feather name="plus"></i-feather>
                  </button>
                </mat-chip>
              </mat-chip-listbox>
            </td>
            <td>
              <button mat-icon-button disabled color="primary" (click)="removeMember(member)">
                <i-feather name="trash"></i-feather>
              </button>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>

<ng-template #addRoleInput let-member="member">
  <popover-container>
    <p>{{ 'add-roles' | translate }}</p>
    <p id="no-roles-message" *ngIf="getUnassignedRoles(member)?.length == 0">
      {{ 'no-additional-roles-available' | translate }}
    </p>
    <mat-chip-listbox selectable="true" multiple="true">
      <mat-chip
        *ngFor="let role of getUnassignedRoles(member)"
        [matTooltip]="role.description"
        (click)="addRole(member, role)">
        {{ role.name }}
      </mat-chip>
    </mat-chip-listbox>
  </popover-container>
</ng-template>
