import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { CheckboxModule } from 'primeng/checkbox';
import { InputTextModule } from 'primeng/inputtext';
import { ConfirmDialogComponent, ConfirmDirective } from 'shared';

@NgModule({
  declarations: [ConfirmDialogComponent, ConfirmDirective],
  imports: [ReactiveFormsModule, CommonModule, CheckboxModule, InputTextModule],
  exports: [ConfirmDialogComponent, ConfirmDirective]
})
export class ConfirmModule {}
