import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-project-plans',
  templateUrl: './project-plans.component.html',
  styleUrls: ['./project-plans.component.scss']
})
export class ProjectPlansComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
