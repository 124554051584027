import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ProjectDashboardRoutingModule } from './project-dashboard-routing.module';
import { ProjectDashboardComponent } from './project-dashboard.component';
import { MatTabsModule } from '@angular/material/tabs';
import { SharedPageModule } from 'libs/shared/src/lib/modules/shared-page.module';
import { FeatureDiaryModule } from '../../../diary/feature-diary.module';

@NgModule({
  imports: [
    SharedPageModule,
    CommonModule,
    FormsModule,
    ProjectDashboardRoutingModule,
    MatTabsModule,
    FeatureDiaryModule
  ],
  declarations: [ProjectDashboardComponent]
})
export class ProjectDashboardModule {}
