import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IDiaryObject, IProject } from 'shared';
import { ProjectsService } from '../../services/projects.service';

@Component({
  selector: 'app-project-dashboard',
  templateUrl: './project-dashboard.component.html',
  styleUrls: ['./project-dashboard.component.scss']
})
export class ProjectDashboardComponent implements OnInit {
  project?: IProject;

  previewDiaryObjects: IDiaryObject[] = [];
  previewDiaryObjectsLoading = true;

  constructor(private projectService: ProjectsService, private router: Router) {}

  ngOnInit() {
    this.projectService.getSelectedProject().subscribe(project => {
      this.project = project;
    });
  }

  castString(str: string): string {
    if (!str || str.trim() === '') {
      return '/';
    } else {
      return str;
    }
  }

  openPage(url: string) {
    this.router.navigateByUrl(url);
  }
}
