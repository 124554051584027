import { NgModule } from '@angular/core';
import { MessageComponent } from './message/message.component';
import { MessagesModule } from 'primeng/messages';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';

@NgModule({
  imports: [MessagesModule, CommonModule, TranslateModule, ButtonModule],
  declarations: [MessageComponent],
  providers: [],
  exports: [MessageComponent]
})
export class AppMessageModule {}
