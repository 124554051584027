import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewContainerRef
} from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';

import { takeUntil } from 'rxjs/operators';
import { fromEvent, Subject } from 'rxjs';
import { TemplatePortal } from '@angular/cdk/portal';
import { PopoverService } from './popover.service';

export type PopoverPositionX = 'start' | 'center' | 'end';
export type PopoverPositionY = 'bottom' | 'center' | 'top';

@Directive({
  selector: '[popoverTrigger]'
})
export class PopoverDirective implements OnDestroy, OnInit {
  @Input()
  popoverTrigger!: TemplateRef<object>;
  @Input()
  popoverContext!: object;
  // [originX, overlayX]
  @Input() positionX: PopoverPositionX[] = ['start', 'start'];
  // [originY, overlayY]
  @Input() positionY: PopoverPositionY[] = ['bottom', 'top'];
  //if set to true the overlay width will be fixed to the elementRef
  @Input() responsiveWidth: boolean = false;

  @Input()
  closeOnClickOutside: boolean = true;

  @Output()
  onChangePopoverState: EventEmitter<boolean> = new EventEmitter<boolean>();

  private unsubscribe = new Subject();
  private overlayRef!: OverlayRef;

  constructor(
    private elementRef: ElementRef,
    private overlay: Overlay,
    private vcr: ViewContainerRef,
    private popoverService: PopoverService
  ) {}

  ngOnInit(): void {
    this.createOverlay();
    this.popoverService.getState().subscribe(resp => {
      if (resp) {
        this.detachOverlay();
      }
    });
  }

  ngOnDestroy(): void {
    this.detachOverlay();
    //this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  @HostListener('click') clickou() {
    this.attachOverlay();
  }

  private createOverlay(): void {
    const scrollStrategy = this.overlay.scrollStrategies.block();
    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(this.elementRef)
      .withPositions([
        {
          originX: this.positionX[0],
          originY: this.positionY[0],
          overlayX: this.positionX[1],
          overlayY: this.positionY[1]
        }
      ]);
    // .connectedTo(
    //   this.elementRef,
    //   { originX: "start", originY: "bottom" },
    //   { overlayX: "start", overlayY: "top" }

    // new ConnectionPositionPair(
    //   { originX: "start", originY: "bottom" },
    //   { overlayX: "start", overlayY: "bottom" }
    // ),
    // new ConnectionPositionPair(
    //   { originX: "start", originY: "bottom" },
    //   { overlayX: "start", overlayY: "bottom" }
    // )
    //);

    let width = this.responsiveWidth ? this.elementRef.nativeElement.clientWidth : undefined;

    this.overlayRef = this.overlay.create({
      positionStrategy,
      scrollStrategy,
      hasBackdrop: true,
      backdropClass: '',
      width: width
    });

    this.overlayRef
      .backdropClick()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {
        if (this.closeOnClickOutside) {
          this.detachOverlay();
        }
      });
  }

  private attachOverlay(): void {
    if (!this.overlayRef.hasAttached()) {
      const periodSelectorPortal = new TemplatePortal(this.popoverTrigger, this.vcr, this.popoverContext);
      this.overlayRef.attach(periodSelectorPortal);
      this.onChangePopoverState.emit(true);
    }
  }

  private detachOverlay(): void {
    if (this.overlayRef.hasAttached()) {
      this.overlayRef.detach();
      this.onChangePopoverState.emit(false);
    }
  }
}

@Directive({ selector: '[autoClosePopover]' })
export class AutoClosePopoverDirective implements OnInit {
  constructor(private popoverService: PopoverService, private _elementRef: ElementRef) {}

  ngOnInit() {
    fromEvent(this._elementRef.nativeElement, 'click').subscribe(() => {
      this.popoverService.setState(true);
    });
  }
}
