import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { NotRespondingPage } from './not-responding.page';

const routes: Routes = [
  {
    path: '',
    component: NotRespondingPage
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class NotRespondingPageRoutingModule {}
