import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';

/**
 * This directive removes focus from the selectors after clicking on them
 */
@Directive({
  selector: '[autoGrow]'
})
export class AutoGrowDirective {
  constructor(private elRef: ElementRef, private renderer: Renderer2) {}

  @HostListener('input') onClick() {
    this.renderer.setStyle(this.elRef.nativeElement, 'height', this.elRef.nativeElement.scrollHeight + 2 + 'px');
  }
}
