export interface IPermission {
  id: string;
  name: string;
  label: string;
  description: string;
}

export enum OrganizationPermission {
  PROJECTS_LIST = 'projects:list',
  PROJECTS_CREATE = 'projects:create',
  PROJECTS_READ = 'projects:read',
  PROJECTS_UPDATE = 'projects:update',
  PROJECTS_DELETE = 'projects:delete',

  DIARY_ENTRIES_READ = 'diary_entries:read',
  DIARY_ENTRIES_DELETE = 'diary_entries:delete',
  DIARY_ENTRIES_UPDATE = 'diary_entries:update',
  DIARY_ENTRIES_CREATE = 'diary_entries:create',

  DIARY_EXPORT_READ = 'diary_export:read',
  DIARY_EXPORT_CREATE = 'diary_export:create',
  DIARY_EXPORT_DELETE = 'diary_export:delete',

  PROJECT_COMPANIES_ASSIGN = 'project_companies:assign',
  PROJECT_COMPANIES_REVOKE = 'project_companies:revoke',

  COMPANIES_READ = 'companies:read',
  COMPANIES_CREATE = 'companies:create',
  COMPANIES_UPDATE = 'companies:update',
  COMPANIES_DELETE = 'companies:delete',

  ROLES_READ = 'roles:read',
  ROLES_CREATE = 'roles:create',
  ROLES_UPDATE = 'roles:update',
  ROLES_DELETE = 'roles:delete',
  ROLES_ASSIGN = 'roles:assign',
  ROLES_REVOKE = 'roles:revoke',

  PROJECT_ROLES_READ = 'projects_roles:read',
  PROJECT_ROLES_UPDATE = 'projects_roles:update',
  PROJECT_ROLES_DELETE = 'projects_roles:delete',
  PROJECT_ROLES_CREATE = 'projects_roles:create',

  USERS_READ = 'users:read',

  INVITES_READ = 'invites:read',
  INVITES_CREATE = 'invites:create',
  INVITES_DELETE = 'invites:delete'
}

export enum ProjectPermission {
  PROJECTS_CREATE = 'projects:create',
  PROJECTS_READ = 'projects:read',
  PROJECTS_UPDATE = 'projects:update',
  PROJECTS_DELETE = 'projects:delete',

  DIARY_ENTRIES_READ = 'diary_entries:read',
  DIARY_ENTRIES_DELETE = 'diary_entries:delete',
  DIARY_ENTRIES_UPDATE = 'diary_entries:update',
  DIARY_ENTRIES_CREATE = 'diary_entries:create',

  DIARY_EXPORT_READ = 'diary_export:read',
  DIARY_EXPORT_CREATE = 'diary_export:create',
  DIARY_EXPORT_DELETE = 'diary_export:delete',

  PROJECT_COMPANIES_ASSIGN = 'project_companies:assign',
  PROJECT_COMPANIES_REVOKE = 'project_companies:revoke',

  ROLES_ASSIGN = 'roles:assign',
  ROLES_REVOKE = 'roles:revoke'
}

export type Permission = ProjectPermission | OrganizationPermission;
