import { createFeatureSelector, createSelector } from '@ngrx/store';
import { uploadAdapter, UploadState } from './upload-queue.state';
import { IUploadTask, UploadStatusEnum, UploadTypeEnum } from 'shared';

export const uploadState = createFeatureSelector<UploadState>('uploadQueue');

const { selectAll, selectEntities, selectIds, selectTotal } = uploadAdapter.getSelectors();

export const selectUploadTaskById = (taskId: string) => createSelector(uploadState, state => state.entities[taskId]);

export const selectUploadInProgress = createSelector(uploadState, state => state.uploadInProgress);

export const selectAllUploads = createSelector(uploadState, selectAll);

export const selectWaitingUploads = createSelector(selectAllUploads, allUploads =>
  allUploads.filter(upload => upload.status == UploadStatusEnum.Waiting)
);

export const selectFailedUploads = createSelector(selectAllUploads, allUploads =>
  allUploads.filter(upload => upload.status == UploadStatusEnum.Failed)
);

export const selectNotFailedUploads = createSelector(selectAllUploads, allUploads =>
  allUploads.filter(upload => upload.status != UploadStatusEnum.Failed)
);

export const selectNextUploadTask = createSelector(selectWaitingUploads, allUploads => {
  let upload: IUploadTask = allUploads.find(upload => upload.type == UploadTypeEnum.Entry)!;
  upload = upload == undefined ? allUploads.find(upload => upload.type == UploadTypeEnum.Image)! : upload;
  upload = upload == undefined ? allUploads.find(upload => upload.type == UploadTypeEnum.Document)! : upload;
  return upload;
});
