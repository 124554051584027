import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProjectExportComponent } from './project-export.component';
import { ProjectExportViewerComponent } from './project-export-viewer/project-export-viewer.component';

const routes: Routes = [
  {
    path: '',
    component: ProjectExportComponent
  },
  {
    path: ':pdfId',
    component: ProjectExportViewerComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProjectExportRoutingModule {}
