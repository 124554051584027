import { NgModule } from '@angular/core';
import { DropzoneOverlayComponent } from './dropzone-overlay/dropzone-overlay.component';
import { DropzoneDirective } from './dropzone.directive';
import { FeatherModule } from 'angular-feather';
import { Plus } from 'angular-feather/icons';
import { DraggableDirective } from './draggable.directive';

@NgModule({
  imports: [
    //CommonModule,
    FeatherModule.pick({ Plus })
  ],
  declarations: [DropzoneOverlayComponent, DropzoneDirective, DraggableDirective],
  providers: [],
  exports: [DropzoneDirective, DraggableDirective]
})
export class DropzoneModule {}
