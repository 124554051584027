import { createFeatureSelector, createSelector } from '@ngrx/store';
import { imagesAdapter, ImagesState } from './image.state';

export const imagesState = createFeatureSelector<ImagesState>('images');

const { selectAll, selectEntities, selectIds, selectTotal } = imagesAdapter.getSelectors();

export const selectImageById = (imageId: number) => createSelector(imagesState, state => state.entities[imageId]);

export const selectAllImages = createSelector(imagesState, selectAll);

export const selectAllImagesLoaded = createSelector(imagesState, state => state.allImagesLoaded);
export const selectAllImagesByEntryId = (entryId: string) =>
  createSelector(selectAllImages, images => {
    return images.filter(image => image.entryId == entryId);
  });

export const selectAllImagesByEntryIdNull = createSelector(selectAllImages, images =>
  images.filter(image => image.entryId == undefined)
);
