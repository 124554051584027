import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ProjectDiaryComponent } from './project-diary.component';
import { ProjectDiaryViewComponent } from './project-diary-view/project-diary-view.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./project-diary-view/project-diary-view.module').then(m => m.ProjectDiaryViewModule)
    // children: [
    //   {
    //     path: '',
    //     loadChildren: () => import('./project-diary-view/project-diary-view.module').then(m => m.ProjectDiaryViewModule)
    //   },
    //   {
    //     path: 'template/:templateId',
    //     loadChildren: () =>
    //       import('./project-diary-template/project-diary-template.module').then(m => m.ProjectDiaryTemplateModule)
    //   }
    // ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProjectDiaryRoutingModule {}
