import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { map, Observable } from 'rxjs';
import { TokenService } from 'data-access';
import { AuthService, GetTokenSilentlyOptions } from '@auth0/auth0-angular';
import { environment } from 'shared';

@Injectable({ providedIn: 'root' })
export class WebManagementTokenService extends TokenService {
  constructor(store: Store, private _authService: AuthService) {
    super();
  }

  public initiate() {}

  override reAuthorize(): Observable<string | undefined> {
    if (!this.tokenRefreshInProgress) {
      console.log('refresh management token');
      this.tokenRefreshInProgress = true;
      this._accessToken.next(undefined);
      this.getTokenSilently()
        // .pipe(
        //   catchError(err => {
        //     return this.getTokenSilently();
        //   })
        // )
        .subscribe(accessToken => {
          this.accessToken = accessToken;
          this.tokenRefreshInProgress = false;
        });
    }
    return this._accessToken.asObservable();
  }

  getTokenSilently(): Observable<string> {
    const options: GetTokenSilentlyOptions = {
      cacheMode: 'off',
      authorizationParams: {
        scope: undefined,
        audience: environment.managementServerAudience,
        redirect_uri: environment.auth.authorizationParams?.redirect_uri
      }
    };
    return this._authService.getAccessTokenSilently(options).pipe(
      map(accessToken => {
        return accessToken;
      })
    );
  }
}
