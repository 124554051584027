import { createFeatureSelector, createSelector } from '@ngrx/store';
import { exportAdapter, ExportState } from './export.state';

export const exportState = createFeatureSelector<ExportState>('export');

const { selectAll, selectEntities, selectIds, selectTotal } = exportAdapter.getSelectors();

export const selectAllPdfs = createSelector(exportState, selectAll);
export const selectAllPdfEntities = createSelector(exportState, selectEntities);

export const selectPdfById = (pdfId: string) => createSelector(selectAllPdfEntities, state => state[pdfId]);

export const selectTemplatePdfByTemplateId = (templateId: string) =>
  createSelector(selectAllPdfs, pdfs => pdfs.filter(pdf => pdf.templateId === templateId && !pdf.customExport).at(0));

export const selectAllTemplatePdfs = createSelector(selectAllPdfs, pdfs => pdfs.filter(pdf => !pdf.customExport));
export const selectAllCustomPdfs = createSelector(selectAllPdfs, pdfs => pdfs.filter(pdf => pdf.customExport));
