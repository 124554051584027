import { UploadStatusEnum } from '../enums/upload-status.enum';
import { UploadTypeEnum } from '../enums/upload-type.enum';
import { IUploadTask } from '../interfaces/upload-task.interface';

export const UPLOAD_TASK_MOCK: IUploadTask[] = [
  {
    id: '',
    projectId: '551bc77c-f35c-4696-9a4e-fee277953d84',
    type: UploadTypeEnum.Entry,
    status: UploadStatusEnum.Started,
    progress: 0,
    preview: undefined,
    request: undefined,
    error: undefined
  },
  {
    id: '',
    projectId: '551bc77c-f35c-4696-9a4e-fee277953d84',
    type: UploadTypeEnum.Entry,
    status: UploadStatusEnum.Waiting,
    progress: 0,
    preview: undefined,
    request: undefined,
    error: undefined
  },
  {
    id: '',
    projectId: '551bc77c-f35c-4696-9a4e-fee277953d84',
    type: UploadTypeEnum.Image,
    status: UploadStatusEnum.Waiting,
    progress: 0,
    preview: undefined,
    request: undefined,
    error: undefined
  },
  {
    id: '',
    projectId: '551bc77c-f35c-4696-9a4e-fee277953d84',
    type: UploadTypeEnum.Image,
    status: UploadStatusEnum.Failed,
    progress: 0,
    preview: undefined,
    request: undefined,
    error: undefined
  }
];
