import { EntryActions, EntryActionTypes } from './entry.actions';
import { entriesAdapter, EntriesState, initialEntriesState } from './entry.state';
import { DiaryEntryType, IAbstractEntry, IRegularEntry } from 'shared';

export function entriesReducer(state = initialEntriesState, action: EntryActions): EntriesState {
  switch (action.type) {
    case EntryActionTypes.AddEntries:
      const nestedEntries: IAbstractEntry[] = action.entries
        .filter(entry => entry.type == DiaryEntryType.REGULAR && (entry as IRegularEntry).images.length > 0)
        // .map(entry =>
        //   (entry as IRegularEntry).images.map(image => {
        //     return { ...image, parentId: entry.id };
        //   })
        // )
        .map(entry => (entry as IRegularEntry).images)
        .flat();
      return entriesAdapter.addMany(nestedEntries.concat(action.entries), state);

    case EntryActionTypes.AddEntry:
      return entriesAdapter.addOne(action.entry, state);
    case EntryActionTypes.UpdateEntry:
      return entriesAdapter.updateOne({ id: action.entry.id!, changes: action.entry }, state);
    case EntryActionTypes.DeleteEntry:
      return entriesAdapter.removeOne(action.id, state);

    /// OLD

    // case EntryActionTypes.LoadEntrySuccess:
    //   return entriesAdapter.addOne(action.payload.entry, state);
    //
    // case EntryActionTypes.LoadAllEntriesSuccess:
    //   return entriesAdapter.addMany(action.payload.entries, {
    //     ...state,
    //     allEntriesLoaded: true
    //   });

    case EntryActionTypes.ResetEntriesState:
      return entriesAdapter.getInitialState(initialEntriesState);

    // case EntryActionTypes.CloseEntrySuccess:
    //   return entriesAdapter.updateOne(
    //     {
    //       id: action.payload.id,
    //       changes: {
    //         closed: true
    //       }
    //     },
    //     state
    //   );
    //
    // case EntryActionTypes.DeleteEntrySuccess:
    //   return entriesAdapter.removeOne(action.payload.id, state);

    default: {
      return state;
    }
  }
}
