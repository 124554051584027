import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatExpansionModule } from '@angular/material/expansion';
import { AccordionModule } from 'primeng/accordion';
import { ErrorComponent } from './error/error.component';
import { ErrorMessageComponent } from './error-message/error-message.component';
import { ErrorDisplayComponent } from './error-display/error-display.component';
import { SharedModule } from '../../shared.module';
import { ButtonModule } from 'primeng/button';

@NgModule({
  imports: [CommonModule, MatExpansionModule, AccordionModule, SharedModule, ButtonModule],
  declarations: [ErrorMessageComponent, ErrorComponent, ErrorDisplayComponent],
  exports: [ErrorDisplayComponent]
})
export class ErrorModule {}
