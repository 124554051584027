import {
  AfterContentInit,
  ContentChildren,
  Directive,
  DoCheck,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  Self,
  TemplateRef
} from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl } from '@angular/forms';
import { isEqual } from 'lodash';
import { PrimeTemplate, SelectItem } from 'primeng/api';
import {
  BehaviorSubject,
  combineLatest,
  debounceTime,
  map,
  Observable,
  ReplaySubject,
  Subject,
  takeUntil,
  throwError
} from 'rxjs';

import { RESOURCE_KEY } from 'shared';
import { ResourceService } from 'data-access';

@Directive({
  selector: 'lazy-core'
})
export class LazyCoreComponent<T> implements OnInit, AfterContentInit, DoCheck, OnDestroy, ControlValueAccessor {
  @ContentChildren(PrimeTemplate) protected templates?: QueryList<PrimeTemplate>;
  @Input() fetchFunction: (query: string) => Observable<T[]> = (query: string) => {
    if (this.fetchPath) {
      if (query) {
        this.queryParams['query'] = query;
      } else {
        delete this.queryParams['query'];
      }
      // return this.restService.get<T[]>(this.fetchPath, [], this.queryParams);
    }
    return throwError(() => new Error('No fetchPath or fetchFunction was given'));
  };
  @Input() itemComparator: (item1: T | undefined, item2: T | undefined) => boolean = (item1, item2) => {
    return isEqual(item1, item2);
  };
  @Input() label: string = '';
  @Input() filter: boolean = false;
  @Input() queryParams: any = {};
  @Input() filterDebounce: number = 300;
  @Input() filterPlaceHolder: string = '';
  @Input() placeHolder: string = '';
  @Input() valueLabel?: string;
  @Input() fetchPath?: string;
  @Input() icon?: string;
  @Output() onSelectionChange: EventEmitter<T> = new EventEmitter<T>();
  protected selectedItemTemplate?: TemplateRef<any>;
  protected itemTemplate?: TemplateRef<any>;
  protected loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  protected searchStr$: ReplaySubject<string> = new ReplaySubject<string>(1);
  protected options$: BehaviorSubject<T[]> = new BehaviorSubject<T[]>([]);
  protected control?: FormControl<T | null | undefined>;
  protected selectedOption: BehaviorSubject<T | undefined> = new BehaviorSubject<T | undefined>(undefined);
  protected options: SelectItem<T | undefined>[] = [];
  private initialLoadPerformed: boolean = false;
  private readonly _destroyer$ = new Subject<void>();

  constructor(@Inject(RESOURCE_KEY) private resourceService: ResourceService, @Self() public controlDir: NgControl) {
    controlDir.valueAccessor = this;
    this.control = new FormControl<T | undefined>(undefined, []);
    this.searchStr$.pipe(takeUntil(this._destroyer$), debounceTime(this.filterDebounce)).subscribe(query => {
      this.loading$.next(true);
      return this.fetchFunction(query)
        .pipe(takeUntil(this._destroyer$))
        .subscribe({
          next: options => {
            // check if the selectedOption is also in the new fetched Options -> if not add it
            if (this.selectedOption.value && !options.find(o => this.itemComparator(o, this.selectedOption?.value))) {
              options.unshift(this.selectedOption.value);
            }
            this.options$.next(options);
            this.loading$.next(false);
          },
          error: err => {
            this.options$.next([]);
            this.loading$.next(false);
            throw new Error(err);
          }
        });
    });
  }

  get optionObs(): Observable<SelectItem<T | undefined>[]> {
    return combineLatest([
      this.options$.asObservable(),
      this.selectedOption.asObservable(),
      this.loading$.asObservable()
    ]).pipe(
      takeUntil(this._destroyer$),
      map(([options, currentOption, loading]) => {
        if (loading) {
          let arr: any[] = [{ value: undefined, disabled: true, isLoader: true }];
          if (currentOption) {
            arr.unshift({ value: currentOption, disabled: true, isLoader: false });
          }
          return arr;
        }
        return options.map(option => {
          return { value: option, disabled: loading, isLoader: false };
        });
      })
    );
  }

  ngOnInit(): void {
    this.control?.setValidators(this.controlDir.control!.validator);
  }

  ngDoCheck() {
    if (!this.control?.touched && this.controlDir.control?.touched) {
      this.control?.markAsTouched();
    }
  }

  ngAfterContentInit() {
    (this.templates as QueryList<PrimeTemplate>).forEach(item => {
      switch (item.getType()) {
        case 'item':
          this.itemTemplate = item.template;
          break;

        case 'selectedItem':
          this.selectedItemTemplate = item.template;
          break;
      }
    });
    this.optionObs.pipe(takeUntil(this._destroyer$)).subscribe(options => (this.options = options));
    this.control?.valueChanges.pipe(takeUntil(this._destroyer$)).subscribe(value => {
      this.selectedOption.next(value!);
    });
  }

  ngOnDestroy(): void {
    this._destroyer$.next();
    this._destroyer$.complete();
  }

  public reset(): void {
    this.initialLoadPerformed = false;
    this.options$.next([]);
  }

  public writeValue(value: T) {
    this.control?.setValue(value);
    this.selectedOption.next(value);
    if (value != null) {
      if (!this.options$.value.find(v => isEqual(v, value))) {
        let newOptions = this.options$.value;
        newOptions.push(value);
        this.options$.next(newOptions);
      }
    }
  }

  public setDisabledState(disabled: boolean) {
    disabled ? this.control?.disable() : this.control?.enable();
  }

  public onChange = (value: T) => {};

  public onTouched: () => void = () => {};

  public registerOnChange(fn: any): void {
    this.control?.valueChanges.subscribe(fn);
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  initData() {
    if (!this.initialLoadPerformed) {
      this.searchStr$.next('');
      this.initialLoadPerformed = true;
    }
  }
}
