import { AfterContentInit, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { IErrorData } from '../../../interfaces/error-data.interface';

@Component({
  selector: 'app-error-message',
  templateUrl: './error-message.component.html',
  styleUrls: ['./error-message.component.scss']
})
export class ErrorMessageComponent implements AfterContentInit {
  loaded = false;
  constructor(
    public dialogRef: MatDialogRef<ErrorMessageComponent>,
    @Inject(MAT_DIALOG_DATA) public errorData: IErrorData
  ) {
    console.log(errorData);
  }

  ngAfterContentInit(): void {
    /**
     * @description needs to show in next cycle because of active item in prime accordion
     */
    setTimeout(() => {
      this.loaded = true;
    }, 0);
  }

  close() {
    this.dialogRef.close();
  }

  copyToClipborad() {
    navigator.clipboard.writeText(JSON.stringify(this.errorData.error));
  }
}
