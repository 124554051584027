import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { distinctUntilChanged, filter, map, Observable, switchMap } from 'rxjs';
import { environment, IOrganization } from 'shared';
import { TokenService } from 'data-access';
import { AuthService, GetTokenSilentlyOptions } from '@auth0/auth0-angular';
import { selectSelectedOrganization } from 'data-access';

@Injectable({ providedIn: 'root' })
export class WebOrganizationTokenService extends TokenService {
  private organization: Observable<IOrganization | undefined>;

  constructor(private store: Store, private _authService: AuthService) {
    super();
    this.organization = store.select(selectSelectedOrganization);
    this.organization.pipe(distinctUntilChanged()).subscribe(org => {
      this.accessToken = undefined;
    });
  }

  public initiate() {}

  override reAuthorize(): Observable<string | undefined> {
    if (!this.tokenRefreshInProgress) {
      console.log('refresh organization token');
      this.tokenRefreshInProgress = true;
      this._accessToken.next(undefined);
      this.getTokenSilently().subscribe(accessToken => {
        this.accessToken = accessToken;
        this.tokenRefreshInProgress = false;
      });
    }
    return this._accessToken.asObservable();
  }

  getTokenSilently(): Observable<string> {
    return this.organization.pipe(
      filter(org => org != undefined),
      switchMap(org => {
        const options: GetTokenSilentlyOptions = {
          cacheMode: 'off',
          authorizationParams: {
            audience: environment.resourceAudience,
            scope: undefined,
            organization: org!.id
          }
        };
        return this._authService.getAccessTokenSilently(options).pipe(
          map(accessToken => {
            return accessToken;
          })
        );
      })
    );
  }
}
