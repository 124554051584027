import { Component, Inject, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { IPdf, IPdfTemplate, IProject, RESOURCE_KEY } from 'shared';
import {
  AddPdfExports,
  PdfService,
  ResetExportState,
  ResourceService,
  selectAllCustomPdfs,
  selectAllTemplatePdfs,
  selectSelectedProject
} from 'data-access';
import { of, switchMap, tap } from 'rxjs';

@Component({
  selector: 'app-project-export',
  templateUrl: './project-export.component.html',
  styleUrls: ['./project-export.component.scss']
})
export class ProjectExportComponent implements OnInit {
  private project?: IProject;
  protected templates?: IPdfTemplate[];
  protected templatePdfs?: IPdf[];
  protected customPdfs?: IPdf[];

  protected loading: boolean = true;

  constructor(
    private store: Store,
    private pdfService: PdfService,
    @Inject(RESOURCE_KEY) private resourceService: ResourceService
  ) {
    this.store
      .select(selectSelectedProject)
      .pipe(
        tap(project => (this.project = project)),
        switchMap(project => {
          if (project) {
            return this.resourceService.getPdfs(project.id);
          } else {
            return of(undefined);
          }
        })
      )
      .subscribe(pdfs => {
        if (pdfs) {
          this.store.dispatch(new AddPdfExports(pdfs));
        } else {
          this.store.dispatch(new ResetExportState());
        }
      });
    this.pdfService.getPdfTemplates().subscribe(templates => {
      this.templates = templates ?? [];
      this.loading = false;
    });
    this.store.select(selectAllTemplatePdfs).subscribe(templatePdfs => (this.templatePdfs = templatePdfs));
    this.store.select(selectAllCustomPdfs).subscribe(customPdfs => (this.customPdfs = customPdfs));
  }
  ngOnInit(): void {}
}
