import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';
import { WebLayoutComponent } from './modules/layout/web-layout.component';
import { OrganizationResolverService } from 'core';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'menu',
    pathMatch: 'full'
  },
  {
    path: '',
    component: WebLayoutComponent,
    children: [
      {
        path: '',
        redirectTo: 'organizations',
        pathMatch: 'full'
      },
      {
        path: 'organizations',
        children: [
          {
            path: '',
            loadChildren: () => import('core').then(m => m.OrganizationOverviewPageModule)
          },
          {
            path: ':organizationId',
            loadChildren: () => import('core').then(m => m.CoreOrganizationsModule),
            resolve: {
              organization: OrganizationResolverService
            }
          }
        ]
      },
      {
        path: 'not-responding',
        loadChildren: () => import('core').then(m => m.NotRespondingPageModule)
      },
      {
        path: '**',
        redirectTo: 'not-found'
      }
    ],
    canActivate: [AuthGuard]
  },

  {
    path: 'logout',
    loadChildren: () => import('./modules/logout/logout.module').then(m => m.LogoutModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./modules/login/login.module').then(m => m.LoginPageModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
