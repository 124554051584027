import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PrimeNGConfig } from 'primeng/api';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(private translateService: TranslateService, private primeNGConfig: PrimeNGConfig) {
    // translate.setDefaultLang('de');
    // translate.use('de');
    // this.config.setTranslation('de');
    // this.translate.get('primeng').subscribe(res => this.config.setTranslation(res));
    this.translate('de');
  }

  ngOnInit() {
    this.addNotranslate();
  }

  translate(lang: string) {
    this.translateService.use(lang);
    this.translateService.get('primeng').subscribe(res => this.primeNGConfig.setTranslation(res));
  }

  private addNotranslate() {
    const elements = document.querySelectorAll('.mat-outlined');
    elements.forEach(element => {
      element.classList.add('notranslate');
      element.setAttribute('translate', 'no');
    });
  }
}
