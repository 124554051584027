import { Action } from '@ngrx/store';
import { IImage } from 'shared';

export enum ImageActionTypes {
  // single project actions
  LoadImage = '[Image] Load',
  LoadImageSuccess = '[Image] Load Success',
  LoadImageError = '[Image] Load Error',

  // all project actions
  LoadAllImages = '[Images] Load All',
  LoadAllImagesSuccess = '[Images] All Loaded Success',
  LoadAllImagesError = '[Images] All Loaded Error',

  // state control
  ResetImagesState = '[Images] Reset State',

  AddImageSuccess = '[Images] Add Success',

  UpdateImage = '[Images] Update',
  RemoveImage = '[Images] Remove'
}

export class UpdateImage implements Action {
  readonly type = ImageActionTypes.UpdateImage;
  constructor(public payload: { image: IImage }) {}
}

export class RemoveImage implements Action {
  readonly type = ImageActionTypes.RemoveImage;
  constructor(public payload: { id: string }) {}
}

export class LoadImage implements Action {
  readonly type = ImageActionTypes.LoadImage;
}

export class LoadImageSuccess implements Action {
  readonly type = ImageActionTypes.LoadImageSuccess;

  constructor(public payload: { image: IImage }) {}
}

export class LoadImageError implements Action {
  readonly type = ImageActionTypes.LoadImageError;

  constructor(public payload: { error: any }) {}
}

export class LoadAllImages implements Action {
  readonly type = ImageActionTypes.LoadAllImages;
}

export class LoadAllImagesSuccess implements Action {
  readonly type = ImageActionTypes.LoadAllImagesSuccess;

  constructor(public payload: { images: IImage[] }) {}
}

export class LoadAllImagesError implements Action {
  readonly type = ImageActionTypes.LoadAllImagesError;

  constructor(public payload: { error: any }) {}
}

export class ResetImagesState implements Action {
  readonly type = ImageActionTypes.ResetImagesState;
}

export class AddImageSuccess implements Action {
  readonly type = ImageActionTypes.AddImageSuccess;

  constructor(public payload: { image: IImage }) {}
}

export type ImageActions =
  | LoadImage
  | LoadImageSuccess
  | LoadImageError
  | LoadAllImages
  | LoadAllImagesSuccess
  | LoadAllImagesError
  | ResetImagesState
  | AddImageSuccess
  | UpdateImage
  | RemoveImage;
