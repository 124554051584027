<div class="diary-object last-diary-object" *ngIf="project">
  <div class="diary-object-header">
    <div class="title">
      <h3>Filter</h3>
    </div>
  </div>
  <div class="diary-object-content">
    <form [formGroup]="diarySearchForm" class="diary-search-box-form">
      <div class="flex flex-column flex-grow-1">
        <div class="field">
          <div class="p-float-label">
            <input
              type="text"
              pInputText
              formControlName="query"
              id="queryInput"
              class="p-filled w-full"
              aria-describedby="queryInputHelp"
              touchedDirty />
            <label for="queryInput">Suche</label>
          </div>
        </div>

        <lazy-multiselect
          [fetchFunction]="fetchCompanies"
          [filter]="true"
          formControlName="companies"
          valueLabel="name"
          icon="mat-outlined mat-apartment"
          placeHolder="Firma"
          aria-describedby="companyHelp"
          [style]="{ width: '100%' }"
          label="{{ 'contractor-subcontractor' | translate }}"
          id="companyInput"
          touchedDirty
          class="p-inputwrapper-filled">
        </lazy-multiselect>

        <lazy-multiselect
          [fetchFunction]="fetchProjectMembers"
          [filter]="true"
          formControlName="users"
          valueLabel="name"
          icon="mat-outlined mat-person"
          placeHolder="Verfasser"
          aria-describedby="usersHelp"
          [style]="{ width: '100%' }"
          label="Verfasser"
          id="userInput"
          touchedDirty
          class="p-inputwrapper-filled">
        </lazy-multiselect>
      </div>
      <div class="flex flex-column">
        <div class="field">
          <div class="p-float-label">
            <p-calendar
              #calendarInput
              id="dateRangeInput"
              class="max-w-full p-inputwrapper-filled"
              formControlName="dateRange"
              [minDate]="project.startDate"
              [maxDate]="getMaxTopDate()"
              [inline]="true"
              [showWeek]="true"
              selectionMode="range"
              [defaultDate]="calendarDefaultDate">
              <ng-template pTemplate="footer">
                <div
                  class="flex flex-row justify-content-between align-items-center ml-4"
                  *ngIf="getCalendarSelectedDateRange() !== undefined">
                  <span class="font-bold"
                    >{{ getCalendarSelectedDateRange()![0] | date : 'd.M.yyyy' }}
                    <span *ngIf="getCalendarSelectedDateRange()![1]">
                      - {{ getCalendarSelectedDateRange()![1] | date : 'd.M.yyyy' }}</span
                    >
                  </span>
                  <button
                    pButton
                    class="p-button-text p-button-sm"
                    label="Zurücksetzen"
                    (click)="clearCalendarInput()"></button></div
              ></ng-template>
            </p-calendar>
            <label for="dateRangeInput">Datum</label>
          </div>
        </div>
      </div>
    </form>
    <div class="flex flex-row justify-content-end mt-2">
      <button
        pButton
        class="p-button-sm p-button-text"
        icon="mat-outlined mat-filter-alt-off"
        label="Filter zurücksetzen"
        (click)="clearFilters()"></button>
    </div>
  </div>
</div>
