import { DatePipe } from '@angular/common';
import { IImage } from './image.interface';
import { ICompany } from './company.interface';
import { IUser } from './user.interface';

export interface IPage<T> {
  content: T[];
  empty: boolean;
  first: boolean;
  last: boolean;
  number: boolean;
  numberOfElements: boolean;
  size: number;
  totalElements: number;
  totalPage: number;
}

export interface IEntry {
  companyId: string;
  shiftStart: Date;
  shiftEnd: Date;
  date: Date;
  weather: string;
  minTemp: number;
  maxTemp: number;
  otherMeasures: string;
  workforce: string;
  sipo: string;
  specials: string;
  machines: string;
  doneWork: string;
  supply: string;
  projectId?: string;
  creatorId?: string;
  isOvernight?: boolean;
  id?: string;
  modifiedAt?: Date;
  createdAt?: Date;
  images?: IImage[];
  closed?: boolean;
  signature?: string;

  // for native usage
  syncAt?: Date;
  deletedFlag?: boolean;
  externalId?: string;
}

export type IEntryInterface = IRegularEntry | IObservantEntry | IImageEntry;

export interface IEntryDto extends IWeather {
  id?: string;
  companyId?: string;
  date: string;
  shiftStart: string;
  shiftEnd: string;
  workforce?: string;
  sipo?: string;
  specials: string;
  doneWork: string;
  machines: string;
  supply: string;

  // for native usage
  syncAt?: Date;
  externalId?: string;

  type: DiaryEntryType;
  modifiedAt: string;
  createdAt: string;
  closed: boolean;
}

export function getEntryDto(entry: IEntry, datePipe: DatePipe): IEntryDto {
  // todo refactor toEntryDto
  return {
    type: DiaryEntryType.REGULAR,
    companyId: entry.companyId,
    date: datePipe.transform(entry.date, 'yyyy-MM-dd') ?? '',
    doneWork: entry.doneWork,
    machines: entry.machines,
    maxTemp: entry.maxTemp,
    minTemp: entry.minTemp,
    otherMeasures: entry.otherMeasures,
    shiftEnd: datePipe.transform(entry.shiftEnd, 'HH:mm:ss') ?? '',
    shiftStart: datePipe.transform(entry.shiftStart, 'HH:mm:ss') ?? '',
    sipo: entry.sipo,
    specials: entry.specials,
    supply: entry.supply,
    weather: entry.weather,
    workforce: entry.workforce,
    externalId: entry.externalId,
    modifiedAt: datePipe.transform(entry.modifiedAt, 'HH:mm:ss') ?? '',
    createdAt: datePipe.transform(entry.createdAt, 'HH:mm:ss') ?? '',
    closed: entry.closed!
  };
}

export function getEntry(entry: IEntryDto, datePipe: DatePipe): IRegularEntry {
  return {
    // TODO handle id ?
    id: '',
    //companyId: entry.companyId!,
    date: new Date(entry.date),
    doneWork: entry.doneWork,
    machines: entry.machines,
    maxTemp: entry.maxTemp,
    minTemp: entry.minTemp,
    otherMeasures: entry.otherMeasures,
    shiftEnd: new Date(`${datePipe.transform(entry.date, 'yyyy-MM-dd')}T${entry.shiftEnd}`),
    shiftStart: new Date(`${datePipe.transform(entry.date, 'yyyy-MM-dd')}T${entry.shiftStart}`),
    // shiftEnd: entry.shiftEnd,
    // shiftStart: entry.shiftStart,
    sipo: entry.sipo!,
    specials: entry.specials,
    supply: entry.supply,
    weather: entry.weather,
    workforce: entry.workforce!,
    modifiedAt: new Date(),

    // native usage
    externalId: entry.externalId,
    // TODO refactor
    creator: undefined,
    images: [],
    imageIds: [],
    type: DiaryEntryType.REGULAR,
    createdAt: new Date(),
    closed: entry.closed
  };
}

export enum DiaryEntryType {
  REGULAR = 'regular',
  OBSERVANT = 'observant',
  IMAGE = 'image'
}

export interface IAbstractEntry {
  id: string;
  date: Date;
  type: DiaryEntryType;
  closed: boolean;
  createdAt: Date;
  modifiedAt: Date;
  creator?: IUser;
  entryId?: string;
}

export interface IWeather {
  minTemp: number;
  maxTemp: number;
  weather: string;
  otherMeasures: string;
}

export interface IRegularEntry extends IAbstractEntry, IWeather {
  companyId?: string;
  company?: ICompany;

  shiftStart: Date;
  shiftEnd: Date;

  doneWork: string;
  machines: string;
  supply: string;
  images: IImageEntry[];
  imageIds: string[];

  workforce: string;
  sipo: string;
  specials: string;

  // TODO refactor externalId
  externalId?: string;
}

export interface IObservantEntry extends IAbstractEntry, IWeather {
  observations: string;
  shiftStart: string;
  shiftEnd: string;

  images: IImageEntry[];
  imageIds: string[];
}

export interface IImageEntry extends IAbstractEntry {
  description: string;
}

export interface IAbstractEntryRequest {
  date: string;
  type: DiaryEntryType;
}

export interface IImageEntryRequest extends IAbstractEntryRequest {
  description: string;
}

export interface IWeatherRequest {
  minTemp?: number;
  maxTemp?: number;
  weather?: string;
  otherMeasures?: string;
}

export interface IRegularEntryRequest extends IAbstractEntryRequest, IWeatherRequest {
  company?: string;

  shiftStart?: string;
  shiftEnd?: string;

  doneWork?: string;
  machines?: string;
  supply?: string;

  workforce?: string;
  sipo?: string;
  specials?: string;
}

export interface IObservantEntryRequest extends IAbstractEntryRequest, IWeatherRequest {
  observations?: string;
}

export type IEntryRequestType = IRegularEntryRequest | IObservantEntryRequest | IImageEntryRequest;
