import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslateModule } from '@ngx-translate/core';
import { DropdownModule } from 'primeng/dropdown';

import { LazyCoreComponent } from '../lazy-core/lazy-core.component';
import { TouchedDirtyDirective } from '../../../../../../shared/src/lib/directives/touched-dirty.directive';

@Component({
  standalone: true,
  selector: 'lazy-dropdown',
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    DropdownModule,
    TranslateModule,
    TouchedDirtyDirective
  ],
  templateUrl: './lazy-dropdown.component.html',
  styleUrls: ['./lazy-dropdown.component.scss']
})
export class LazyDropdownComponent<T> extends LazyCoreComponent<T> {
  /**
   * @deprecated Replaced/Obsolete with proper ControlValueAccessor
   */
  public setValue(object: T) {
    this.writeValue(object);
  }
}
