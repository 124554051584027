import { UploadStatusEnum } from 'shared';
import { UploadActions, UploadActionTypes } from './upload-queue.actions';
import { initialUploadState, uploadAdapter, UploadState } from './upload-queue.state';

export function uploadQueueReducer(state = initialUploadState, action: UploadActions): UploadState {
  switch (action.type) {
    case UploadActionTypes.AddUploadTask:
      return uploadAdapter.addOne(action.payload.task, state);

    case UploadActionTypes.AllUploadsCompleted:
      return { ...state, uploadInProgress: false };

    case UploadActionTypes.UploadNext:
      return { ...state, uploadInProgress: true };

    case UploadActionTypes.UploadStart:
      return uploadAdapter.updateOne(
        {
          id: action.payload.upload.id,
          changes: {
            status: UploadStatusEnum.Started
          }
        },
        state
      );

    case UploadActionTypes.UploadCompleted:
      return uploadAdapter.removeOne(action.payload.id, state);

    case UploadActionTypes.UploadFailed:
      return uploadAdapter.updateOne(
        {
          id: action.payload.id,
          changes: {
            error: action.payload.error,
            status: UploadStatusEnum.Failed
          }
        },
        state
      );

    case UploadActionTypes.UploadCancel:
      return uploadAdapter.removeOne(action.payload.id, state);

    default: {
      return state;
    }
  }
}
