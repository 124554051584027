import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import { IOrganization, IUploadImage, IUploadTask } from 'shared';
import { IEntry, IImage, IProject } from 'shared';

export enum UploadActionTypes {
  // upload types
  UploadEntry = '[Upload] Entry',
  UploadImage = '[Upload] Image',
  UploadDocument = '[Upload] Document',

  UploadEntrySuccess = '[Upload] Entry',
  UploadImageSuccess = '[Upload] Image',
  UploadDocumentSuccess = '[Upload] Document',

  // abstract queue types
  AddUploadTask = '[Upload] Add',
  UploadCancel = '[Upload] Cancel',
  UploadRetry = '[Upload] Retry',

  // internal queue types
  UploadNext = '[Upload] Next',
  UploadStart = '[Upload] Start',
  UploadStarted = '[Upload] Started',
  UploadProgressed = '[Upload] Progressed',
  UploadCompleted = '[Upload] Completed',
  UploadFailed = '[Upload] Failed',

  AllUploadsCompleted = '[Upload] All Completed'
}

// upload types

export class UploadEntry implements Action {
  readonly type = UploadActionTypes.UploadEntry;

  constructor(organization: IOrganization, project: IProject, entry: IEntry) {}
}

export class UploadImage implements Action {
  readonly type = UploadActionTypes.UploadImage;

  constructor(public payload: { image: IUploadImage }) {}
}

export class UploadDocument implements Action {
  readonly type = UploadActionTypes.UploadDocument;

  constructor(public payload: { organizationId: string; projectId: string; document: any }) {}
}

export class UploadImageSuccess implements Action {
  readonly type = UploadActionTypes.UploadImageSuccess;

  constructor(public payload: { image: IImage }) {}
}

// abstract queue types

export class AddUploadTask implements Action {
  readonly type = UploadActionTypes.AddUploadTask;

  constructor(public payload: { task: IUploadTask }) {}
}

export class UploadCancel implements Action {
  readonly type = UploadActionTypes.UploadCancel;

  constructor(public payload: { id: string }) {}
}

export class UploadRetry implements Action {
  readonly type = UploadActionTypes.UploadRetry;

  constructor(public payload: { task: IUploadTask }) {}
}

// internal queue types
export class UploadNext implements Action {
  readonly type = UploadActionTypes.UploadNext;

  constructor() {}
}

export class UploadStart implements Action {
  readonly type = UploadActionTypes.UploadStart;

  constructor(public payload: { upload: IUploadTask }) {}
}

export class UploadStarted implements Action {
  readonly type = UploadActionTypes.UploadStarted;

  constructor(public payload: { id: string }) {}
}

export class UploadProgressed implements Action {
  readonly type = UploadActionTypes.UploadProgressed;

  constructor(public payload: { id: string; progress: number }) {}
}

export class UploadCompleted implements Action {
  readonly type = UploadActionTypes.UploadCompleted;

  constructor(public payload: { id: string; response: HttpResponse<any> }) {}
}

export class UploadFailed implements Action {
  readonly type = UploadActionTypes.UploadFailed;

  constructor(public payload: { id: string; error?: HttpErrorResponse }) {}
}

export class AllUploadsCompleted implements Action {
  readonly type = UploadActionTypes.AllUploadsCompleted;
}

export type UploadActions =
  | UploadEntry
  | UploadImage
  | UploadDocument
  | AddUploadTask
  | UploadCancel
  | UploadRetry
  | UploadNext
  | UploadStart
  | UploadStarted
  | UploadProgressed
  | UploadCompleted
  | UploadFailed
  | AllUploadsCompleted;
