import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DiaryEntryType, IEntryInterface, IRegularEntry } from 'shared';
import { IDiaryEntryFormGroupTypes, IRegularDiaryEntryFormGroup, TForm } from './abstract-entry-form-groups.interface';
import { DatePipe } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class AbstractDiaryFactoryService {
  constructor(private datePipe: DatePipe, private fb: FormBuilder) {}

  public buildFormGroup(entry: IEntryInterface): TForm<IDiaryEntryFormGroupTypes> {
    let form: FormGroup | undefined = undefined;
    switch (entry.type) {
      case DiaryEntryType.REGULAR:
        form = this.buildRegularEntryFormGroup(<IRegularEntry>entry);
        break;
      // case DiaryEntryType.OBSERVANT:
      //   form = this.buildObservantEntryFormGroup(<IObservantEntry>entry);
    }

    return form as TForm<IDiaryEntryFormGroupTypes>;
  }

  public buildInitialFormGroup(type: DiaryEntryType): TForm<IDiaryEntryFormGroupTypes> {
    let form: FormGroup | undefined = undefined;
    switch (type) {
      case DiaryEntryType.REGULAR:
        form = this.buildRegularEntryFormGroup();
        break;
      // case DiaryEntryType.OBSERVANT:
      //   form = this.buildObservantEntryFormGroup();
    }

    return form as TForm<IDiaryEntryFormGroupTypes>;
  }

  public buildRegularEntryFormGroup(entry?: IRegularEntry): TForm<IRegularDiaryEntryFormGroup> {
    return this.fb.group({
      // company
      company: new FormControl(entry?.company, [Validators.required]),
      shiftStart: new FormControl(entry?.shiftStart ? new Date(entry.shiftStart) : this.getDefaultShiftStart(), [
        Validators.required
      ]),
      shiftEnd: new FormControl(entry?.shiftEnd ? new Date(entry.shiftEnd) : this.getDefaultShiftEnd(), [
        Validators.required
      ]),
      // workforce
      workforce: new FormControl(entry?.workforce, [Validators.maxLength(255)]),
      sipo: new FormControl(entry?.sipo, [Validators.maxLength(255)]),
      specials: new FormControl(entry?.specials, [Validators.maxLength(255)]),
      // weather
      minTemp: new FormControl(entry?.minTemp, [Validators.required, Validators.max(60), Validators.min(-40)]),
      maxTemp: new FormControl(entry?.maxTemp, [Validators.required, Validators.max(60), Validators.min(-40)]),
      weather: new FormControl(entry?.weather, [Validators.required, Validators.maxLength(255)]),
      otherMeasures: new FormControl(entry?.otherMeasures, [Validators.maxLength(255)]),
      // work
      doneWork: new FormControl(entry?.doneWork),
      machines: new FormControl(entry?.machines),
      supply: new FormControl(entry?.supply, [Validators.maxLength(255)])
    }) as TForm<IRegularDiaryEntryFormGroup>;
  }

  private getDefaultShiftStart(): Date {
    const date = new Date();
    return this.roundDateToNextFiveMinutes(date);
  }

  private getDefaultShiftEnd(): Date {
    const date = new Date();
    date.setHours(date.getHours() + 8);
    return this.roundDateToNextFiveMinutes(date);
  }

  private roundDateToNextFiveMinutes(date: Date): Date {
    // Get the current minutes
    const minutes = date.getMinutes();
    // Calculate the next multiple of 5
    const nextMultipleOfFive = Math.floor(minutes / 10) * 10;
    // Create a new Date object
    const roundedDate = new Date(date);
    // Set the minutes to the next multiple of 5 and zero out the seconds and milliseconds
    roundedDate.setMinutes(nextMultipleOfFive);
    roundedDate.setSeconds(0);
    roundedDate.setMilliseconds(0);
    // If the minutes are 60, it means the hour needs to be incremented
    if (nextMultipleOfFive === 60) {
      roundedDate.setHours(roundedDate.getHours() + 1);
      roundedDate.setMinutes(0);
    }
    return roundedDate;
  }
}
