import { globalCookieConfig } from '../configs/cookie.config';
import { IEnvironment } from '../interfaces/environment.interface';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

export const environment: IEnvironment = {
  production: false,
  normalDelay: 1000,
  managementServerBaseURL: 'https://dev.management.bahntagebuch.de',
  managementServerAudience: 'https://dev.management.bahntagebuch.de/',
  resourceAudience: 'https://dev.api.bahntagebuch.de/',

  firebaseConfig: {
    apiKey: 'AIzaSyCeOsGCzM08jn30fsMiQF7bgE0teNKfevk',
    authDomain: 'bahntagebuch.firebaseapp.com',
    projectId: 'bahntagebuch',
    storageBucket: 'bahntagebuch.appspot.com',
    messagingSenderId: '1025797095968',
    appId: '1:1025797095968:web:27e39e821c1d13fe5af245',
    measurementId: 'G-LVXNQJQENR'
  },
  cookieConfig: {
    ...globalCookieConfig,
    cookie: {
      domain: 'dev.bahntagebuch.de'
    }
  },
  auth: {
    domain: 'dev.auth.bahntagebuch.de',
    clientId: 'l81IObnRnG8pu5uJCEi6oU0jR5X484Ko',
    clientSecret: '',
    authorizationParams: {
      redirect_uri: 'https://dev.app.bahntagebuch.de/'
    }
  },
  storeModuleImports: [StoreDevtoolsModule.instrument()]
};
