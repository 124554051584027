import { map } from 'rxjs/operators';
import { HttpEventType, HttpRequest } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { IDiaryEntry, IGenericUploadTask, IImage, IOrganization, IProject } from 'shared';
import { ResourceHttpClient } from '../interceptors/resource-server-client.service';
import { ImageMetadata } from './upload-queue.service';

@Injectable({
  providedIn: 'root'
})
export class UploadService {
  private queue: BehaviorSubject<IGenericUploadTask<any>[]> = new BehaviorSubject<IGenericUploadTask<any>[]>([]);
  private failedRequests: BehaviorSubject<IGenericUploadTask<any>[]> = new BehaviorSubject<IGenericUploadTask<any>[]>(
    []
  );

  constructor(private httpClient: ResourceHttpClient) {}

  injectTask(task: IGenericUploadTask<any>) {
    this.queue.next(this.queue.value.concat([task]));
    if (this.queue.value.length === 1) {
      this.executeNext();
    }
    return task;
  }

  private executeNext() {
    if (this.queue.value.length > 0) {
      let task: IGenericUploadTask<any> = this.queue.value[0];
      this.httpClient.request(task.request).subscribe(event => {
        switch (event.type) {
          case HttpEventType.Sent:
            task.state.next('inProgress');
            break;
          case HttpEventType.UploadProgress:
            task.progress?.next(event.loaded / event.total!);
            break;
          case HttpEventType.Response:
            if (event.status == 200) {
              console.log('upload finished', event.body);
              task.state.next('finished');
              task.progress?.next(100);
              this.queue.next(this.queue.value.slice(1));
              this.executeNext();
            } else {
              task.state.next('error');
              task.progress?.next(100);
              this.failedRequests.next(this.failedRequests.value.concat(this.queue.value.splice(0, 1)));
            }
            break;
        }
      });
    }
  }

  public getPendingRequests(): Observable<IGenericUploadTask<any>[]> {
    return this.queue.asObservable();
  }

  public getQueueLength(): Observable<number> {
    return this.queue.pipe(map(queue => queue.length));
  }

  public uploadImage(
    organization: IOrganization,
    project: IProject,
    entry: IDiaryEntry,
    image: IImage
  ): IGenericUploadTask<IImage> {
    // build form data
    let formData = new FormData();
    formData.append('file', image.file!, image.title);
    const metaData: ImageMetadata = {
      title: image.title,
      description: image.description,
      createdAt: image.createdAt,
      type: image.type!
    };
    let jsonblob = new Blob([JSON.stringify(metaData)], {
      type: 'application/json'
    });
    formData.append('image', jsonblob);
    let url = entry
      ? organization.api + 'projects/' + project.id + '/entries/' + entry.id + '/images'
      : organization.api + 'projects/' + project.id + '/images';

    // build the httpRequest
    const request = new HttpRequest('POST', url, formData, {
      reportProgress: true
    });

    // build the uploadTask
    let task: IGenericUploadTask<IImage> = {
      name: '',
      request: request,
      body: image,
      state: new BehaviorSubject('pending'),
      progress: request.reportProgress == true ? new BehaviorSubject(0) : undefined,
      response: new Observable<any>(undefined)
    };

    this.injectTask(task);
    return task;
  }
}
