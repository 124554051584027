<div class="image-description-container" [class.visible]="visible">
  <div class="creator">
    <img *ngIf="image.creator?.picture" [attr.src]="image.creator?.picture" />
    <h2>{{ image.creator?.name }}</h2>
  </div>
  <div
    class="flex flex-row justify-content-between w-full align-items-start"
    *ngIf="!editMode; else imageDescriptionForm">
    <p class="description">{{ image.description }}</p>
    <button
      *ngIf="editable"
      pButton
      class="p-button-sm p-button-text ml-2"
      icon="pi pi-pencil"
      (click)="enableEditMode()"></button>
  </div>
</div>

<ng-template #imageDescriptionForm>
  <form
    class="flex flex-column w-full"
    [formGroup]="editFormGroup"
    (ngSubmit)="editFormGroup.valid ? saveDescription() : editFormGroup.markAllAsTouched()">
    <div class="flex w-full">
      <div class="field">
        <div class="p-float-label">
          <textarea
            formControlName="description"
            pInputTextarea
            [autoResize]="true"
            id="descriptionInput"
            class="p-filled w-full"
            touchedDirty
            rows="2">
          </textarea>
          <label for="descriptionInput">{{ 'description' | translate }}</label>
        </div>
      </div>
    </div>
    <div class="flex justify-content-end w-full">
      <button
        pButton
        class="p-button-sm p-button-text"
        type="button"
        [disabled]="saveInProgress"
        (click)="editMode = false">
        {{ 'cancel' | translate }}
      </button>
      <button pButton class="p-button-sm ml-2" [loading]="saveInProgress" label="Speichern"></button>
    </div>
  </form>
</ng-template>
