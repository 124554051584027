<div class="menu-content">
  <app-customer-selector></app-customer-selector>
  <ng-container
    [ngTemplateOutlet]="menuSectionOutlet"
    [ngTemplateOutletContext]="{ routes: menuRoutes }"></ng-container>
  <ng-container
    *ngIf="projectRoutes?.length; else noProjectTemplate"
    [ngTemplateOutlet]="menuSectionOutlet"
    [ngTemplateOutletContext]="{ routes: projectRoutes }"></ng-container>

  <div class="menu-section spacer">
    <div class="menu-button-wrapper">
      <button pButton class="menu-button" [disabled]="isProduction || disabled">
        <span class="mat-outlined">person</span>
        <p>{{ 'profile' | translate }}</p>
      </button>
    </div>
    <div class="menu-button-wrapper">
      <button #menuButton pButton class="menu-button" [routerLink]="'/logout'">
        <span class="mat-outlined">logout</span>
        <p>{{ 'logout' | translate }}</p>
      </button>
    </div>
  </div>
</div>

<ng-template #menuSectionOutlet let-routes="routes">
  <div class="menu-section">
    <div class="menu-entry" *ngFor="let route of routes">
      <div class="menu-button-wrapper">
        <button
          pButton
          [class.p-button-secondary]="!activeSubRoute && activeRoute && activeRoute.url === route.url"
          class="menu-button"
          [disabled]="(isProduction && route.disabled) || disabled"
          [routerLink]="route.url">
          <span class="mat-outlined"
            >{{ route.icon }} <span class="menu-button-badge" *ngIf="route.hasBadge"></span
          ></span>
          <p class="noselect">{{ route.title | translate }}</p>
          <span class="menu-button-text-badge" *ngIf="route.hasBadge">1</span>
        </button>
      </div>
      <div class="sub-pages" *ngIf="route.subRoutes.length > 0">
        <div class="menu-button-wrapper" *ngFor="let subRoute of route.subRoutes">
          <button
            pButton
            [class.p-button-secondary]="activeSubRoute && activeSubRoute.id === subRoute.id"
            class="menu-button"
            [disabled]="(isProduction && subRoute.disabled) || disabled"
            [routerLink]="subRoute.url">
            <span class="mat-outlined rotate-180">turn_left</span>
            <span class="mat-outlined">{{ subRoute.icon }}</span>
            <p>{{ subRoute.title | translate }}</p>
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #noProjectTemplate>
  <div class="menu-section">
    <p id="noProject">Kein Projekt ausgewählt</p>
  </div>
</ng-template>
