import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IUser } from 'shared';

@Injectable({
  providedIn: 'root'
})
export abstract class UserService {
  abstract getLoggedUser(): Observable<IUser | undefined>;

  abstract setLoggedUser(user: IUser): void;
}
