import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ConverterService {
  public static ConvertLocalDateToUTCYYYYMMDD(date: Date): string {
    const utcDate = this.ConvertLocalDateToUTC(date);
    return utcDate.toISOString().split('T')[0];
  }

  public static ConvertLocalDateToUTC(date: Date): Date {
    const offset = date.getTimezoneOffset();
    return new Date(date.getTime() - offset * 60 * 1000);
  }
}
