import { Component, OnInit } from '@angular/core';
import { OrganizationService } from 'data-access';
import { IOrganization } from 'shared';

@Component({
  selector: 'app-not-responding',
  templateUrl: './not-responding.page.html',
  styleUrls: ['./not-responding.page.scss']
})
export class NotRespondingPage implements OnInit {
  organization: IOrganization | undefined = undefined;

  constructor(private organizationService: OrganizationService) {
    this.organizationService.getSelectedOrganization().subscribe(org => (this.organization = org));
  }

  ngOnInit() {}
}
