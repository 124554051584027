import { Action } from '@ngrx/store';

export enum DiaryActionTypes {
  DiaryLoadFrom = '[Diary] Load From',
  DiaryUpdateTopLoaderActive = '[Diary] Update Top Loader Active',
  DiaryUpdateBottomLoaderActive = '[Diary] Update Bottom Loader Active',
  DiaryUpdateTopLoading = '[Diary] Update Top Loading',
  DiaryUpdateBottomLoading = '[Diary] Update Bottom Loading',
  DiaryUpdateTopDate = '[Diary] Update Top Date',
  DiaryUpdateBottomDate = '[Diary] Update Bottom Date'
}

export class DiaryLoadFrom implements Action {
  readonly type = DiaryActionTypes.DiaryLoadFrom;

  constructor(public payload: { date: Date }) {}
}

export class DiaryUpdateTopLoaderActive implements Action {
  readonly type = DiaryActionTypes.DiaryUpdateTopLoaderActive;

  constructor(public payload: { active: boolean }) {}
}

export class DiaryUpdateBottomLoaderActive implements Action {
  readonly type = DiaryActionTypes.DiaryUpdateBottomLoaderActive;

  constructor(public payload: { active: boolean }) {}
}

export class DiaryUpdateTopLoading implements Action {
  readonly type = DiaryActionTypes.DiaryUpdateTopLoading;

  constructor(public payload: { loading: boolean }) {}
}

export class DiaryUpdateBottomLoading implements Action {
  readonly type = DiaryActionTypes.DiaryUpdateBottomLoading;

  constructor(public payload: { loading: boolean }) {}
}

export class DiaryUpdateTopDate implements Action {
  readonly type = DiaryActionTypes.DiaryUpdateTopDate;

  constructor(public payload: { date: Date }) {}
}

export class DiaryUpdateBottomDate implements Action {
  readonly type = DiaryActionTypes.DiaryUpdateBottomDate;

  constructor(public payload: { date: Date }) {}
}

export type DiaryActions =
  | DiaryLoadFrom
  | DiaryUpdateTopLoaderActive
  | DiaryUpdateBottomLoaderActive
  | DiaryUpdateTopLoading
  | DiaryUpdateBottomLoading
  | DiaryUpdateTopDate
  | DiaryUpdateBottomDate;
