import { IOrganization, IProject, IRoute } from 'shared';

export const UUID_REGEX = '[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}';
export const PROJECTS_REGEX = new RegExp('/organizations/[a-zA-Z0-9_-]+/projects');
export const PROJECTS_PAGES_REGEX = new RegExp('/organizations/[a-zA-Z0-9_-]+/projects/[a-zA-Z0-9_-]+');
export const ORGANIZATION_PAGES_REGEX = new RegExp('/organizations/[a-zA-Z0-9_-]+/');
export function getMenuRoutes(organization: IOrganization, hasNewAnnouncement: boolean): IRoute[] {
  return [
    {
      id: 1,
      title: 'overview',
      url: '/organizations/' + organization.id + '/landing',
      icon: 'analytics',
      disabled: true,
      subRoutes: [],
      regEx: new RegExp('/organizations/[a-zA-Z0-9_-]+/landing')
    },
    {
      id: 1,
      title: 'Mitteilungen',
      url: '/organizations/' + organization.id + '/announcements',
      icon: 'notifications',
      subRoutes: [],
      hasBadge: hasNewAnnouncement,
      regEx: new RegExp('/organizations/[a-zA-Z0-9_-]+/announcements')
    },
    {
      id: 2,
      title: 'projects',
      url: '/organizations/' + organization.id + '/projects',
      icon: 'layers',
      regEx: PROJECTS_REGEX,
      subRoutes: [
        {
          id: 3,
          title: 'new-project',
          url: '/organizations/' + organization.id + '/projects/new',
          icon: 'add',
          regEx: new RegExp('/organizations/[a-zA-Z0-9_-]+/projects/new')
        },
        {
          id: 4,
          title: 'archived-projects',
          url: '/organizations/' + organization.id + '/projects/archive',
          icon: 'inventory_2',
          disabled: true,
          regEx: new RegExp('/organizations/[a-zA-Z0-9_-]+/projects/archive')
        },
        {
          id: 5,
          title: 'company-list',
          url: '/organizations/' + organization.id + '/projects/companies',
          icon: 'apartment',
          regEx: new RegExp('/organizations/[a-zA-Z0-9_-]+/projects/companies')
        }
      ]
    },
    {
      id: 6,
      title: 'access',
      url: '/organizations/' + organization.id + '/members',
      icon: 'lock',
      regEx: new RegExp('/organizations/[a-zA-Z0-9_-]+/members'),
      subRoutes: []
    }
  ];
}

export function getProjectRoutes(organization?: IOrganization, project?: IProject): IRoute[] {
  return !project
    ? []
    : [
        {
          id: 7,
          title: project.name,
          url: '/organizations/' + organization?.id + '/projects/' + project?.id,
          icon: 'layers',
          disabled: true,
          regEx: new RegExp('/organizations/[a-zA-Z0-9_-]+/projects/' + UUID_REGEX),
          subRoutes: [
            {
              id: 8,
              title: 'diary',
              url: '/organizations/' + organization?.id + '/projects/' + project?.id + '/diary',
              icon: 'book',
              regEx: new RegExp('/organizations/[a-zA-Z0-9_-]+/projects/' + UUID_REGEX + '/diary')
            },
            {
              id: 14,
              title: 'Export',
              url: '/organizations/' + organization?.id + '/projects/' + project?.id + '/export',
              icon: 'file_open',
              disabled: false,
              regEx: new RegExp('/organizations/[a-zA-Z0-9_-]+/projects/' + UUID_REGEX + '/export')
            },
            {
              id: 9,
              title: 'documents',
              url: '/organizations/' + organization?.id + '/projects/' + project?.id + '/documents',
              icon: 'description',
              disabled: true,
              regEx: new RegExp('/organizations/[a-zA-Z0-9_-]+/projects/' + UUID_REGEX + '/documents')
            },
            {
              id: 10,
              title: 'plannings',
              url: '/organizations/' + organization?.id + '/projects/' + project?.id + '/plans',
              icon: 'folder',
              disabled: true,
              regEx: new RegExp('/organizations/[a-zA-Z0-9_-]+/projects/' + UUID_REGEX + '/plans')
            },
            {
              id: 11,
              title: 'members',
              url: '/organizations/' + organization?.id + '/projects/' + project?.id + '/members',
              icon: 'person',
              regEx: new RegExp('/organizations/[a-zA-Z0-9_-]+/projects/' + UUID_REGEX + '/members')
            },
            {
              id: 12,
              title: 'companies',
              url: '/organizations/' + organization?.id + '/projects/' + project?.id + '/companies',
              icon: 'apartment',
              regEx: new RegExp('/organizations/[a-zA-Z0-9_-]+/projects/' + UUID_REGEX + '/companies')
            },
            {
              id: 13,
              title: 'details',
              url: '/organizations/' + organization?.id + '/projects/' + project?.id + '/details',
              icon: 'info',
              regEx: new RegExp('/organizations/[a-zA-Z0-9_-]+/projects/' + UUID_REGEX + '/details')
            }
          ]
        }
      ];
}

export function getUtilRoutes(): IRoute[] {
  return [
    {
      id: 1,
      title: 'organisations',
      url: '/organizations',
      icon: 'bar-chart-2',
      disabled: true,
      regEx: new RegExp('/organizations/&'),
      subRoutes: []
    }
  ];
}
