import { createFeatureSelector, createSelector } from '@ngrx/store';
import { organizationAdapter, OrganizationsState } from './organization.state';

export const organizationState = createFeatureSelector<OrganizationsState>('organizations');

const { selectAll, selectEntities, selectIds, selectTotal } = organizationAdapter.getSelectors();

export const selectOrganizationById = (organizationId: string) =>
  createSelector(organizationState, state => state.entities[organizationId]);

export const selectAllOrganizations = createSelector(organizationState, selectAll);

export const selectAllOrganizationsLoaded = createSelector(organizationState, state => state.allOrganizationsLoaded);

export const selectAllOrganizationsLoadedError = createSelector(
  organizationState,
  state => state.allOrganizationsLoadedError
);

export const selectSelectedOrganization = createSelector(organizationState, state =>
  state.selectedOrganizationId ? state.entities[state.selectedOrganizationId] : undefined
);
