<div *ngIf="loading">
  <p-progressSpinner class="mb-2 ml-3"></p-progressSpinner>
</div>

<form class="flex flex-column" *ngIf="formGroup && !loading" [formGroup]="formGroup">
  <div class="p-float-label">
    <input pInputText id="nameInput" formControlName="name" touchedDirty class="p-filled w-full" />
    <label for="nameInput">{{ 'name' | translate }}</label>
  </div>

  <div class="p-float-label">
    <textarea
      pInputTextarea
      formControlName="description"
      [autoResize]="true"
      id="descriptionInput"
      class="p-filled w-full"
      touchedDirty
      rows="2">
    </textarea>
    <label for="descriptionInput">{{ 'description' | translate }}</label>
  </div>

  <h2 class="mb-0 mt-2">Berechtigungen</h2>

  <div *ngFor="let permissionEntity of permissionsMap.keys()">
    <h3 class="mb-2">{{ permissionEntity | translate }}</h3>
    <mat-chip-listbox *ngIf="!loading" [multiple]="true" [selectable]="true">
      <mat-chip-option
        *ngFor="let permission of permissionsMap.get(permissionEntity)"
        (click)="selectPermission(permission)"
        (removed)="unselectPermission(permission)"
        [selected]="formGroup.controls.selectedPermissions.value?.includes(permission)"
        selectable="true"
        removable="true">
        {{ permission | translate }}
        <mat-icon matChipRemove *ngIf="formGroup.controls.selectedPermissions.value?.includes(permission)"
          >cancel
        </mat-icon>
      </mat-chip-option>
    </mat-chip-listbox>
  </div>
</form>
