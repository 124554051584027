<div [ngSwitch]="diaryObject.type">
  <div *ngSwitchCase="'diaryDate'">
    <div
      class="diary-object"
      [class.last-diary-object]="suppressBottomSpacer"
      [class.diary-object-highlighted]="highlighted">
      <div class="diary-object-header">
        <div class="title">
          <h3>{{ getTitle() }}</h3>
        </div>
        <div class="controls">
          <button
            pButton
            class="p-button-text"
            [disabled]="!canEdit"
            icon="mat-outlined mat-add-notes"
            (click)="newEntry()"></button>
          <button
            pButton
            class="p-button-text"
            [disabled]="!canEdit"
            icon="mat-outlined mat-photo-camera"
            (click)="imagePreviewList.triggerImageInput()"></button>
        </div>
      </div>

      <div class="diary-object-content gap-4">
        <div
          class="no-entry-container"
          *ngIf="(checkIfDateIsToday() || checkIfDateIsYesterday()) && diaryObject.diaryDate?.entries?.length == 0">
          <div class="col">
            <h1>{{ 'add-entry' | translate }}</h1>
            <button
              pButton
              class="p-button-text"
              icon="mat-outlined mat-edit"
              *ngIf="canEdit"
              (click)="newEntry()"></button>
          </div>
          <div class="col">
            <h1>{{ 'add-photo' | translate }}</h1>
            <button
              pButton
              class="p-button-text"
              icon="mat-outlined mat-photo-camera"
              *ngIf="canEdit"
              (click)="imagePreviewList.triggerImageInput()"></button>
          </div>
        </div>

        <image-preview-list-component
          #imagePreviewList
          dropzone
          (onFilesDropped)="imagePreviewList.openImageUploadPopover($event)"
          [imageIds]="imageIds"
          [date]="diaryObject.diaryDate!.date">
        </image-preview-list-component>

        <div
          class="no-entry-container"
          *ngIf="!checkIfDateIsToday() && !checkIfDateIsYesterday() && !diaryObject.diaryDate?.entries?.length">
          <h1>{{ 'no-entrys' | translate }}</h1>
        </div>
        <!--      todo add trackBy: trackByEntryId-->
        <div *ngFor="let entry of entries" [ngSwitch]="entry.type">
          <diary-entry-component *ngSwitchCase="DiaryEntryType.REGULAR" [entryId]="entry.id"></diary-entry-component>
        </div>
      </div>
    </div>
  </div>

  <!-- empty dateSpan card -->
  <div
    *ngSwitchCase="'dateSpan'"
    class="diary-object"
    [class.last-diary-object]="suppressBottomSpacer"
    [class.diary-object-highlighted]="highlighted">
    <div class="diary-object-header">
      <div class="title">
        <h3>
          {{ diaryObject.dateSpan?.endDate | date : 'dd.MM.yyyy' }} -
          {{ diaryObject.dateSpan?.startDate | date : 'dd.MM.yyyy' }}
        </h3>
      </div>
      <div class="controls">
        <button
          pButton
          class="p-button-text"
          icon="mat-outlined mat-edit"
          [disabled]="!canEdit"
          (click)="newEntry()"></button>
      </div>
    </div>

    <div class="diary-object-content">
      <div class="no-entry-container">
        <h1>{{ 'no-entrys' | translate }}</h1>
      </div>
    </div>
  </div>

  <!-- project interruption card -->
  <div *ngSwitchCase="'interruption'" class="diary-object">
    <div class="diary-object-header">
      <div class="title">
        <h3>
          Unterbrechung
          {{ diaryObject.interruption?.endDate | date : 'dd.MM.yyyy' }} -
          {{ diaryObject.interruption?.startDate | date : 'dd.MM.yyyy' }}
        </h3>
      </div>
    </div>
    <div class="diary-object-content"></div>
  </div>
</div>
