import { HttpClient, HttpEventType, HttpRequest } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { IImage } from 'shared';

export interface UploadTask {
  serverURL: string;
  state: BehaviorSubject<string>;
  progress: BehaviorSubject<number>;
  objectType: string;
  object: any;
}

export interface ImageMetadata {
  title: string;
  description: string;
  createdAt: Date;
  type: string;
}

@Injectable({
  providedIn: 'root'
})
export class UploadQueueService {
  uploadQueue: UploadTask[] = [];
  uploadInProgress: boolean = false;

  constructor(private httpClient: HttpClient) {}

  /**
   * upload a single image + metadata
   * add it to the upload queue and return the uploadProgress as observable
   * @param image
   * @param blob
   * @returns Observable<number> representation of the upload progress {0-1}
   */
  public uploadImage(serverURL: string, image: IImage): UploadTask {
    this.uploadQueue.push({
      serverURL: serverURL,
      object: image,
      objectType: 'image',
      state: new BehaviorSubject<string>('pending'),
      progress: new BehaviorSubject<number>(0)
    });

    if (!this.uploadInProgress) {
      this.startUpload(this.uploadQueue.length - 1);
    }
    // return the lastly added upload task
    return this.uploadQueue[this.uploadQueue.length - 1];
  }

  // starts the upload and stops after the upload queue is empty
  // works recursive
  private async startUpload(index: number) {
    this.uploadInProgress = true;
    //break condition
    if (this.uploadQueue.length == index) {
      this.uploadInProgress = false;
      return;
    }

    // upload image
    if (this.uploadQueue[index].objectType == 'image') {
      console.log('upload image: ', this.uploadQueue[index].object);
      let formData = new FormData();
      let image: IImage = this.uploadQueue[index].object;
      formData.append('image', image.file!, image.title);
      const metaData: ImageMetadata = {
        title: image.title,
        description: image.description,
        createdAt: image.createdAt,
        type: image.type!
      };
      let jsonblob = new Blob([JSON.stringify(metaData)], {
        type: 'application/json'
      });
      formData.append('imagejson', jsonblob);
      //upload the form data

      console.log('upload now!');
      console.log(formData.get('imagejson'));
      console.log(formData.get('image'));

      //let options = new RequestOptions({ headers:headers,withCredentials: true});
      //this.postHeader = this.postHeader.set("Content-Type", "multipart/form-data")
      //this.postHeader.delete("Content-Type")

      const request = new HttpRequest('POST', this.uploadQueue[index].serverURL + '/image/add', formData, {
        reportProgress: true
      });
      this.httpClient.request(request).subscribe(event => {
        switch (event.type) {
          case HttpEventType.Sent:
            console.log('upload started');
            this.uploadQueue[index].state.next('in_progress');
            break;
          case HttpEventType.UploadProgress || HttpEventType.DownloadProgress:
            console.log('upload progress: ', event.loaded / event.total!);
            this.uploadQueue[index].progress.next(event.loaded / event.total!);
            break;
          case HttpEventType.Response:
            console.log('upload finished', event.body);
            this.uploadQueue[index].state.next('finished');
            this.uploadQueue[index].progress.next(1);
            this.startUpload((index += 1));
            break;
        }
      });
    }
  }
}
