<div class="page">
  <div class="page-content unlimited-width">
    <div class="error" *ngIf="error">
      <h1>Error</h1>
    </div>

    <div class="loader" *ngIf="!loaded">
      <mat-spinner color="primary" diameter="30"></mat-spinner>
    </div>

    <!--  For statuses of organizations-->
    <div class="org-flex">
      <ng-container *ngFor="let organization of organizations">
        <div class="card">
          <div class="card-body">
            <h1 class="card-title">{{organization.displayName}}</h1>
            <p class="id-field">id: {{organization.id}}</p>
            <div [ngStyle]="{'color': getColor(organization.status)}">
              <div class="online-indicator" [ngStyle]="{'background-color':getColor(organization.status)}"></div>
              <span class="middle">
                {{organization.status === 'up' ? 'online' : 'down' ? 'offline' : 'maintenance' ? 'maintenance' :
                'unknown status'}}</span
              >
            </div>
          </div>
          <a (click)="selectOrganization(organization)" class="link-text">auswählen ></a>
        </div>
      </ng-container>
    </div>
    <div *ngIf="organizations.length === 0 && loaded" class="no-record">
      <span>Keine assoziierten Organisationen gefunden</span>
    </div>
  </div>
</div>
