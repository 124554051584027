import { Pipe, PipeTransform } from '@angular/core';
import { IPdf } from 'shared';

@Pipe({
  name: 'castPdf'
})
export class CastPdfPipe implements PipeTransform {
  transform(value: object): IPdf | undefined {
    if (!value) {
      return undefined;
    }
    if ('pdfId' in value && 'blob' in value) {
      return value as unknown as IPdf;
    } else {
      return undefined;
    }
  }
}
