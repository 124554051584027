import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { ErrorService, ResourceService } from 'data-access';
import { ResetProjectState, RestoreLastProject } from 'data-access';
import { combineLatest, of, switchMap } from 'rxjs';
import { catchError, filter, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import { RESOURCE_KEY } from 'shared';

import * as OrganizationActions from './organization.actions';
import { selectAllOrganizationsLoaded, selectOrganizationById } from './organization.selectors';

@Injectable()
export class OrganizationEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    @Inject(RESOURCE_KEY) private resourceService: ResourceService,
    private routes: ActivatedRoute,
    private router: Router,
    private errorService: ErrorService
  ) {}

  loadOrganizationsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationActions.loadAllOrganizationsAction),
      withLatestFrom(this.store.pipe(select(selectAllOrganizationsLoaded))),
      filter(([action, loaded]) => !loaded),
      mergeMap(() =>
        this.resourceService.getOrganizations().pipe(
          switchMap(organizations => {
            return [
              OrganizationActions.loadAllSuccessAction({
                organizations: organizations
              }),
              new RestoreLastProject()
            ];
          }),
          catchError(err => {
            console.log(err);
            return of(OrganizationActions.loadAllErrorAction());
          })
        )
      )
    )
  );

  reloadOrganizationsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationActions.reloadAllOrganizationsAction),
      mergeMap(() =>
        this.resourceService.getOrganizations().pipe(
          mergeMap(organizations => {
            return [
              OrganizationActions.loadAllSuccessAction({
                organizations: organizations
              }),
              new RestoreLastProject()
            ];
          }),
          catchError(err => {
            return of(OrganizationActions.loadAllErrorAction());
          })
        )
      )
    )
  );

  //write new selected org id to local storage
  selectOrganizationEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationActions.selectOrganizationAction),
      map(action => action.organization),
      switchMap(organization =>
        combineLatest([
          of(organization),
          this.resourceService.getOrganizationStatus(organization).pipe(
            catchError(err => {
              console.log(err);
              return of(OrganizationActions.selectOrganizationErrorAction());
            })
          )
        ])
      ),
      map(([organization, response]) => {
        if (response?.status == 'ready') {
          return OrganizationActions.selectOrganizationSuccessAction({
            organizationId: organization.id
          });
        } else {
          return OrganizationActions.selectOrganizationErrorAction();
        }
      })
    )
  );

  selectOrganizationErrorEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(OrganizationActions.selectOrganizationErrorAction),
        tap(action => {
          localStorage.removeItem('organizationId');
          this.errorService.showErrorPlain({
            title: 'Organisation nicht erreichbar',
            message: 'Bitte versuchen Sie es in einigen Minuten erneut'
          });
          this.router.navigateByUrl('/organizations');
        })
      ),
    { dispatch: false }
  );

  restoreOrganizationEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationActions.restoreOrganizationAction),
      map(action => action.organizationId),
      filter(orgId => orgId != undefined),
      concatLatestFrom(orgId => this.store.select(selectOrganizationById(orgId))),
      switchMap(([orgId, organization]) => {
        if (organization) {
          return [
            OrganizationActions.selectOrganizationAction({
              organization: organization
            })
          ];
        } else {
          return this.resourceService.getOrganization(orgId).pipe(
            switchMap(organization => [
              OrganizationActions.restoreOrganizationSuccessAction({
                organization: organization
              }),
              OrganizationActions.selectOrganizationAction({
                organization: organization
              })
            ]),
            catchError(err => of(OrganizationActions.restoreOrganizationErrorAction()))
          );
        }
      })
    )
  );

  resetOrganizationStateEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationActions.selectOrganizationSuccessAction),
      tap(action => localStorage.setItem('organizationId', action.organizationId)),
      switchMap(action => [new ResetProjectState()])
    )
  );
}
