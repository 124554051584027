import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppState } from '@auth0/auth0-angular';
import { Store } from '@ngrx/store';
import {
  loadAllOrganizationsAction,
  selectAllOrganizations,
  selectAllOrganizationsLoaded,
  selectAllOrganizationsLoadedError,
  selectSelectedOrganization
} from 'data-access';
import * as OrganizationActions from 'data-access';
import { filter, map } from 'rxjs';
import { IOrganization } from 'shared';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.page.html',
  styleUrls: ['./overview.page.scss']
})
export class OrganizationOverviewPage {
  organizations: IOrganization[] = [];
  loaded = false;
  error = false;

  constructor(private router: Router, private route: ActivatedRoute, private store: Store<AppState>) {
    store.select(selectAllOrganizations).subscribe(orgs => (this.organizations = orgs));
    store.select(selectAllOrganizationsLoaded).subscribe(loaded => (this.loaded = loaded));
    store.select(selectAllOrganizationsLoadedError).subscribe(error => (this.error = error));
    this.store.dispatch(loadAllOrganizationsAction());
    route.paramMap
      .pipe(
        map(params => {
          let orgId = params.get('organizationId');
          if (!orgId) {
            orgId = localStorage.getItem('organizationId');
          }
          return orgId;
        }),
        filter(orgId => orgId != undefined)
      )
      .subscribe(orgId => {
        this.store.dispatch(
          OrganizationActions.restoreOrganizationAction({
            organizationId: orgId!
          })
        );
      });
    store
      .select(selectSelectedOrganization)
      .pipe(filter(org => org != undefined))
      .subscribe(org => {
        this.selectOrganization(org!);
      });
  }

  selectOrganization(organization: IOrganization) {
    this.router.navigate([organization.id], { relativeTo: this.route });
  }

  getColor(color: string) {
    switch (color) {
      case 'up':
        return '#4bb757';
      case 'down':
        return '#ff1416';
      case 'maintenance':
        return '#c28d4f';
      default:
        return '#7A7265';
    }
  }
}
