import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatTable } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { ErrorService, ResourceService } from 'data-access';
import { combineLatest } from 'rxjs';
import { IInvitation, IInvitationPostRequest, IInviter, IOrganization, IRole, RESOURCE_KEY } from 'shared';

@Component({
  selector: 'app-invite',
  templateUrl: './invite.component.html',
  styleUrls: ['./invite.component.scss']
})
export class InviteComponent implements OnInit {
  @ViewChild('invitationTable') invitationTable?: MatTable<IInviter>;
  organization?: IOrganization;
  inviteFormControl: FormControl = new FormControl('', [Validators.required, Validators.email]);
  invites: IInvitation[] = [];
  roles: IRole[] = [];
  hasError = false;
  loading = true;
  createInProgress = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    @Inject(RESOURCE_KEY) private resourceService: ResourceService,
    private errorService: ErrorService
  ) {}

  ngOnInit() {
    this.organization = this.activatedRoute.parent?.parent?.parent?.snapshot.data['organization'];
    this.loadPageData();
  }

  loadPageData() {
    this.loading = true;
    this.hasError = false;
    combineLatest([this.resourceService.getInvitations(), this.resourceService.getRoles()]).subscribe({
      error: () => {
        this.invites = [];
        this.roles = [];
        this.hasError = true;
        this.loading = false;
      },
      next: ([invites, roles]) => {
        this.invites = invites;
        this.roles = roles;
        this.hasError = false;
        this.loading = false;
      }
    });
  }

  revokeInvite(invite: IInvitation) {
    this.resourceService.deleteInvitation(invite.id).subscribe({
      error: err => {
        this.errorService.showError('Einladung konnte nicht widerrufen werden', err);
      },
      next: () => {
        const index = this.invites.findIndex(i => i.id === invite.id);
        if (index >= 0) {
          this.invites.splice(index, 1);
          this.invitationTable?.renderRows();
        }
      }
    });
  }

  createInvite() {
    this.createInProgress = true;
    this.inviteFormControl.disable();
    const postRequest: IInvitationPostRequest = {
      email: this.inviteFormControl.value
    };
    this.resourceService.createInvitation(postRequest).subscribe({
      error: err => {
        console.log('ERROR', err);
        this.errorService.showError('Einladung konnte nicht erstellt werden', err);
        this.createInProgress = false;
        this.inviteFormControl.enable();
      },
      next: invite => {
        this.invites.unshift(invite);
        this.invitationTable?.renderRows();
        this.createInProgress = false;
        this.inviteFormControl.setValue('');
        this.inviteFormControl.enable();
      }
    });
  }
}
