<p-messages></p-messages>
<ng-container [ngTemplateOutlet]="errorMessage"></ng-container>

<!-- Error Message Template -->
<ng-template #errorMessage>
  <div [class]="' p-message p-message-' + type">
    <div class="flex align-content-center p-message-wrapper">
      <span [classList]="'p-message-icon ' + icon"></span>
      <span class="p-message-summary">{{ summary | translate }}</span>
      <span class="p-message-detail">{{ detail | translate }}</span>
      <button
        *ngIf="showCloseButton"
        (click)="onClose()"
        pButton
        class="p-message-close p-button-text ml-auto"
        [icon]="'pi pi-times'"></button>
    </div>
  </div>
</ng-template>
