import { NgModule } from '@angular/core';
import { LayoutComponent } from './layout.component';
import { CommonModule } from '@angular/common';
import { SharedModule, TouchedDirtyDirective } from 'shared';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { CustomerSelectorComponent } from './customer-selector/customer-selector.component';
import { OrganizationSelectMenuComponent } from './customer-selector/select-menu/select-menu.component';
import { UploadQueueComponent } from './upload-queue/upload-queue.component';
import { AccountMenuComponent } from './account-menu/account-menu.component';
import { MenuContentComponent } from './menu-content/menu-content.component';
import { BadgeModule } from 'primeng/badge';

const COMPONENTS = [
  LayoutComponent,
  CustomerSelectorComponent,
  OrganizationSelectMenuComponent,
  UploadQueueComponent,
  AccountMenuComponent,
  MenuContentComponent
];
@NgModule({
  imports: [CommonModule, SharedModule, RouterModule, ReactiveFormsModule, BadgeModule, TouchedDirtyDirective],
  exports: [...COMPONENTS, SharedModule, RouterModule],
  declarations: [...COMPONENTS],
  providers: []
})
export class LayoutModule {}
