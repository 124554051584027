import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-project-documents',
  templateUrl: './project-documents.component.html',
  styleUrls: ['./project-documents.component.scss']
})
export class ProjectDocumentsComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
