import { ScrollStrategyOptions } from '@angular/cdk/overlay';
import {
  Component,
  ElementRef,
  HostBinding,
  Inject,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { selectEntryById, selectSelectedOrganization } from 'data-access';
import { UploadImage } from 'data-access';
import {
  ImageInputService,
  IImageEntry,
  IMAGE_INPUT_SERVICE_KEY,
  IOrganization,
  IProject,
  IRegularEntry,
  ImageViewerService,
  IMAGE_VIEWER_SERVICE_KEY,
  DIALOG_SERVICE_KEY,
  DialogService
} from 'shared';

import { ImageViewerComponent } from '../image-viewer/image-viewer.component';
import {
  ImageUploadDialogComponent,
  ImageUploadDialogData
} from '../image-upload-dialog/image-upload-dialog.component';
import { selectEntriesById } from 'data-access';
import { selectSelectedProject } from 'data-access';
import { Subscription } from 'rxjs';
import { ImageViewerComponentData } from 'shared';

@Component({
  selector: 'image-preview-list-component',
  templateUrl: './image-preview-list.component.html',
  styleUrls: ['./image-preview-list.component.scss']
})
export class ImagePreviewListComponent implements OnInit, OnChanges {
  project?: IProject;
  images: IImageEntry[] = [];
  @Input() imageIds: string[] = [];
  @Input() date!: Date;
  @Input() entryId?: string;

  @HostBinding('style.display') public displayHost: string = 'block';

  protected entry?: IRegularEntry;

  private imageSelectorSubscription: Subscription = new Subscription();

  validImageTypes: string[] = ['image/png', 'image/jpeg'];

  constructor(
    private store: Store,
    @Inject(DIALOG_SERVICE_KEY) private dialog: DialogService,
    @Inject(IMAGE_INPUT_SERVICE_KEY) private imageInputService: ImageInputService,
    @Inject(IMAGE_VIEWER_SERVICE_KEY) private imageViewerService: ImageViewerService
  ) {}

  ngOnInit(): void {
    if (this.entryId) {
      this.store.select(selectEntryById(this.entryId)).subscribe(entry => (this.entry = entry as IRegularEntry));
    }
    this.store.select(selectSelectedProject).subscribe(project => (this.project = project));
    this.loadImages(this.imageIds);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['imageIds'] && !changes['imageIds'].isFirstChange()) {
      this.loadImages(changes['imageIds'].currentValue);
    }
    if (!this.imageIds.length) {
      this.displayHost = 'none';
    } else {
      this.displayHost = 'block';
    }
  }

  private loadImages(imageIds: string[]): void {
    this.imageSelectorSubscription.unsubscribe(); // Unsubscribe from previous observable
    this.imageSelectorSubscription = this.store.select(selectEntriesById(imageIds)).subscribe(images => {
      this.images = images
        .map(image => image as IImageEntry)
        .sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime());
    });
  }

  public openImageViewer(index: number) {
    this.imageViewerService.openImageViewer(
      this.images.map(image => image.id),
      index,
      this.entry?.id
    );
  }

  public triggerImageInput() {
    this.imageInputService.getImageInputFiles().then(blobs => {
      blobs = blobs.filter(blob => this.validImageTypes.includes(blob.type));
      if (blobs.length > 0) {
        this.openImageUploadPopover(blobs);
      }
    });
  }

  public openImageUploadPopover(files: Blob[]) {
    const data: ImageUploadDialogData = {
      files: files,
      entry: this.entry,
      date: this.date
    };
    this.dialog.openDialog<ImageUploadDialogComponent, ImageUploadDialogData, undefined>(ImageUploadDialogComponent, {
      data: data
    });
  }

  public trackByImageId(index: number, image: IImageEntry) {
    return image.id;
  }

  getImageUrl(imageId: string): string {
    return `/projects/${this.project!.id}/diary/${imageId}/file?preview=true`;
  }
}
