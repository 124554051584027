<div class="flex flex-column">
  <p-progressBar
    *ngIf="!error && loadingFile && progress != undefined"
    [value]="progress"
    unit="%"
    styleClass="fast-progress-bar"></p-progressBar>

  <p-progressBar *ngIf="!error && loadingFile && progress == undefined" [mode]="'indeterminate'"></p-progressBar>
  <app-message
    *ngIf="error && !loadingFile"
    [showCloseButton]="false"
    summary="PDF"
    detail="konnte nicht bereitgestellt werden"></app-message>
</div>
