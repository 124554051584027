import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { SharedModule } from 'shared';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { OAuthModule } from 'angular-oauth2-oidc';
import { CompanyEffects, companiesReducer } from 'data-access';
import { diaryReducer } from 'data-access';
import { EntryEffects, entriesReducer } from 'data-access';
import { ImageEffects, imagesReducer } from 'data-access';
import { MemberEffects, memberReducer } from 'data-access';
import { OrganizationEffects, organizationReducer } from 'data-access';
import { ProjectEffects, projectsReducer } from 'data-access';
import { uploadQueueReducer } from 'data-access';
import { NgcCookieConsentModule } from 'ngx-cookieconsent';
import { environment } from 'shared';
import { HttpClientModule } from '@angular/common/http';
import { exportReducer } from '../../../data-access/src/lib/store/export/export.reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    SharedModule,
    HttpClientModule,
    BrowserAnimationsModule,
    OAuthModule.forRoot(),
    NgcCookieConsentModule.forRoot(environment.cookieConfig),
    EffectsModule.forRoot([
      OrganizationEffects,
      ProjectEffects,
      EntryEffects,
      ImageEffects,
      CompanyEffects,
      MemberEffects
    ]),
    StoreModule.forRoot({
      organizations: organizationReducer,
      projects: projectsReducer as any,
      companies: companiesReducer as any,
      images: imagesReducer as any,
      entries: entriesReducer as any,
      export: exportReducer as any,
      diary: diaryReducer as any,
      uploadQueue: uploadQueueReducer as any,
      member: memberReducer as any,
      organizationMember: organizationReducer
    }),
    environment.storeModuleImports ? environment.storeModuleImports : []
  ],
  exports: [HttpClientModule, BrowserAnimationsModule, OAuthModule, NgcCookieConsentModule, EffectsModule, StoreModule],
  providers: [DatePipe]
})
export class CoreModule {}
