import { CompanyActions, CompanyActionTypes } from './company.actions';
import { companiesAdapter, CompaniesState, initialCompaniesState } from './company.state';

export function companiesReducer(state = initialCompaniesState, action: CompanyActions): CompaniesState {
  switch (action.type) {
    case CompanyActionTypes.LoadProjectCompanies:
      return { ...state };

    case CompanyActionTypes.LoadProjectCompaniesSuccess:
      return companiesAdapter.addMany(action.payload.companies, {
        ...state,
        allCompaniesLoaded: true
      });

    case CompanyActionTypes.LoadProjectCompaniesError:
      return { ...state, loading: false };

    case CompanyActionTypes.AddProjectCompanies:
      return companiesAdapter.addMany(action.payload.companies, state);

    case CompanyActionTypes.RemoveProjectCompanies:
      return companiesAdapter.removeMany(action.payload.companyIds, state);

    case CompanyActionTypes.ResetCompaniesState:
      return companiesAdapter.getInitialState(initialCompaniesState);

    default: {
      return state;
    }
  }
}
