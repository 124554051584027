import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { EMPTY, Observable, of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

export interface ComponentConfig {
  header: string;
  confirmLabel?: string;
  confirmIcon?: string;
  showConfirmIcon?: boolean;
  confirmSuccessMessage?: string;
  confirmErrorMessage?: string;
  cancelLabel?: string;
  cancelIcon?: string;
  showCancelIcon?: boolean;
  confirmButtonClass?: string;
  cancelButtonClass?: string;
  hideConfirmButton?: boolean;
  hideCancelButton?: boolean;
  loadingErrorMessage?: string;
}

@Component({
  template: ''
})
export abstract class DialogComponent<D, R> implements OnInit {
  @Input() data: D | undefined;
  @Input() mobile!: boolean;
  @Output() dismissed: EventEmitter<R | undefined> = new EventEmitter<R | undefined>();
  @Output() onConfirmInProgress: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onConfirmSuccess: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onConfirmError: EventEmitter<HttpErrorResponse | undefined> = new EventEmitter<
    HttpErrorResponse | undefined
  >();
  @Output() onLoading: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onLoadingError: EventEmitter<HttpErrorResponse | undefined> = new EventEmitter<
    HttpErrorResponse | undefined
  >();

  public abstract config: ComponentConfig;

  private _confirmProgress: boolean = false;
  private _confirmSuccess: boolean = false;
  private _confirmError: HttpErrorResponse | undefined = undefined;
  private _loadingError: HttpErrorResponse | undefined = undefined;
  private _loading: boolean = true;
  private loaded: boolean = false;

  public set confirmProgress(value: boolean) {
    this.onConfirmInProgress.emit(value);
    this._confirmProgress = value;
  }

  public get confirmProgress(): boolean {
    return this._confirmProgress;
  }

  public set confirmSuccess(value: boolean) {
    this.onConfirmSuccess.emit(value);
    this._confirmSuccess = value;
  }

  public get confirmSuccess(): boolean {
    return this._confirmSuccess;
  }

  public set confirmError(value: HttpErrorResponse | undefined) {
    this.onConfirmError.emit(value);
    this._confirmError = value;
  }

  public get confirmError(): HttpErrorResponse | undefined {
    return this._confirmError;
  }

  public set loadingError(value: HttpErrorResponse | undefined) {
    this.onLoadingError.emit(value);
    this._loadingError = value;
  }

  public get loadingError(): HttpErrorResponse | undefined {
    return this._loadingError;
  }

  protected set loading(value: boolean) {
    this.onLoading.emit(value);
    this._loading = value;
  }

  protected get loading(): boolean {
    return this._loading;
  }

  protected dismiss(data?: R) {
    this.dismissed.emit(data);
  }

  public close() {
    this.dismiss();
  }

  public confirm() {
    if (!this.canConfirm()) {
      return;
    }
    this.confirmProgress = true;
    this.confirmSuccess = false;
    this.confirmError = undefined;
    this.getSaveObs().subscribe({
      next: value => {
        this.confirmProgress = false;
        this.confirmSuccess = true;
        this.confirmError = undefined;
        this.confirmSuccessHandle(value);
      },
      error: err => {
        this.confirmProgress = false;
        this.confirmSuccess = false;
        this.confirmError = err;
        this.confirmErrorHandle(err);
      }
    });
  }

  protected canConfirm(): boolean {
    return true;
  }

  protected confirmSuccessHandle(value: R) {
    setTimeout(() => this.dismiss(value), 1500);
  }

  protected confirmErrorHandle(err: HttpErrorResponse) {}

  abstract getSaveObs(): Observable<R>;

  protected getLoadingObs(): Observable<any> {
    return of(undefined);
  }

  /**
   * Base function to provide a handler triggered when the loading has finished.
   * Should be overwritten by the extending components
   * @param data produced by the getLoadingObs() function
   * @protected
   */
  protected loadedHandle(data: any) {}

  protected load() {
    this.loading = true;
    this.loadingError = undefined;
    this.getLoadingObs().subscribe({
      next: value => {
        this.loadingError = undefined;
        this.loading = false;
        this.loadedHandle(value);
      },
      error: err => {
        this.loadingError = err;
        this.loading = false;
      }
    });
  }

  ngOnInit(): void {
    this.load();
  }
}
