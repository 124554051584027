<div class="page">
  <div class="page-content flex flex-column">
    <h1>{{ 'add' | translate }}</h1>
    <form #companySearchForm="ngForm" name="companySearchForm" class="mb-2">
      <div class="flex">
        <div class="field">
          <div class="p-float-label">
            <p-autoComplete
              id="companyNameInput"
              #companyNameInput
              class="p-inputwrapper-filled"
              [style]="{ width: '100%' }"
              [inputStyle]="{ width: '100%' }"
              [suggestions]="(filteredCompanies | async) || []"
              (completeMethod)="searchCompanies($event.query)"
              [showEmptyMessage]="!(filteredCompanies | async)?.length"
              [emptyMessage]="'Keine Auftrag-/Subunternehmer gefunden'"
              (onSelect)="selectCompany($event)"
              [disabled]="!loaded"
              field="name">
              <ng-template let-company pTemplate="item">
                <div class="flex flex-column autocomplete-icon">
                  <p class="mb-0">{{ company.name }}</p>
                  <span class="text-500 text-sm">{{ company.description }}</span>
                </div>
              </ng-template>
            </p-autoComplete>
            <label for="companyNameInput">{{ 'search' | translate }}</label>
          </div>
        </div>
      </div>

      <div class="flex flex-column ml-2 mt-3 mb-2">
        <label *ngIf="selectedCompanies.length > 0">Ausgewählte Auftrag-/ Subunternehmer</label>
        <mat-chip-listbox #chipList aria-label="selected companies">
          <mat-chip-option
            *ngFor="let company of selectedCompanies"
            (removed)="unselectCompany(company)"
            [selected]="true">
            {{ company.name }}
            <button matChipRemove>
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-chip-option>
        </mat-chip-listbox>
      </div>

      <div *ngIf="selectedCompanies.length > 0" class="flex justify-content-end">
        <p-button
          [label]="'Hinzufügen (' + selectedCompanies.length + ')'"
          styleClass="p-button-raised p-button-sm"
          [loading]="saveInProgress"
          (click)="addCompaniesToProject(selectedCompanies)"></p-button>
      </div>
    </form>

    <h1 class="mt-2">{{ 'contractor-subcontractor' | translate }}</h1>
    <div style="min-height: 0">
      <p-table
        [value]="companies || []"
        scrollHeight="flex"
        [tableStyle]="{ 'min-width': '50rem' }"
        [responsive]="true"
        [scrollable]="true">
        <ng-template pTemplate="header">
          <tr>
            <th>{{ 'name' | translate }}</th>
            <th>{{ 'description' | translate }}</th>
            <th>{{ 'email' | translate }}</th>
            <th>{{ 'tel-nr' | translate }}</th>
            <th></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-company>
          <tr>
            <td [id]="'name'">
              {{ company.name }}
            </td>
            <td>{{ company.description }}</td>
            <td>{{ company.email }}</td>
            <td>{{ company.phone }}</td>
            <td [id]="'iconCol'">
              <button pButton class="p-button-sm p-button-text p-button-icon-only" disabled>
                <span class="mat-outlined mat-close"></span>
              </button>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>
