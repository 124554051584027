<div class="page-content">
  <div class="flex flex-row justify-content-between align-items-start">
    <div>
      <h1>{{ 'roles' | translate }}</h1>
    </div>
    <button pButton class="p-button-sm" (click)="editRole()" [label]="'add' | translate"></button>
  </div>

  <div class="table-responsive" style="margin-bottom: 1rem">
    <table mat-table [dataSource]="roles" #rolesTable>
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>{{ 'name' | translate }}</th>
        <td mat-cell *matCellDef="let role">{{ role.name }}</td>
      </ng-container>

      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef>{{ 'description' | translate }}</th>
        <td mat-cell *matCellDef="let role">{{ role.description }}</td>
      </ng-container>

      <ng-container matColumnDef="control">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let role">
          <button
            pButton
            class="p-button-text"
            [popoverTrigger]="controlMenuTemplate"
            [popoverContext]="{ role: role }"
            [disabled]="role.defaultRole"
            [positionX]="['end', 'end']"
            [positionY]="['bottom', 'top']"
            icon="mat-outlined mat-more-vert"></button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="['name', 'description', 'control']"></tr>
      <tr mat-row *matRowDef="let row; columns: ['name', 'description', 'control']"></tr>
    </table>
  </div>

  <error-display-component *ngIf="!loading && hasError" (onRetry)="loadPageData()"></error-display-component>
  <mat-spinner style="margin-left: 1.4rem" *ngIf="loading" diameter="30"></mat-spinner>
  <h3 style="margin-left: 1.4rem" *ngIf="roles.length == 0 && !loading && !hasError">
    {{ 'no-roles-found' | translate }}
  </h3>

  <ng-template #controlMenuTemplate let-role="role">
    <popover-container>
      <div class="control-menu">
        <button
          pButton
          autoClosePopover
          class="p-button-sm p-button-text"
          (click)="editRole(role)"
          [disabled]="role.defaultRole">
          <span class="mat-outlined">key</span>
          <p>Bearbeiten</p>
        </button>
        <hr />
        <button
          pButton
          class="p-button-sm p-button-danger p-button-text"
          (onConfirmed)="deleteRole(role)"
          confirmLabel="Löschen"
          [confirmDanger]="true"
          [confirmChallenge]="role.name"
          confirmMessage="Dieser Vorgange kann nicht rückgänig gemacht werden. Durch Löschen der Rolle wird diese auch allen zugewiesenen Nutzern entzogen."
          [disabled]="role.defaultRole">
          <span class="mat-outlined">delete</span>
          <p>Löschen</p>
        </button>
      </div>
    </popover-container>
  </ng-template>
</div>
