import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedFeatherModule } from './modules/SharedFeather.module';
import { SharedMaterialModule } from './modules/SharedMaterial.module';
import { PopoverModule } from './modules/popover/popover.module';
import { DateSpanValidator, FutureDateValidator } from './directives/project.directive';
import { AtLeastOneValidator } from './directives/at-least-one-role.directive';
import { DateInput } from './directives/date-input.directive';
import { LoseFocusDirective } from './directives/lose-focus.directive';
import { AutoGrowDirective } from './directives/textarea-autogrow.directive';
import { PositiveNumberValidator } from './directives/positive-number.directive';
import { UserValidator } from './directives/valid-user.directive';
import { WeatherValidator } from './directives/weather-validator.directive';
import { CastPdfProgressPipe } from './pipes/castPdfProgress.pipe';
import { CastPdfPipe } from './pipes/castPdf.pipe';
import { hexToRGBAPipe, hexToRGBPipe, hexToRGBTintPipe } from './pipes/color.pipe';
import { CastBlobToObjectUrlPipe } from './pipes/cast-blob-to-object-url.pipe';
import { RouterModule } from '@angular/router';
import { PrimeSharedModule } from './modules/prime-shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { WordCountValidator } from './directives/word-count-validators.directive';

const MODULES = [
  SharedFeatherModule,
  SharedMaterialModule,
  PopoverModule,
  RouterModule,
  TranslateModule,
  PrimeSharedModule
];
const DIRECTIVES = [
  DateSpanValidator,
  FutureDateValidator,
  AtLeastOneValidator,
  DateInput,
  LoseFocusDirective,
  AutoGrowDirective,
  PositiveNumberValidator,
  WeatherValidator,
  UserValidator,
  WordCountValidator
];
const PIPES = [
  // ProjectImagePipe,
  CastPdfPipe,
  CastPdfProgressPipe,
  hexToRGBPipe,
  hexToRGBAPipe,
  hexToRGBTintPipe,
  CastBlobToObjectUrlPipe
];

@NgModule({
  declarations: [...DIRECTIVES, ...PIPES],
  imports: [CommonModule, ...MODULES],
  exports: [...MODULES, ...DIRECTIVES, ...PIPES]
})
export class SharedModule {}
