<div *ngIf="images" class="image-container">
  <img [src]="imageData | async | handleUndefinedImage" />

  <div class="top-controls">
    <mat-spinner color="primary" [diameter]="30" *ngIf="!(imageLG | async)"></mat-spinner>
    <button
      pButton
      class="p-button-sm p-button-text"
      icon="mat-outlined mat-download"
      *ngIf="(imageLG | async) && !editable; else imageUserControlsButtonTemplate"
      (click)="downloadImage()"></button>
    <button pButton class="p-button-sm ml-2" icon="mat-outlined mat-close" (click)="dialogRef.close()"></button>
  </div>

  <div class="bottom-controls">
    <image-viewer-description-component
      *ngIf="descriptionVisible && (getSelectedImage() | async) as selectedImage"
      [imageId]="selectedImage.id">
    </image-viewer-description-component>
    <div style="position: relative">
      <button pButton class="p-button-sm" icon="mat-outlined mat-chevron-left" (click)="prewImage()"></button>
      <button
        pButton
        class="p-button-sm ml-1"
        [class.p-button-text]="!descriptionVisible"
        icon="mat-outlined mat-info"
        (click)="descriptionVisible = !descriptionVisible"></button>
      <button pButton class="p-button-sm ml-1" icon="mat-outlined mat-chevron-right" (click)="nextImage()"></button>
    </div>

    <div class="imageFeedback">
      <p>{{ indexOfImage + 1 }}/{{ images.length }}</p>
    </div>
  </div>
</div>

<ng-template #imageUserControlsButtonTemplate>
  <button
    pButton
    class="p-button-sm p-button-text"
    icon="mat-outlined mat-menu-open"
    [popoverTrigger]="imageUserControlsPopoverTemplate"
    [positionY]="['bottom', 'top']"
    [positionX]="['end', 'end']"></button>
</ng-template>

<ng-template #imageUserControlsPopoverTemplate>
  <popover-container>
    <div class="flex flex-column">
      <button
        pButton
        class="p-button-sm p-button-text mb-1 text-left"
        icon="mat-outlined mat-download"
        label="Herunterladen"
        (click)="downloadImage()"></button>
      <mat-divider></mat-divider>
      <button
        pButton
        class="p-button-sm p-button-text p-button-danger mt-1 text-left"
        icon="mat-outlined mat-delete"
        label="Löschen"
        [confirmToggle]="true"
        (onConfirmed)="deleteImage(indexOfImage)"
        confirmLabel="Löschen"
        confirmColor="warn"
        confirmMessage="Dieser Vorgang kann nicht rückgängig gemacht werden"
        confirmTitle="Foto löschen"
        [loading]="deleteInProgress"></button>
    </div>
  </popover-container>
</ng-template>
