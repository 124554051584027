import { NgModule } from '@angular/core';
import { HandleUndefinedImage, SecureResourcePipe } from '../pipes/secure-images.pipe';

@NgModule({
  imports: [],
  declarations: [HandleUndefinedImage, SecureResourcePipe],

  exports: [HandleUndefinedImage, SecureResourcePipe]
})
export class DataAccessModule {}
