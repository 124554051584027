export * from './modules/project-companies/project-companies.module';
export * from './modules/project-dashboard/project-dashboard.module';
export * from './modules/project-details/project-details.module';
export * from './modules/project-plans/project-plans.module';
export * from './modules/project-members/project-members.module';
export * from './modules/project-landing/project-landing.module';
export * from './modules/project-documents/project-documents.module';
export * from './modules/project-diary/project-diary.module';

export * from '../projects/modules/companies/company-input/company-input.component';

export * from './services/projects.service';
