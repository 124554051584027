import { DiaryState, initialDiaryState } from './diary.state';
import { DiaryActions, DiaryActionTypes } from './diary.actions';

export function diaryReducer(state = initialDiaryState, action: DiaryActions): DiaryState {
  switch (action.type) {
    // case DiaryActionTypes.DiaryUpdateTopLoaderActive:
    //   return { ...state, topLoaderActive: action.payload.active };
    // case DiaryActionTypes.DiaryUpdateBottomLoaderActive:
    //   return { ...state, bottomLoaderActive: action.payload.active };
    //
    // case DiaryActionTypes.DiaryUpdateTopLoading:
    //   return { ...state, topLoading: action.payload.loading };
    // case DiaryActionTypes.DiaryUpdateBottomLoading:
    //   return { ...state, bottomLoading: action.payload.loading };

    case DiaryActionTypes.DiaryUpdateTopDate:
      return { ...state, topDate: action.payload.date };
    case DiaryActionTypes.DiaryUpdateBottomDate:
      return { ...state, bottomDate: action.payload.date };
    default: {
      return state;
    }
  }
}
