import { Component, Inject, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { DialogComponent } from '../../dialog/dialog.component';
import { DialogContainerComponent } from '../dialog-container.component';
import { DialogContainerData } from '../dialog-container-data';
import { DialogContainerRef } from '../../dialog.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-dialog-container',
  templateUrl: './../dialog-container.component.html',
  styleUrls: ['./../dialog-container.component.scss']
})
export class MaterialDialogContainerComponent<C extends DialogComponent<D, R>, D, R> extends DialogContainerComponent<
  C,
  D,
  R
> {
  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public matDialogData: DialogContainerData<C, D>,
    @Optional() @Inject(MAT_BOTTOM_SHEET_DATA) public matSheetData: DialogContainerData<C, D>,
    @Optional() public matDialogRef: MatDialogRef<MaterialDialogContainerComponent<C, D, R>, R>,
    @Optional() public matSheetRef: MatBottomSheetRef<MaterialDialogContainerComponent<C, D, R>, R>
  ) {
    super();
  }

  override dismiss(data: R) {
    if (this.matDialogRef) {
      this.matDialogRef.close(data);
    }
    if (this.matSheetRef) {
      this.matSheetRef.dismiss(data);
    }
  }

  override init() {
    console.log(this.matDialogData);
    console.log(this.matSheetData);

    if (this.matDialogData?.componentType) {
      this.componentType = this.matDialogData.componentType;
      this.data = this.matDialogData.data;
    }
    if (this.matSheetData?.componentType) {
      this.componentType = this.matSheetData.componentType;
      this.data = this.matSheetData.data;
    }
  }
}

export class MatSheetDialogContainer<R> extends DialogContainerRef<R> {
  constructor(private sheetRef: MatBottomSheetRef<any, R>) {
    super();
  }

  override close() {
    this.sheetRef.dismiss();
  }

  override afterDismissed(): Observable<R | undefined> {
    return this.sheetRef.afterDismissed();
  }
}

export class MatDialogDialogContainer<R> extends DialogContainerRef<R> {
  constructor(private dialogRef: MatDialogRef<any, R>) {
    super();
  }

  override close() {
    this.dialogRef.close();
  }

  override afterDismissed(): Observable<R | undefined> {
    return this.dialogRef.afterClosed();
  }
}
