import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { catchError, filter, map, mergeMap, of, withLatestFrom } from 'rxjs';
import { selectSelectedOrganization } from 'data-access';
import { selectAllOrganizationMembersLoaded } from './organization-member.selectors';
import { RESOURCE_KEY } from 'shared';
// import { ResourceService } from 'data-access';

@Injectable()
export class ProjectMemberEffects {
  constructor(
    private actions$: Actions,
    private store: Store // @Inject(RESOURCE_KEY) private readonly _resourceService: ResourceService
  ) {}

  // loadAllOrganizationMembers$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType<LoadAllOrganizationMembers>(OrganizationMemberActionTypes.LoadAllOrganizationMembers),
  //     withLatestFrom(this.store.pipe(select(selectAllOrganizationMembersLoaded))),
  //     filter(([action, allMembersLoaded]) => !allMembersLoaded),
  //     mergeMap(() => this.store.pipe(select(selectSelectedOrganization))),
  //     filter(organization => organization != undefined),
  //     mergeMap(organization =>
  //       this._resourceService.getOrganizationMembersObs(organization!.id).pipe(
  //         map(members => new LoadAllOrganizationMembersSuccess({ members: members })),
  //         catchError(error => of(new LoadAllOrganizationMembersError(error)))
  //       )
  //     )
  //   )
  // );
}
