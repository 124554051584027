import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors } from '@angular/forms';

@Directive({
  selector: '[validUser]',
  providers: [{ provide: NG_VALIDATORS, useExisting: UserValidator, multi: true }]
})
export class UserValidator {
  constructor() {}

  validate(control: AbstractControl): ValidationErrors | null {
    if (control.value?.id == undefined) {
      return { invalidUserId: null };
    } else {
      return null;
    }
  }
}
