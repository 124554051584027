import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { IImage } from 'shared';

export const imagesAdapter: EntityAdapter<IImage> = createEntityAdapter<IImage>({});

export const initialImagesState: ImagesState = imagesAdapter.getInitialState({
  allImagesLoaded: false
});

export interface ImagesState extends EntityState<IImage> {
  allImagesLoaded: boolean;
}
