import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, filter, map, Observable } from 'rxjs';

export interface WindowSize {
  height: number;
  width: number;
}

@Injectable({
  providedIn: 'root'
})
export abstract class LayoutService implements OnDestroy {
  private windowSize: BehaviorSubject<WindowSize | undefined> = new BehaviorSubject<WindowSize | undefined>(undefined);

  public getWindowSize(): Observable<WindowSize> {
    return this.windowSize.asObservable().pipe(filter((ws): ws is WindowSize => ws !== undefined));
  }

  public isMobileLayout(): Observable<boolean> {
    return this.isWidthSmaller(1024);
  }

  public isWidthSmaller(width: number): Observable<boolean> {
    return this.getWindowSize().pipe(map(ws => ws.width < width));
  }

  private onResize(event?: Event): void {
    // Check if the window width is less than 1024 pixels
    this.windowSize.next({
      height: window.innerHeight,
      width: window.innerWidth
    });
  }

  constructor() {
    window.addEventListener('resize', this.onResize.bind(this));
    // trigger read of initial values
    this.onResize();
  }

  ngOnDestroy(): void {
    window.removeEventListener('resize', this.onResize.bind(this));
  }
}
