import { SharedPageModule } from 'libs/shared/src/lib/modules/shared-page.module';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';

import { CoreProjectsRoutingModule } from './core-projects-routing.module';
import { ProjectsPage } from './components/projects/projects.page';
import { ProjectComponent } from '../project/components/project/project.component';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FeatureDiaryModule } from '../diary/feature-diary.module';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  imports: [CommonModule, FormsModule, CoreProjectsRoutingModule, SharedPageModule, FeatureDiaryModule, DropdownModule],
  declarations: [ProjectsPage, ProjectComponent]
})
export class CoreProjectsModule {}
