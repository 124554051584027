<div class="flex flex-column max-h-full h-full position-relative mx-4 my-3">
  <div class="flex justify-content-between align-items-center">
    <h1 class="m-0">{{ errorData.title }}</h1>
    <button pButton class="p-button-text" icon="mat-outlined mat-close" (click)="close()"></button>
  </div>

  <div class="mt-3" *ngIf="errorData.error">
    <p class="mb-1"><b>Status Code:</b> {{ errorData.error.status }}</p>
    <p><b>Message:</b> {{ errorData.error.message }}</p>

    <div class="my-4">
      <p-accordion *ngIf="loaded">
        <p-accordionTab header="Details">
          <p style="white-space: pre-wrap">{{ errorData.error.error | json }}</p>
          <div class="flex justify-content-end">
            <button mat-button color="primary" (click)="copyToClipborad()">Kopieren</button>
          </div>
        </p-accordionTab>
      </p-accordion>
    </div>
  </div>
</div>
