import { entriesAdapter, EntriesState } from './entry.state';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export const entriesState = createFeatureSelector<EntriesState>('entries');

export const { selectAll, selectEntities, selectIds, selectTotal } = entriesAdapter.getSelectors();

export const selectEntryById = (entryId: string) => createSelector(entriesState, state => state.entities[entryId]);

export const selectEntriesById = (entryIds: string[]) =>
  createSelector(entriesState, state => entryIds.map(id => state.entities[id]).filter(entry => !!entry));

export const selectAllEntries = createSelector(entriesState, selectAll);

export const selectAllParentEntries = createSelector(selectAllEntries, entries =>
  entries.filter(entry => !entry.entryId)
);

export const selectEntriesByParent = (parentId: string) =>
  createSelector(selectAllEntries, entries => entries.filter(entry => entry.entryId === parentId));
