import { KeyValue } from '@angular/common';
import { Observable } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { IProject, IUploadTask, UploadStatusEnum, UploadTypeEnum } from 'shared';
import {
  UploadCancel,
  UploadRetry,
  selectFailedUploads,
  selectNotFailedUploads,
  selectUploadInProgress
} from 'data-access';
import { selectProjectById } from 'data-access';

@Component({
  selector: 'upload-queue-component',
  templateUrl: './upload-queue.component.html',
  styleUrls: ['./upload-queue.component.scss']
})
export class UploadQueueComponent {
  uploads: IUploadTask[] = [];
  failedUploads: IUploadTask[] = [];
  uploadInProgress = false;

  constructor(private store: Store) {
    this.store.pipe(select(selectNotFailedUploads)).subscribe(uploads => (this.uploads = uploads));
    this.store.pipe(select(selectUploadInProgress)).subscribe(inProgress => (this.uploadInProgress = inProgress));
    this.store.pipe(select(selectFailedUploads)).subscribe(uploads => (this.failedUploads = uploads));
  }

  get uploadsMap(): Map<string, IUploadTask[]> {
    return this.uploads.reduce((map, upload) => {
      if (upload.status == UploadStatusEnum.Started || UploadStatusEnum.Waiting) {
        map.set(upload.projectId, [...(map.get(upload.projectId) || []), upload]);
      }
      return map;
    }, new Map<string, IUploadTask[]>());
  }

  getUploadTypeGroups(uploads: IUploadTask[]): Map<UploadTypeEnum, IUploadTask[]> {
    return uploads.reduce((map, upload) => {
      map.set(upload.type, [...(map.get(upload.type) || []), upload]);
      return map;
    }, new Map<UploadTypeEnum, IUploadTask[]>());
  }

  trackByMapKey(index: number, item: KeyValue<string, IUploadTask[]>) {
    return item.key;
  }

  getProject(projectId: string): Observable<IProject | undefined> {
    return this.store.pipe(select(selectProjectById(projectId)));
  }

  uploadTaskIdentity(upload: IUploadTask): IUploadTask {
    return upload;
  }

  retryUpload(upload: IUploadTask) {
    this.store.dispatch(new UploadRetry({ task: upload }));
  }

  cancelUpload(upload: IUploadTask) {
    this.store.dispatch(new UploadCancel({ id: upload.id }));
  }
}
