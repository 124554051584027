import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { IMember, IUser } from 'shared';

export const organizationMemberAdapter: EntityAdapter<IUser> = createEntityAdapter<IUser>({});

export const initialOrganizationMembersState: OrganizationMemberState = organizationMemberAdapter.getInitialState({
  allMembersLoaded: false
});

export interface OrganizationMemberState extends EntityState<IUser> {
  allMembersLoaded: boolean;
}
