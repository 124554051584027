import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProjectCompaniesRoutingModule } from './project-companies-routing.module';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { TableModule } from 'primeng/table';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ProjectCompaniesComponent } from './project-companies.component';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { SharedPageModule, TouchedDirtyDirective } from 'shared';

@NgModule({
  imports: [
    SharedPageModule,
    CommonModule,
    FormsModule,
    ProjectCompaniesRoutingModule,
    ReactiveFormsModule,
    ButtonModule,
    InputTextModule,
    TableModule,
    AutoCompleteModule,
    InputTextareaModule,
    TouchedDirtyDirective
  ],
  declarations: [ProjectCompaniesComponent],
  exports: []
})
export class ProjectCompaniesModule {}
