import { createAction, props } from '@ngrx/store';
import { IOrganization } from 'shared';

export const restoreOrganizationAction = createAction(
  '[Organization] Restore Organization',
  props<{ organizationId: string }>()
);
export const restoreOrganizationSuccessAction = createAction(
  '[Organization] Restore Organization Success',
  props<{ organization: IOrganization }>()
);
export const restoreOrganizationErrorAction = createAction('[Organization] Restore Organization Error');

export const selectOrganizationAction = createAction(
  '[Organization] Select Organization',
  props<{ organization: IOrganization }>()
);
export const selectOrganizationSuccessAction = createAction(
  '[Organization] Select Organization Success',
  props<{ organizationId: string }>()
);
export const selectOrganizationErrorAction = createAction('[Organization] Select Organization Error');

export const loadAllOrganizationsAction = createAction('[Organization] Load All Organizations');
export const reloadAllOrganizationsAction = createAction('[Organization] Reload All Organizations');
export const loadAllSuccessAction = createAction(
  '[Organization] Load All Success',
  props<{ organizations: IOrganization[] }>()
);
export const loadAllErrorAction = createAction('[Organization] Load All Error');

export const resetOrganizationState = createAction('[Organization] Reset State');
