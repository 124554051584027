<div class="menu" [class.menu--active]="isMenuOpen">
  <div class="menu-header">
    <h1 class="noselect">Bahntagebuch</h1>
    <button pButton class="menu-close-button" (click)="isMenuOpen = false" icon="mat-outlined mat-close"></button>
  </div>

  <app-menu-content [activeRoute]="activeRoute" [activeSubRoute]="activeSubRoute"></app-menu-content>
</div>

<div class="menu-backdrop" *ngIf="isMenuOpen" (click)="isMenuOpen = false"></div>

<div class="menu-page">
  <div class="menu-page-header" [class.has-subtitle]="activeSubRoute">
    <button
      pButton
      class="p-button-text menu-open-button"
      (click)="isMenuOpen = true"
      icon="mat-outlined mat-menu"></button>
    <div class="menu-page-header-title">
      <h1 class="noselect" *ngIf="activeRoute?.title">{{ activeRoute!.title | translate }}</h1>
      <p *ngIf="activeSubRoute">
        <span class="mat-outlined rotate-180">turn_left</span>
        {{ activeSubRoute.title | translate }}
      </p>
    </div>

    <button
      pButton
      class="profile-button p-button-text"
      [popoverTrigger]="profilePopoverContent"
      [positionX]="['end', 'end']"
      [positionY]="['bottom', 'top']"
      id="profileButton">
      <div class="profile-icon">
        <img *ngIf="user" [alt]="user.name ? user.name[0] : '?'" [src]="user.picture" />
      </div>
      <span class="mat-outlined">keyboard_arrow_down</span>
    </button>
  </div>
  <div style="max-height: 100%; height: 100%; min-height: 0">
    <router-outlet></router-outlet>
  </div>
</div>

<ng-template #uploadQueuePopoverContent>
  <popover-container>
    <upload-queue-component></upload-queue-component>
  </popover-container>
</ng-template>

<ng-template #profilePopoverContent>
  <popover-container>
    <account-menu-component></account-menu-component>
  </popover-container>
</ng-template>
