import { IEntryInterface, IEntryRequestType, IProject, RESOURCE_KEY } from 'shared';
import { Directive, Inject, Input } from '@angular/core';
import { IAbstractDiaryEntryFormGroup, IDiaryEntryFormGroupTypes, TForm } from './abstract-entry-form-groups.interface';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectSelectedProject } from 'data-access';
import { ResourceService } from 'data-access';

@Directive()
export abstract class AbstractDiaryEntryForm<
  TEntry extends IEntryInterface,
  TRequest extends IEntryRequestType,
  TControl extends IDiaryEntryFormGroupTypes
> {
  @Input() form!: TForm<IAbstractDiaryEntryFormGroup<IDiaryEntryFormGroupTypes>>;
  @Input() mobile!: boolean;

  protected project?: IProject;

  protected constructor(@Inject(RESOURCE_KEY) protected resourceService: ResourceService, protected store: Store) {
    this.store.select(selectSelectedProject).subscribe(project => (this.project = project));
  }

  saveEntry(): Observable<TEntry> {
    if (this.form.controls.id.value) {
      return this.resourceService.patchAbstractEntry(
        this.form.controls.projectId.value,
        this.form.controls.id.value,
        this.getEntryRequestObj()
      );
    }
    return this.resourceService.postAbstractEntry(this.form.controls.projectId.value, this.getEntryRequestObj());
  }

  get entryForm(): TForm<TControl> {
    return this.form.get('entry') as TForm<TControl>;
  }

  get typedForm(): TForm<IAbstractDiaryEntryFormGroup<TControl>> {
    return this.form as TForm<IAbstractDiaryEntryFormGroup<TControl>>;
  }

  abstract getEntryRequestObj(): TRequest;
}
