import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { RESOURCE_KEY } from 'shared';
import { ResourceService } from 'data-access';

@Injectable({
  providedIn: 'root'
})
export class UserResolverService implements Resolve<any> {
  constructor(@Inject(RESOURCE_KEY) private resourceService: ResourceService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const userId = route.paramMap.get('userId');
    if (userId) {
      return this.resourceService.getUser(userId);
    }
    return undefined;
  }
}
