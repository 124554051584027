<form *ngIf="formGroup" [formGroup]="formGroup">
  <!-- Project Name -->
  <div class="field">
    <div class="p-float-label">
      <input
        pInputText
        class="p-filled w-full"
        touchedDirty
        formControlName="name"
        id="ProjectNameInput"
        type="text"
        required />
      <label for="ProjectNameInput">{{ 'project-name' | translate }}</label>
    </div>
  </div>

  <div class="input-row">
    <!-- Project Start Date -->
    <div class="field">
      <div class="p-float-label">
        <p-calendar
          appendTo="body"
          id="startDate"
          formControlName="startDate"
          dateFormat="dd.mm.yy"
          [showIcon]="true"
          [style]="{ width: '100%' }"
          aria-describedby="shiftStartHelp"
          class="p-inputwrapper-filled"
          touchedDirty>
        </p-calendar>
        <label for="startDate">Projektstart</label>
      </div>
    </div>
    <!-- Project End Date -->
    <div class="field">
      <div class="p-float-label">
        <p-calendar
          appendTo="body"
          dateFormat="dd.mm.yy"
          id="endDate"
          formControlName="endDate"
          [showIcon]="true"
          [style]="{ width: '100%' }"
          aria-describedby="shiftStartHelp"
          class="p-inputwrapper-filled"
          touchedDirty>
        </p-calendar>
        <label for="endDate">Projektende</label>
      </div>
    </div>
  </div>
  <div class="input-row">
    <!-- Construction Task -->
    <div class="field flex-b-66">
      <div class="p-float-label">
        <input
          pInputText
          formControlName="constructionTask"
          class="p-filled w-full"
          touchedDirty
          id="constructionTask"
          required />
        <label for="constructionTask">Bauauftrag</label>
      </div>
    </div>
    <!-- Number -->
    <div class="field flex-b-33">
      <div class="p-float-label">
        <input pInputText formControlName="number" class="p-filled w-full" touchedDirty id="number" required />
        <label for="number">Projektnummer</label>
      </div>
    </div>
  </div>

  <div class="input-row">
    <!-- Commissioning Authority -->
    <div class="field">
      <div class="p-float-label">
        <input
          pInputText
          formControlName="commissioningAuthority"
          class="p-filled w-full"
          touchedDirty
          id="commissioningAuthority"
          required />
        <label for="commissioningAuthority">Auftraggeber</label>
      </div>
    </div>

    <!-- Supervisory Authority -->
    <div class="field">
      <div class="p-float-label">
        <input
          pInputText
          formControlName="supervisoryAuthority"
          class="p-filled w-full"
          touchedDirty
          id="supervisoryAuthority"
          required />
        <label for="supervisoryAuthority">Bauüberwachende Stelle</label>
      </div>
    </div>
  </div>

  <!-- Description -->
  <div class="field">
    <div class="p-float-label">
      <textarea
        pInputTextarea
        formControlName="description"
        [autoResize]="true"
        id="description"
        class="p-filled w-full"
        aria-describedby="descriptionHelp"
        rows="3"></textarea>
      <label for="description">Projektbezeichnung</label>
    </div>
  </div>
</form>
