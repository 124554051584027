import { Injectable } from '@angular/core';

export interface ImageViewerComponentData {
  imageIds: string[];
  initialIndex: number;
  entryId?: string;
}

@Injectable({
  providedIn: 'root'
})
export abstract class ImageViewerService {
  public abstract openImageViewer(imageIds: string[], selectedIndex: number, entryId?: string): void;
}
