<div class="container">
  <div *ngIf="uploads.length == 0 && failedUploads.length == 0" class="state-container">
    <h3>
      {{ 'status' | translate }}: <b>{{ 'up-to-date' | translate }}</b>
    </h3>
    <p>{{ 'no-pending-uploads' | translate }}</p>
  </div>
  <div *ngIf="uploads.length == 0 && failedUploads.length != 0" class="state-container">
    <h3>
      {{ 'status' | translate }}: <b>{{ 'upload-paused' | translate }}</b>
    </h3>
  </div>
  <div *ngIf="uploads.length != 0" class="state-container">
    <h3>
      {{ 'status' | translate }}: <b>{{ 'synchronising' | translate }}</b>
    </h3>
  </div>

  <div *ngFor="let projectUpload of uploadsMap | keyvalue; trackBy: trackByMapKey">
    <h2>{{ (getProject(projectUpload.key) | async)?.name }}</h2>
    <ng-container
      [ngSwitch]="uploadTypeGroup.key"
      *ngFor="let uploadTypeGroup of getUploadTypeGroups(projectUpload.value) | keyvalue; trackBy: trackByMapKey">
      <div *ngSwitchCase="'entry'" class="upload-group">
        <h3>{{ 'diary' | translate }}</h3>
        <p *ngIf="uploadTypeGroup.value[0].status == 'started'">
          {{ 'uploading' | translate }}
          <span *ngIf="uploadTypeGroup.value.length == 2">{{ 'entry-waiting' | translate }} </span>
          <span *ngIf="uploadTypeGroup.value.length > 2">
            - {{ uploadTypeGroup.value.length - 1 }} {{ 'entrys-waiting' | translate }}</span
          >
        </p>
        <p *ngIf="uploadTypeGroup.value[0].status != 'started'">
          <span *ngIf="uploadTypeGroup.value.length == 1"> {{ 'entry-waiting' | translate }}</span>
          <span *ngIf="uploadTypeGroup.value.length > 1">
            - {{ uploadTypeGroup.value.length - 1 }}{{ 'entrys-waiting' | translate }}</span
          >
        </p>
        <mat-spinner [diameter]="24" *ngIf="uploadTypeGroup.value[0].status == 'started'"></mat-spinner>
      </div>
      <div *ngSwitchCase="'image'" class="upload-group">
        <h3>Foto(s)</h3>
        <p *ngIf="uploadTypeGroup.value[0].status == 'started'">
          wird hochgeladen
          <span *ngIf="uploadTypeGroup.value.length == 2">{{ 'photo-waiting' | translate }}</span>
          <span *ngIf="uploadTypeGroup.value.length > 2">
            - {{ uploadTypeGroup.value.length - 1 }} {{ 'waiting' | translate }}</span
          >
        </p>
        <p *ngIf="uploadTypeGroup.value[0].status != 'started'">
          <span *ngIf="uploadTypeGroup.value.length == 1">{{ 'photo-waiting' | translate }}</span>
          <span *ngIf="uploadTypeGroup.value.length > 1">
            {{ uploadTypeGroup.value.length - 1 }} {{ 'waiting' | translate }}</span
          >
        </p>
        <mat-spinner [diameter]="24" *ngIf="uploadTypeGroup.value[0].status == 'started'"></mat-spinner>
      </div>
    </ng-container>
  </div>

  <ng-container [ngSwitch]="upload.type" *ngFor="let upload of failedUploads">
    <div class="failed-upload">
      <div style="flex-grow: 1; min-width: 0px">
        <div class="title">
          <i-feather name="alert-circle"></i-feather>
          <h3 *ngSwitchCase="'entry'">{{ 'diary-entry-cant-load' | translate }}</h3>
          <h3 *ngSwitchCase="'image'">{{ 'photo-cant-be-uploaded' | translate }}</h3>
        </div>
        <p>{{ 'reason' | translate }}: {{ upload.error?.status || 'unbekannt' }}</p>
      </div>
      <button mat-icon-button (click)="cancelUpload(upload)">
        <i-feather name="x"></i-feather>
      </button>
      <button mat-icon-button (click)="retryUpload(upload)" disabled="true">
        <i-feather name="rotate-ccw"></i-feather>
      </button>
    </div>
  </ng-container>
</div>
