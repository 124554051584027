import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import { IUser } from 'shared';

export enum OrganizationMemberActionTypes {
  // all member actions
  LoadAllOrganizationMembers = '[OrganizationMembers] Load All',
  LoadAllOrganizationMembersSuccess = '[OrganizationMembers] All Loaded Success',
  LoadAllOrganizationMembersError = '[OrganizationMembers] All Loaded Error'
}

/** LOAD PROJECT MEMBERS */

export class LoadAllOrganizationMembers implements Action {
  readonly type = OrganizationMemberActionTypes.LoadAllOrganizationMembers;
}

export class LoadAllOrganizationMembersSuccess implements Action {
  readonly type = OrganizationMemberActionTypes.LoadAllOrganizationMembersSuccess;

  constructor(public payload: { members: IUser[] }) {}
}

export class LoadAllOrganizationMembersError implements Action {
  readonly type = OrganizationMemberActionTypes.LoadAllOrganizationMembersError;

  constructor(public payload: { error: HttpErrorResponse }) {}
}

export type OrganizationMemberActions =
  | LoadAllOrganizationMembers
  | LoadAllOrganizationMembersSuccess
  | LoadAllOrganizationMembersError;
