import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { ProjectLandingRoutingModule } from './project-landing-routing.module';

import { ProjectLandingComponent } from './project-landing.component';
import { SharedPageModule } from 'libs/shared/src/lib/modules/shared-page.module';
import { FeatureDiaryModule } from '../../../diary/feature-diary.module';

@NgModule({
  imports: [CommonModule, FormsModule, ProjectLandingRoutingModule, FeatureDiaryModule, SharedPageModule],
  declarations: [ProjectLandingComponent]
})
export class ProjectLandingModule {}
