import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors } from '@angular/forms';

@Directive({
  selector: '[isFutureDateValidation]',
  providers: [{ provide: NG_VALIDATORS, useExisting: FutureDateValidator, multi: true }]
})
export class FutureDateValidator {
  constructor() {}

  validate(control: AbstractControl): ValidationErrors | null {
    if (control.value == undefined || !(control.value instanceof Date)) {
      return { dateRequiredError: null };
    } else if (control.value.getTime() < new Date().getTime()) {
      return { futureDateError: control.value };
    } else {
      return null;
    }
  }
}

@Directive({
  selector: '[dateSpanValidation]',
  providers: [{ provide: NG_VALIDATORS, useExisting: DateSpanValidator, multi: true }]
})
export class DateSpanValidator {
  @Input() startDate: Date | undefined = undefined;
  @Input() endDate: Date | undefined = undefined;

  constructor() {}

  validate(control: AbstractControl): ValidationErrors | null {
    if (control.value == undefined || !(control.value instanceof Date)) {
      return { dateRequiredError: null };
    }
    if (this.startDate != undefined && this.startDate.getTime() > control.value.getTime()) {
      return { invalidTimeError: control.value };
    }
    if (this.endDate != undefined && this.endDate.getTime() < control.value.getTime()) {
      control.markAsTouched();
      return { invalidTimeError: control.value };
    }
    return null;
  }
}
