import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { AppState } from '@auth0/auth0-angular';
import { Store } from '@ngrx/store';
import { ErrorService, ResourceService } from 'data-access';
import { AddProjectCompanies, LoadProjectCompanies, selectAllCompanies, selectAllCompaniesLoaded } from 'data-access';
import { selectSelectedProject } from 'data-access';
import { AutoComplete } from 'primeng/autocomplete';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { ICompany, IOrganization, IProject, RESOURCE_KEY } from 'shared';

@Component({
  selector: 'app-companies',
  templateUrl: './project-companies.component.html',
  styleUrls: ['./project-companies.component.scss']
})
export class ProjectCompaniesComponent implements OnInit {
  project: IProject | undefined = undefined;
  organization: IOrganization | undefined;
  companies: ICompany[] | undefined = undefined;
  autocompleteCompanies: BehaviorSubject<ICompany[]> = new BehaviorSubject<ICompany[]>([]);
  selectedCompanies: ICompany[] = [];
  loaded = false;
  saveInProgress = false;
  @ViewChild('companyNameInput') companyNameInput!: AutoComplete;

  constructor(
    @Inject(RESOURCE_KEY) private restService: ResourceService,
    private store: Store<AppState>,
    private errorService: ErrorService
  ) {}

  searchCompanies(term: string) {
    this.restService.getCompanies(term).subscribe(comps => {
      this.autocompleteCompanies.next(comps);
    });
  }

  get filteredCompanies(): Observable<ICompany[]> {
    return this.autocompleteCompanies.pipe(
      map(companies => {
        return companies.filter(company => {
          return (
            this.selectedCompanies.find(c => company.id == c.id) == undefined &&
            this.companies!.find(c => company.id == c.id) == undefined
          );
        });
      })
    );
  }

  ngOnInit() {
    this.store.dispatch(new LoadProjectCompanies());
    this.store.select(selectSelectedProject).subscribe(project => (this.project = project));
    this.store.select(selectAllCompanies).subscribe(companies => {
      console.log('@All Companies: ', companies);

      this.companies = companies;
    });
    this.store.select(selectAllCompaniesLoaded).subscribe(loaded => (this.loaded = loaded));
  }

  addCompaniesToProject(companies: ICompany[]) {
    this.saveInProgress = true;

    this.restService.postProjectCompanies(this.project!, companies).subscribe({
      error: err => {
        this.selectedCompanies = [];
        this.saveInProgress = false;
        this.errorService.showError('Auftragnehmer/ Subunternehmen konnte nicht hinzugefügt werden', err);
        this.companyNameInput.clear();
      },
      next: data => {
        this.selectedCompanies = [];
        this.saveInProgress = false;
        this.store.dispatch(new AddProjectCompanies({ companies: companies }));
        this.companyNameInput.clear();
      }
    });
  }

  selectCompany(company: ICompany) {
    this.selectedCompanies.push(company);
    this.companyNameInput.clear();
  }

  unselectCompany(company: ICompany): void {
    const index = this.selectedCompanies.indexOf(company);
    if (index >= 0) {
      this.selectedCompanies.splice(index, 1);
    }
  }
}
