import { OrganizationMemberActions, OrganizationMemberActionTypes } from './organization-member.actions';
import {
  initialOrganizationMembersState,
  organizationMemberAdapter,
  OrganizationMemberState
} from './organization-member.state';

export function organizationMemberReducer(
  state = initialOrganizationMembersState,
  action: OrganizationMemberActions
): OrganizationMemberState {
  switch (action.type) {
    case OrganizationMemberActionTypes.LoadAllOrganizationMembersSuccess:
      return organizationMemberAdapter.addMany(action.payload.members, {
        ...state,
        allMembersLoaded: true
      });

    default: {
      return state;
    }
  }
}
