import { Inject, Pipe, PipeTransform } from '@angular/core';
import { map, Observable, switchMap } from 'rxjs';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { RESOURCE_KEY } from 'shared';
import { ResourceService } from 'data-access';

@Pipe({ name: 'lazyLoadImage' })
export class LazyImageLoadingPipe implements PipeTransform {
  constructor(@Inject(RESOURCE_KEY) private resourceService: ResourceService, private sanitizer: DomSanitizer) {}

  transform(imageId: string, projectId: string): Observable<SafeUrl> {
    return this.resourceService.getEntryFile(projectId, imageId, true).pipe(
      map(fullImage => this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(fullImage))),
      switchMap(previewImage => {
        // Emit the preview image
        return new Observable<SafeUrl>(observer => {
          observer.next(previewImage);

          // Fetch the full resolution image
          this.resourceService
            .getEntryFile(projectId, imageId, false)
            .pipe(map(fullImage => this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(fullImage))))
            .subscribe(fullImage => {
              // Emit the full resolution image
              observer.next(fullImage);
              observer.complete();
            });
        });
      })
    );
  }
}
