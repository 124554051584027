<div class="page">
  <nav style="display: flex" class="page-tab">
    <button
      pButton
      class="p-button-sm"
      [class.p-button-text]="!rla1.isActive"
      [routerLink]="['./']"
      #rla1="routerLinkActive"
      routerLinkActive
      [routerLinkActiveOptions]="{exact: true}">
      {{"members" | translate}}
    </button>
    <button
      pButton
      class="p-button-sm"
      [class.p-button-text]="!rla2.isActive"
      [routerLink]="['invites']"
      #rla2="routerLinkActive"
      routerLinkActive
      style="margin-left: 8px">
      {{"invitations" | translate}}
    </button>
    <button
      pButton
      class="p-button-sm"
      [class.p-button-text]="!rla3.isActive"
      [routerLink]="['roles']"
      #rla3="routerLinkActive"
      routerLinkActive
      style="margin-left: 8px">
      {{"roles" | translate}}
    </button>
  </nav>
  <router-outlet></router-outlet>
</div>
