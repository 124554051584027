import { Injectable } from '@angular/core';
import { BehaviorSubject, distinctUntilChanged, Observable } from 'rxjs';
import { ICompany, IUser } from 'shared';

export interface IDiarySearchComponentParams {
  query?: string;
  companies?: ICompany[];
  users?: IUser[];
  dateRange?: Date[];
}

@Injectable({
  providedIn: 'root'
})
export class DiarySearchService {
  private searchParamsSource = new BehaviorSubject<IDiarySearchComponentParams>({});

  constructor() {}

  public searchParams(): Observable<IDiarySearchComponentParams> {
    return this.searchParamsSource.asObservable().pipe(
      distinctUntilChanged((prev, curr) => {
        return this.areEqual(prev, curr);
      })
    );
  }

  public setSearchParams(params: IDiarySearchComponentParams): void {
    this.searchParamsSource.next(params);
  }

  // Comparison function to determine if two DiarySearchParams are equal
  private areEqual(prev: IDiarySearchComponentParams, curr: IDiarySearchComponentParams): boolean {
    return JSON.stringify(prev) === JSON.stringify(curr);
  }
}
