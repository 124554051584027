import { Inject, Pipe, PipeTransform } from '@angular/core';
import { from, map, Observable } from 'rxjs';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { RESOURCE_KEY } from '../../../../shared/src/lib/inject-tokens/resource-inject-token';
import { ResourceService } from '../classes/resource-service.class';

@Pipe({
  name: 'projectImage'
})
export class ProjectImagePipe implements PipeTransform {
  constructor(@Inject(RESOURCE_KEY) private restService: ResourceService) {}

  transform(imageId: string, projectId: string, resolution: 'sm' | 'lg') {
    return from(this.restService.getImageBlobObs(projectId, imageId, resolution));
  }
}

@Pipe({
  name: 'secure'
})
export class SecureResourcePipe implements PipeTransform {
  constructor(@Inject(RESOURCE_KEY) private resourceService: ResourceService, private sanitizer: DomSanitizer) {}

  // transform(url: string): Observable<SafeUrl> {
  //   return this.resourceService
  //     .getImage(url)
  //     .pipe(map(data => this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(data))));
  // }

  transform(entryId: string, projectId: string, preview: boolean): Observable<SafeUrl> {
    return this.resourceService
      .getEntryFile(projectId, entryId, preview)
      .pipe(map(data => this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(data))));
  }
}

@Pipe({
  name: 'handleUndefinedImage'
})
export class HandleUndefinedImage implements PipeTransform {
  transform(url: SafeUrl | undefined | null): SafeUrl {
    return url ?? 'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=';
  }
}
