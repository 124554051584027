import { selectAllCompaniesLoaded } from './company.selector';
import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, filter, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import {
  CompanyActionTypes,
  LoadProjectCompanies,
  LoadProjectCompaniesError,
  LoadProjectCompaniesSuccess
} from './company.actions';
import { selectSelectedProject } from 'data-access';
import { RESOURCE_KEY } from 'shared';
import { ResourceService } from 'data-access';

@Injectable()
export class CompanyEffects {
  constructor(
    private actions$: Actions,
    @Inject(RESOURCE_KEY) private resourceService: ResourceService,
    private store: Store
  ) {}

  loadProjectCompanies$ = createEffect(() =>
    this.actions$.pipe(
      ofType<LoadProjectCompanies>(CompanyActionTypes.LoadProjectCompanies),
      withLatestFrom(this.store.select(selectAllCompaniesLoaded), this.store.select(selectSelectedProject)),
      filter(([action, allCompaniesLoaded, project]) => !allCompaniesLoaded && project != undefined),
      mergeMap(([action, allCompaniesLoaded, project]) =>
        this.resourceService.getProjectCompaniesObs(project?.id!).pipe(
          map(companies => {
            return new LoadProjectCompaniesSuccess({ companies: companies });
          }),

          catchError(err => {
            return of(new LoadProjectCompaniesError(err));
          })
        )
      )
    )
  );

  resetProjectCompanies$ = createEffect(() =>
    this.actions$.pipe(
      ofType<LoadProjectCompanies>(CompanyActionTypes.ResetCompaniesState),
      map(action => new LoadProjectCompanies())
    )
  );
}
