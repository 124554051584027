import { catchError, Observable, of, Subject } from 'rxjs';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'loading-component',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit {
  private _errorMessageSubject: Subject<string> = new Subject<string>();

  private _errorMessage$: Observable<string> = this._errorMessageSubject.asObservable();
  public get errorMessage$(): Observable<string> {
    return this._errorMessage$;
  }

  private _target: Observable<any> | null = null;
  public get target(): Observable<any> | null {
    return this._target;
  }

  // this input does nothing except catch the error and feed the
  // message into the errorMessage subject.
  @Input()
  public set target(o: Observable<any> | null) {
    if (o == null) {
      return;
    }
    this._target = o.pipe(
      catchError((error, _) => {
        this._errorMessageSubject.next(error);
        return of(null);
      })
    );
  }

  constructor() {}

  ngOnInit(): void {}
}
