import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'castBlobToObjectUrl'
})
export class CastBlobToObjectUrlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(blob: Blob): SafeUrl | undefined {
    if (blob) {
      return this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(blob));
    } else {
      return undefined;
    }
  }
}
