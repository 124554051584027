import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'error-display-component',
  templateUrl: './error-display.component.html',
  styleUrls: ['./error-display.component.scss']
})
export class ErrorDisplayComponent implements OnInit {
  @Output() onRetry = new EventEmitter<void>();
  @Input() retryEnabled: boolean = true;

  constructor() {}

  ngOnInit() {}

  retry() {
    this.onRetry.emit();
  }
}
