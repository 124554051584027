import { ChangeDetectorRef, Directive, ElementRef, forwardRef, HostListener, Renderer2 } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Directive({
  selector: 'input[type=date]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateInput),
      multi: true
    }
  ]
})
export class DateInput implements ControlValueAccessor {
  onChangeCallback = (_: any) => {};
  onTouchedCallback = () => {};

  @HostListener('input', ['$event.target.valueAsDate']) input(value: any) {
    // if(!value){
    //     value = undefined;
    // }else{
    //     console.log(value);
    // }
    this.onChangeCallback(value);
    this.cdf.detectChanges();
  }

  @HostListener('blur', []) touched() {
    this.onTouchedCallback();
  }

  constructor(private _renderer: Renderer2, private _elementRef: ElementRef, private cdf: ChangeDetectorRef) {}

  writeValue(value: any): void {
    if (value) {
      var date = new Date(value);
      this._renderer.setProperty(this._elementRef.nativeElement, 'valueAsDate', date);
    } else {
      this._renderer.setProperty(this._elementRef.nativeElement, 'valueAsDate', undefined);
    }
    //this.cdf.markForCheck();
  }

  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }
}
