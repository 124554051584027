import { AfterViewInit, Component, ElementRef, HostBinding, Input, OnDestroy, ViewChild } from '@angular/core';

@Component({
  selector: 'app-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss']
})
export class AccordionComponent implements AfterViewInit, OnDestroy {
  @Input() expanded: boolean = false;

  @ViewChild('content') content!: ElementRef;
  @ViewChild('contentWrapper') contentWrapper!: ElementRef;
  private resizeObserver!: ResizeObserver;
  @HostBinding('style.--accordion-content-height') contentHeight!: string;

  constructor() {}

  ngAfterViewInit(): void {
    this.observeContentHeight();
  }

  ngOnDestroy(): void {
    // Disconnect the observer when the component is destroyed to avoid memory leaks
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  }

  // Function to get content height
  private observeContentHeight(): void {
    if (this.content && this.contentWrapper) {
      this.resizeObserver = new ResizeObserver(entries => {
        for (const entry of entries) {
          // Update content height when it changes
          this.contentHeight = `${entry.contentRect.height}px`;
        }
      });
      this.resizeObserver.observe(this.content.nativeElement);
    }
  }
}
