import { NgModule } from '@angular/core';
import { FeatherModule } from 'angular-feather';
import { Check } from 'angular-feather/icons';
import { SelectOverlayComponent } from './select-overlay/select-overlay.component';
import { SelectableDirective } from './selectable.directive';

@NgModule({
  imports: [FeatherModule.pick({ Check })],
  declarations: [SelectOverlayComponent, SelectableDirective],
  providers: [],
  exports: [SelectableDirective]
})
export class SelectableModule {}
