import { HttpErrorResponse } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { Injectable } from '@angular/core';
import { ErrorMessageComponent, IErrorData } from 'shared';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {
  constructor(private dialog: MatDialog) {}

  showError(title: string, error: HttpErrorResponse) {
    this.dialog.open(ErrorMessageComponent, {
      width: '80%',
      maxWidth: '960px',
      minWidth: '600px',
      disableClose: false,
      data: { title: title, error: error }
    });
  }

  showErrorPlain(error: IErrorData) {
    this.dialog.open(ErrorMessageComponent, {
      width: '600px',
      disableClose: false,
      data: { title: error.title, message: error.message }
    });
  }
}
