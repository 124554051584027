import { TokenService } from '../classes/token-service.class';
import { BehaviorSubject, map, Observable, shareReplay, switchMap } from 'rxjs';
import { Inject, Injectable } from '@angular/core';
import { EventSourcePolyfill } from 'ng-event-source';
import { Store } from '@ngrx/store';
import { IPdfTemplate, IProject, ORGANIZATION_TOKEN_SERVICE, PdfStatusEnum, RESOURCE_KEY } from 'shared';
import { take } from 'rxjs/operators';
import { ResourceService } from '../classes/resource-service.class';
import { selectSelectedOrganization } from '../store';

@Injectable({
  providedIn: 'root'
})
export class PdfService {
  private templates: Observable<IPdfTemplate[] | undefined> | undefined = undefined;

  constructor(
    @Inject(RESOURCE_KEY) private restService: ResourceService,
    @Inject(ORGANIZATION_TOKEN_SERVICE) private tokenService: TokenService,
    private store: Store
  ) {}

  getPdfGenerationStatus(project: IProject, pdfId: string): Observable<PdfStatusEnum | undefined> {
    const eventEmitter: BehaviorSubject<PdfStatusEnum | undefined> = new BehaviorSubject<PdfStatusEnum | undefined>(
      undefined
    );

    return this.store.select(selectSelectedOrganization).pipe(
      take(1),
      switchMap(organization => {
        // init the event source
        const url = organization!.api + 'projects/' + project.id + '/pdf/' + pdfId + '/progress';
        const eventSource = new EventSourcePolyfill(url, {
          headers: {
            withCredentials: true,
            Authorization: 'Bearer ' + this.tokenService.accessToken
          }
        });

        eventSource.addEventListener('message', (ev: any) => {
          if (ev.data === 'complete') {
            eventEmitter.next(ev.data);
            eventEmitter.complete();
            eventSource.close();
          } else {
            eventEmitter.next(ev.data);
          }
        });
        eventSource.onerror = (err: any) => {
          eventSource.close();
          eventEmitter.complete();
        };
        eventSource.addEventListener('error', (ev: any) => {
          eventEmitter.error(ev.data);
          eventSource.close();
          eventEmitter.complete();
        });
        return eventEmitter.asObservable();
      })
    );
  }

  public getPdfTemplates(): Observable<IPdfTemplate[] | undefined> {
    if (!this.templates) {
      this.templates = this.restService.getPdfTemplates().pipe(shareReplay(1));
    }
    return this.templates;
  }

  public getPdfTemplate(templateId: string): Observable<IPdfTemplate | undefined> {
    return this.getPdfTemplates().pipe(map(templates => templates?.find(template => template.id === templateId)));
  }
}
