import { exportAdapter, ExportState, initialExportState } from './export.state';
import { ExportActions, ExportActionTypes } from './export.actions';

export function exportReducer(state = initialExportState, action: ExportActions): ExportState {
  switch (action.type) {
    case ExportActionTypes.AddPdfExports:
      return exportAdapter.addMany(action.pdfs, state);
    case ExportActionTypes.AddPdfExport:
      return exportAdapter.addOne(action.pdf, state);
    case ExportActionTypes.UpdatePdfExport:
      return exportAdapter.updateOne({ id: action.pdf.id, changes: action.pdf }, state);
    case ExportActionTypes.UpdatePdfGenerationProgress:
      return exportAdapter.updateOne({ id: action.id, changes: { status: action.progress } }, state);
    case ExportActionTypes.ResetExportState:
      return exportAdapter.getInitialState(initialExportState);
    case ExportActionTypes.RemovePdfExport:
      return exportAdapter.removeOne(action.id, state);
    default: {
      return state;
    }
  }
}
