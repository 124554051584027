import { HttpBackend, HttpClient, HttpInterceptor } from '@angular/common/http';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { InterceptingHandler } from './intercepting-handler';

export const MANAGEMENT_HTTP_INTERCEPTORS = new InjectionToken<HttpInterceptor[]>(
  'An abstraction on feature HttpInterceptor[]'
);

@Injectable()
export class ManagementHttpClient extends HttpClient {
  constructor(backend: HttpBackend, @Inject(MANAGEMENT_HTTP_INTERCEPTORS) managementInterceptors: HttpInterceptor[]) {
    super(new InterceptingHandler(backend, managementInterceptors));
  }
}
