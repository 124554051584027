import { NgcCookieConsentConfig } from 'ngx-cookieconsent';

export const globalCookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: 'bahntagebuch.de'
  },
  palette: {
    popup: {
      background: '#F4F4F4',
      text: '#1F1F1F'
    },
    button: {
      background: '#70A2CB',
      text: '#FFFFFF'
    }
  },
  theme: 'classic',
  type: 'info',
  content: {
    message:
      'Diese Website verwendet ausschließlich technisch notwendige Cookies. Es werden keine Daten durch analytische oder tracking Cookies erhoben.',
    dismiss: 'Bestätigen',
    link: '',
    policy: 'Cookie Policy'
  }
};
