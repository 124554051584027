import { Injectable } from '@angular/core';
import { ImageViewerComponentData, ImageViewerService } from 'shared';
import { ImageViewerComponent } from '../../../../../libs/core/src/lib/diary/components/image-viewer/image-viewer.component';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class WebImageViewerService implements ImageViewerService {
  constructor(private dialog: MatDialog) {}

  public openImageViewer(imageIds: string[], selectedIndex: number, entryId?: string): void {
    const data: ImageViewerComponentData = {
      imageIds: imageIds,
      initialIndex: selectedIndex,
      entryId: entryId
    };
    this.dialog.open(ImageViewerComponent, {
      width: '90vw',
      height: '90vh',
      disableClose: false,
      data: data
    });
  }
}
