import { Component, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { PermissionService, UserService } from 'data-access';
import { LayoutComponent } from 'libs/core/src/lib/layout/layout/layout.component';
import { PopoverService, USER_KEY } from 'shared';
import { AnnouncementsService } from '../../../../../../libs/core/src/lib/organizations/modules/announcements/announcements.service';

@Component({
  selector: 'app-web-layout',
  templateUrl: 'web-layout.component.html',
  styleUrls: ['./web-layout.component.scss']
})
export class WebLayoutComponent extends LayoutComponent {
  constructor(
    router: Router,
    store: Store,
    permissionService: PermissionService,
    popoverService: PopoverService,
    @Inject(USER_KEY) _userService: UserService,
    announcementService: AnnouncementsService
  ) {
    super(router, permissionService, popoverService, store, _userService, announcementService);
  }
}
