import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectSelectedOrganization } from 'data-access';
import { selectSelectedProject } from 'data-access';
import { combineLatest, Subject, takeUntil, tap } from 'rxjs';
import { environment, getMenuRoutes, getProjectRoutes, IRoute, ISubRoute } from 'shared';
import { AnnouncementsService } from '../../../organizations/modules/announcements/announcements.service';

@Component({
  selector: 'app-menu-content',
  templateUrl: './menu-content.component.html',
  styleUrls: ['./menu-content.component.scss']
})
export class MenuContentComponent implements OnInit, OnDestroy {
  @Input() activeRoute?: IRoute;
  @Input() activeSubRoute?: ISubRoute;

  disabled = false;
  menuRoutes: IRoute[] = [];
  projectRoutes: IRoute[] = [];

  readonly isProduction = environment.production;
  private readonly _destroyer$ = new Subject<void>();

  constructor(private store: Store, private announcementService: AnnouncementsService) {}

  ngOnInit(): void {
    combineLatest([
      this.store.select(selectSelectedOrganization),
      this.store.select(selectSelectedProject),
      this.announcementService.hasNewAnnouncement()
    ])
      .pipe(takeUntil(this._destroyer$))
      .subscribe(([org, pro, hasNewAnnouncement]) => {
        this.projectRoutes = getProjectRoutes(org, pro);
        this.disabled = !org;
        if (org) {
          this.menuRoutes = getMenuRoutes(org, hasNewAnnouncement);
        } else {
          this.menuRoutes = [];
        }
      });
  }

  ngOnDestroy(): void {
    this._destroyer$.next();
    this._destroyer$.complete();
  }
}
