import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {
  ComponentConfig,
  FormDialogComponent,
  IPermission,
  IRole,
  IRoleRequest,
  Permission,
  RESOURCE_KEY
} from 'shared';
import { PermissionEntity, PermissionService, ResourceService } from 'data-access';
import { Observable } from 'rxjs';

export interface EditRoleDialogData {
  roleId: string | undefined;
}

export interface EditRoleFormGroup {
  name: FormControl<string | null>;
  description: FormControl<string | null>;
  selectedPermissions: FormControl<Permission[] | null>;
}

@Component({
  selector: 'app-edit-role-dialog',
  templateUrl: './edit-role-dialog.component.html',
  styleUrls: ['./edit-role-dialog.component.scss']
})
export class EditRoleDialogComponent
  extends FormDialogComponent<EditRoleDialogData, IRole, EditRoleFormGroup>
  implements OnInit
{
  permissions: IPermission[] = [];

  get permissionsMap(): Map<PermissionEntity, Permission[]> {
    return this.permissionService.getOrganizationPermissionsMap();
  }

  constructor(
    @Inject(RESOURCE_KEY) private resourceService: ResourceService,
    private formBuilder: FormBuilder,
    private permissionService: PermissionService
  ) {
    super();
  }

  override getLoadingObs() {
    if (this.data?.roleId) {
      return this.resourceService.getRole(this.data.roleId);
    }
    return super.getLoadingObs();
  }

  override loadedHandle(role: any) {
    this.initFormData(role);
  }

  private initFormData(role: IRole) {
    this.formGroup?.controls.name.setValue(role.name);
    this.formGroup?.controls.description.setValue(role.description);
    this.formGroup?.controls.selectedPermissions.setValue(role.permissions);
  }

  get config(): ComponentConfig {
    return {
      header: `Rolle ${this.data?.roleId ? 'bearbeiten' : 'erstellen'}`,
      confirmSuccessMessage: 'Rolle gespeichert',
      confirmErrorMessage: 'Rolle konnte nicht gespeichert werden',
      confirmLabel: 'Speichern'
    };
  }

  buildFormGroup(): FormGroup<EditRoleFormGroup> {
    return this.formBuilder.group({
      name: new FormControl('', [Validators.required]),
      description: new FormControl(''),
      selectedPermissions: new FormControl<Permission[] | null>([], Validators.required)
    });
  }

  unselectPermission(permission: Permission) {
    const permissions = this.formGroup?.controls.selectedPermissions.value ?? [];
    const index = permissions.findIndex(p => (p = permission));
    if (index >= 0) {
      permissions.splice(index, 1);
      this.formGroup?.controls.selectedPermissions.setValue(permissions);
    }
  }

  selectPermission(permission: Permission) {
    const permissions = this.formGroup?.controls.selectedPermissions.value ?? [];
    if (!permissions.includes(permission)) {
      permissions.unshift(permission);
      this.formGroup?.controls.selectedPermissions.setValue(permissions);
    }
  }

  getSaveObs(): Observable<IRole> {
    const role: IRoleRequest = {
      name: this.formGroup?.controls.name.value!,
      description: this.formGroup?.controls.description.value!,
      permissions: this.formGroup?.controls.selectedPermissions.value ?? []
    };

    return this.data?.roleId === undefined
      ? this.resourceService.postRole(role)
      : this.resourceService.patchRole(this.data.roleId, role);
  }
}
