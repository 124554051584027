<form *ngIf="abstractEntryFormGroup" [formGroup]="abstractEntryFormGroup" class="flex flex-column">
  <div class="input-row" style="width: 60%">
    <div class="field flex-b-50">
      <div class="p-float-label">
        <p-dropdown
          id="entryTypeInput"
          appendTo="body"
          formControlName="type"
          [style]="{ width: '100%' }"
          [options]="entryTypeOptions"
          touchedDirty>
          <ng-template let-entryType pTemplate="item">
            <span>{{ entryType.label }}</span>
          </ng-template>
          <ng-template let-entryType pTemplate="selectedItem">
            <span>{{ entryType.label }}</span>
          </ng-template>
        </p-dropdown>
        <label for="entryTypeInput">Eintragstyp</label>
      </div>
    </div>
    <div class="field flex-b-50">
      <div class="p-float-label">
        <p-calendar
          [style]="{ width: '100%' }"
          [readonlyInput]="true"
          touchedDirty
          id="entryDateInput"
          class="max-w-full p-inputwrapper-filled"
          formControlName="date"
          dateFormat="d.m.yy"
          [showIcon]="true"
          [showWeek]="true">
        </p-calendar>
        <label for="entryDateInput">Datum</label>
      </div>
    </div>
  </div>
  <div *ngIf="entryType" [ngSwitch]="entryType">
    <app-regular-diary-entry-form
      #entryFormContainer
      *ngSwitchCase="'regular'"
      [form]="abstractEntryFormGroup"
      [mobile]="mobile"></app-regular-diary-entry-form>
    <app-observant-diary-entry-form
      #entryFormContainer
      *ngSwitchCase="'observant'"
      [form]="abstractEntryFormGroup"
      [mobile]="mobile"></app-observant-diary-entry-form>
  </div>
</form>
