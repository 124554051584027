<div style="max-width: 36rem">
  <div class="flex">
    <div class="field">
      <div class="p-float-label">
        <p-dropdown
          [options]="filteredUsers"
          [ngModelOptions]="{ standalone: true }"
          [(ngModel)]="selectedUser"
          (ngModelChange)="this.onChange(model)"
          optionLabel="name"
          [filter]="true"
          filterBy="name"
          [showClear]="true"
          placeholder="Angabe erforderlich"
          [required]="true"
          #userInput="ngModel"
          touchedDirty
          validUser
          id="userInput"
          class="p-inputwrapper-filled"
          [style]="{ width: '100%' }">
          <ng-template let-user pTemplate="selectedItem">
            <div class="flex align-items-center gap-2 user-template">
              <img [attr.src]="user?.picture" style="width: 1.6rem; border-radius: 50%" [alt]="user.name" />
              <p>{{ user.name }}</p>
            </div>
          </ng-template>
          <ng-template let-user pTemplate="item">
            <div class="flex align-items-center gap-2 user-template">
              <img [attr.src]="user?.picture" style="width: 1.6rem; border-radius: 50%" [alt]="user.name" />
              <p>{{ user.name }}</p>
            </div>
          </ng-template>
        </p-dropdown>
        <label for="userInput">{{ 'members' | translate }}</label>
      </div>
      <small id="userInputHelp" *ngIf="userInput.invalid && userInput.touched" class="p-error">{{
        'invalid-input' | translate
      }}</small>
    </div>
  </div>

  <div class="flex mt-2">
    <div class="field">
      <div class="p-float-label">
        <mat-chip-listbox
          #chipList
          aria-label="role selection"
          selectable="false"
          multiple="true"
          class="p-inputwrapper-filled"
          *ngIf="roles; else loadingChips">
          <mat-chip-option
            *ngFor="let role of roles"
            [matTooltip]="role.description"
            [selected]="selectedRoles.includes(role)"
            (click)="
              toggleRoleSelection(role);
              roleChipInput.control.updateValueAndValidity();
              roleChipInput.control.markAsDirty()
            ">
            {{ role.name }}
          </mat-chip-option>
        </mat-chip-listbox>
        <input
          [(ngModel)]="selectedRoles"
          atLeastOne
          #roleChipInput="ngModel"
          id="rolesInput"
          name="roleChipInput"
          touchedDirty
          hidden />
        <label for="rolesInput">{{ 'roles' | translate }}</label>
      </div>
      <small id="rolesInputHelp" *ngIf="roleChipInput.invalid && roleChipInput.touched" class="p-error">{{
        'input-required' | translate
      }}</small>
    </div>
  </div>
</div>

<ng-template #loadingChips>
  <mat-chip-listbox aria-label="role selection" selectable="false" multiple="false">
    <mat-chip *ngFor="let index of [1, 2, 3]" style="min-width: 90px" [disabled]="true"></mat-chip>
  </mat-chip-listbox>
</ng-template>
