import { mergeMap } from 'rxjs/operators';
import { combineLatest, filter } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from '@auth0/auth0-angular';
import { LoadAllProjects, SelectProject, selectAllProjects, selectAllProjectsLoaded } from 'data-access';

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss']
})
export class ProjectComponent implements OnInit {
  constructor(private router: Router, private routes: ActivatedRoute, private store: Store<AppState>) {
    this.store.dispatch(new LoadAllProjects());
    this.store
      .select(selectAllProjectsLoaded)
      .pipe(
        filter(loaded => loaded),
        mergeMap(() => combineLatest([this.store.select(selectAllProjects), this.routes.paramMap]))
      )
      .subscribe(([projects, params]) => {
        // try to resolve the project by path params if it is undefined
        if (projects != undefined) {
          let project = projects.find(project => project.id == params.get('projectId'));
          if (project != undefined) {
            this.store.dispatch(new SelectProject({ projectId: project.id! }));
          } else {
            this.router.navigate(['../not-found'], { relativeTo: this.routes });
          }
        }
      });
  }

  ngOnInit() {}
}
