import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { LandingPageRoutingModule } from './overview-routing.module';
import { OrganizationOverviewPage } from './overview.page';
import { SharedPageModule } from 'shared';

@NgModule({
  imports: [CommonModule, FormsModule, LandingPageRoutingModule, SharedPageModule],
  declarations: [OrganizationOverviewPage]
})
export class OrganizationOverviewPageModule {}
