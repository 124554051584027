import { SharedPageModule } from 'libs/shared/src/lib/modules/shared-page.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ProjectMembersRoutingModule } from './project-members-routing.module';
import { TableModule } from 'primeng/table';
import { ProjectMembersComponent } from './project-members.component';
import { ProjectUserInputModule } from '../../components/project-user-input/project-user-input.module';

@NgModule({
  imports: [
    SharedPageModule,
    CommonModule,
    FormsModule,
    ProjectMembersRoutingModule,
    TableModule,
    ProjectUserInputModule
  ],
  declarations: [ProjectMembersComponent]
})
export class ProjectMembersModule {}
