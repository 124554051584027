import { Inject, Injectable } from '@angular/core';
import { ConfirmDialogComponent, ConfirmDialogData, DIALOG_SERVICE_KEY, DialogService } from 'shared';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WebConfirmService {
  constructor(@Inject(DIALOG_SERVICE_KEY) private dialog: DialogService) {}

  public confirm(dialogData: ConfirmDialogData): Observable<boolean> {
    return this.dialog
      .openDialog<ConfirmDialogComponent, ConfirmDialogData, boolean>(ConfirmDialogComponent, { data: dialogData })
      .afterDismissed()
      .pipe(
        map(confirmed => {
          // capture confirmed = undefined case
          return confirmed ?? false;
        })
      );
  }
}
