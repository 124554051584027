import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'popover-container',
  templateUrl: './popover-container.component.html',
  styleUrls: ['./popover-container.component.scss']
})
export class PopoverContainerComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
