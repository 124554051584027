import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ListComponent } from './components/list/list.component';
import { InviteComponent } from './components/invite/invite.component';
import { RolesComponent } from './modules/roles/roles.component';
import { MembersPage } from './components/members/members.page';
import { UserResolverService } from './resolver/user.resolver';

const routes: Routes = [
  {
    path: '',
    component: MembersPage,
    children: [
      {
        path: '',
        component: ListComponent
      },
      {
        path: 'invites',
        component: InviteComponent
      },
      {
        path: 'roles',
        component: RolesComponent
      }
    ]
  },
  {
    path: ':userId',
    loadChildren: () => import('./modules/user/user.module').then(m => m.UserPageModule),
    resolve: {
      user: UserResolverService
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CoreMembersRoutingModule {}
