import { selectAllImagesLoaded } from './image.selectors';
import { ImageActionTypes, LoadAllImages, LoadAllImagesError, LoadAllImagesSuccess } from './image.actions';
import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { catchError, combineLatest, filter, map, mergeMap, of, withLatestFrom } from 'rxjs';
import { selectSelectedOrganization } from 'data-access';
import { selectSelectedProject } from 'data-access';
import { RESOURCE_KEY } from 'shared';
import { ResourceService } from 'data-access';

@Injectable()
export class ImageEffects {
  constructor(
    private actions$: Actions,
    @Inject(RESOURCE_KEY) private resourceService: ResourceService,
    private store: Store
  ) {}

  loadAllImages$ = createEffect(() =>
    this.actions$.pipe(
      ofType<LoadAllImages>(ImageActionTypes.LoadAllImages),
      withLatestFrom(this.store.pipe(select(selectAllImagesLoaded))),
      filter(([action, allImagesLoaded]) => !allImagesLoaded),
      mergeMap(() =>
        combineLatest([
          this.store.pipe(select(selectSelectedOrganization)),
          this.store.pipe(select(selectSelectedProject))
        ])
      ),
      // mergeMap(() => this.store.pipe(select(selectSelectedOrganization))),
      // withLatestFrom(this.store.pipe(select(selectSelectedProject))),
      filter(([organization, project]) => organization != undefined && project != undefined),
      mergeMap(([organization, project]) =>
        this.resourceService.getImagesObs(organization!, project!).pipe(
          map(images => {
            return new LoadAllImagesSuccess({ images: images });
          }),
          catchError(err => {
            return of(new LoadAllImagesError(err));
          })
        )
      )
    )
  );
}
