import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import {
  DiaryEntryType,
  IDiaryObject,
  IEntry,
  IEntryDialogData,
  IImageEntry,
  IEntryInterface,
  IProject,
  IRegularEntry,
  DialogService,
  DIALOG_SERVICE_KEY,
  IAbstractEntry
} from 'shared';
import { AbstractDiaryDialogComponent } from '../abstract-diary-dialog/abstract-diary-dialog.component';
import { ImagePreviewListComponent } from '../image-preview-list/image-preview-list.component';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'diary-date-component',
  templateUrl: './diary-date.component.html',
  styleUrls: ['./diary-date.component.scss']
})
export class DiaryDateComponent {
  @Input() diaryObject!: IDiaryObject;
  @Input() project!: IProject;
  @Input() dateIndex!: number;
  @Input() highlighted = false;
  @Input() suppressBottomSpacer = false;
  @Input() canEdit = true;

  DiaryEntryType = DiaryEntryType;
  IRegularEntry!: IRegularEntry;
  @ViewChild('imagePreviewList') imagePreviewList!: ImagePreviewListComponent;

  constructor(@Inject(DIALOG_SERVICE_KEY) private dialogService: DialogService, private datePipe: DatePipe) {}

  newEntry() {
    const data: IEntryDialogData = {
      date: this.diaryObject.date,
      dateSpan: this.diaryObject.dateSpan
        ? [this.diaryObject.dateSpan.startDate, this.diaryObject.dateSpan.endDate]
        : undefined
    };
    this.dialogService.openDialog<AbstractDiaryDialogComponent, IEntryDialogData, IEntryInterface>(
      AbstractDiaryDialogComponent,
      { data: data }
    );
  }

  // returns true if the dates compare
  checkIfDateIsToday(): boolean {
    const today = new Date();
    return (
      today.getDate() === this.diaryObject.date.getDate() &&
      today.getMonth() === this.diaryObject.date.getMonth() &&
      today.getFullYear() === this.diaryObject.date.getFullYear()
    );
  }

  protected get imageIds(): string[] {
    return this.entryImages.map(imageEntry => imageEntry.id);
  }

  checkIfDateIsYesterday(): boolean {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    return (
      yesterday.getDate() === this.diaryObject.date.getDate() &&
      yesterday.getMonth() === this.diaryObject.date.getMonth() &&
      yesterday.getFullYear() === this.diaryObject.date.getFullYear()
    );
  }

  // returns true if the dates compare
  compareTimeStampsForDate(firstDate: Date, secondDate: Date): boolean {
    return (
      firstDate.getDate() === secondDate.getDate() &&
      firstDate.getMonth() === secondDate.getMonth() &&
      firstDate.getFullYear() === secondDate.getFullYear()
    );
  }

  public getTitle(): string | undefined | null {
    if (this.checkIfDateIsYesterday()) {
      return 'Gestern -' + this.datePipe.transform(this.diaryObject.diaryDate?.date, 'dd.MM.yyyy');
    } else if (this.checkIfDateIsToday()) {
      return 'Heute -' + this.datePipe.transform(this.diaryObject.diaryDate?.date, 'dd.MM.yyyy');
    } else {
      return this.datePipe.transform(this.diaryObject.diaryDate?.date, 'dd.MM.yyyy');
    }
  }

  public trackByEntryId(index: number, entry: IEntry) {
    return entry.id;
  }

  public get entryImages(): IImageEntry[] {
    return (
      (this.diaryObject.diaryDate?.entries?.filter(entry => entry.type === DiaryEntryType.IMAGE) as IImageEntry[]) ?? []
    );
  }

  public get entries(): IAbstractEntry[] {
    const allowedTypes = [DiaryEntryType.REGULAR, DiaryEntryType.OBSERVANT];

    return this.diaryObject.diaryDate?.entries?.filter(entry => allowedTypes.includes(entry.type)) ?? [];
  }
}
