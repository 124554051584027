import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { OrganizationsPage } from './components/organizations/organizations.page';
import { CoreOrganizationsRoutingModule } from './core-organizations-routing.module';
import { SharedPageModule } from 'libs/shared/src/lib/modules/shared-page.module';

@NgModule({
  imports: [CommonModule, FormsModule, CoreOrganizationsRoutingModule, SharedPageModule],
  declarations: [OrganizationsPage]
})
export class CoreOrganizationsModule {}
