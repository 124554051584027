import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import { IMember, IRole } from 'shared';

export enum MemberActionTypes {
  // all member actions
  LoadAllProjectMembers = '[ProjectMembers] Load All',
  LoadAllProjectMembersSuccess = '[ProjectMembers] All Loaded Success',
  LoadAllProjectMembersError = '[ProjectMembers] All Loaded Error',

  // single member actions
  AddProjectMember = '[ProjectMember] Add',
  RemoveProjectMember = '[ProjectMember] Remove',
  SetProjectMemberRoles = '[ProjectMember] Set Roles',
  ResetProjectMemberState = '[ProjectMember] Reset State'
}

/** LOAD PROJECT MEMBERS */

export class LoadAllProjectMembers implements Action {
  readonly type = MemberActionTypes.LoadAllProjectMembers;
}

export class LoadAllProjectMembersSuccess implements Action {
  readonly type = MemberActionTypes.LoadAllProjectMembersSuccess;

  constructor(public payload: { members: IMember[] }) {}
}

export class LoadAllProjectMembersError implements Action {
  readonly type = MemberActionTypes.LoadAllProjectMembersError;

  constructor(public payload: { error: HttpErrorResponse }) {}
}

/** ADD PROJECT MEMBER */

export class AddProjectMember implements Action {
  readonly type = MemberActionTypes.AddProjectMember;

  constructor(public payload: { member: IMember }) {}
}

/** REMOVE PROJECT MEMBER */

export class RemoveProjectMember implements Action {
  readonly type = MemberActionTypes.RemoveProjectMember;

  constructor(public payload: { memberId: string }) {}
}

/** ADD PROJECT MEMBER ROLE */

export class SetProjectMemberRoles implements Action {
  readonly type = MemberActionTypes.SetProjectMemberRoles;

  constructor(public payload: { member: IMember; roles: IRole[] }) {}
}

export class ResetProjectMemberState implements Action {
  readonly type = MemberActionTypes.ResetProjectMemberState;
}

export type MemberActions =
  | LoadAllProjectMembers
  | LoadAllProjectMembersSuccess
  | LoadAllProjectMembersError
  | AddProjectMember
  | RemoveProjectMember
  | SetProjectMemberRoles
  | ResetProjectMemberState;
