import { ScrollStrategyOptions } from '@angular/cdk/overlay';
import { Component, Inject, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { ErrorService, ResourceService, selectEntryById, UserService } from 'data-access';
import { DeleteEntry, selectEntriesByParent, UpdateEntry, selectSelectedProject } from 'data-access';
import {
  DIALOG_SERVICE_KEY,
  DialogService,
  IEntryDialogData,
  IEntryInterface,
  IImageEntry,
  IProject,
  IRegularEntry,
  IUser,
  PopoverService,
  RESOURCE_KEY,
  USER_KEY
} from 'shared';
import { ImagePreviewListComponent } from '../image-preview-list/image-preview-list.component';
import { AbstractDiaryDialogComponent } from '../abstract-diary-dialog/abstract-diary-dialog.component';

@Component({
  selector: 'diary-entry-component',
  templateUrl: './diary-entry.component.html',
  styleUrls: ['./diary-entry.component.scss']
})
export class DiaryEntryComponent implements OnInit {
  @ViewChild('imagePreviewList') imagePreviewList!: ImagePreviewListComponent;

  @Input() entryId!: string;

  protected entry!: IRegularEntry;
  protected project?: IProject;

  isExpanded = false;
  user?: IUser;

  private images: IImageEntry[] = [];

  constructor(
    private renderer: Renderer2,
    private dialog: MatDialog,
    private store: Store,
    private popoverService: PopoverService,
    private readonly bottomSheet: MatBottomSheet,
    private readonly scroller: ScrollStrategyOptions,
    private errorService: ErrorService,
    @Inject(RESOURCE_KEY) private resourceService: ResourceService,
    @Inject(USER_KEY) private _userService: UserService,
    @Inject(DIALOG_SERVICE_KEY) private dialogService: DialogService
  ) {}

  ngOnInit() {
    this._userService.getLoggedUser().subscribe(user => (this.user = user));
    this.store.select(selectSelectedProject).subscribe(project => (this.project = project));
    this.store.select(selectEntryById(this.entryId)).subscribe(entry => (this.entry = entry as IRegularEntry));
    this.store.select(selectEntriesByParent(this.entryId)).subscribe(entries => {
      this.images = entries.map(entry => entry as IImageEntry);
    });
  }

  editEntry() {
    const data: IEntryDialogData = {
      date: this.entry.date,
      entry: this.entry
    };
    this.dialogService.openDialog<AbstractDiaryDialogComponent, IEntryDialogData, IEntryInterface>(
      AbstractDiaryDialogComponent,
      { data: data }
    );

    // const data: IEntryDialogData = {
    //   date: this.entry.date,
    //   entry: this.entry
    // };
    // if (window.innerWidth >= 1024) {
    //   return this.dialog
    //     .open(AbstractDiaryDialogComponent, {
    //       maxWidth: '80vw',
    //       maxHeight: '80vh',
    //       disableClose: true,
    //       data: data
    //     })
    //     .afterClosed();
    // } else {
    //   return this.bottomSheet
    //     .open(AbstractDiaryDialogComponent, {
    //       disableClose: true,
    //       scrollStrategy: this.scroller.noop(),
    //       data: data
    //     })
    //     .afterDismissed();
    // }
  }

  closeEntry() {
    this.resourceService.closeEntry(this.project!.id!, this.entry.id!).subscribe({
      error: err => {
        this.errorService.showError('Eintrag konnte nicht abgeschlossen werden', err);
      },
      next: entry => {
        this.store.dispatch(new UpdateEntry(entry));
      }
    });
    // this.store.dispatch(new CloseEntry({ id: this.entry.id! }));
    this.popoverService.setState(true);
  }

  deleteEntry() {
    this.resourceService.deleteEntry(this.project?.id!, this.entry.id!).subscribe({
      error: err => {
        this.errorService.showError('Eintrag konnte nicht gelöscht werden', err);
      },
      next: () => {
        this.store.dispatch(new DeleteEntry(this.entry.id!));
      }
    });
    this.popoverService.setState(true);
  }

  get isCreator(): boolean {
    return this.entry.creator?.id === this.user?.id;
  }

  protected get imageIds(): string[] {
    return this.images.map(imageEntry => imageEntry.id);
  }
}
