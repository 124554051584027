import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ResetEntriesState } from 'data-access';
import { ResetImagesState } from 'data-access';
import { UnselectProject } from 'data-access';
import { BehaviorSubject, Observable } from 'rxjs';
import { IProject as TestProject, IProject, IRole, RESOURCE_KEY } from 'shared';
import { ResourceService } from 'data-access';

@Injectable({
  providedIn: 'root'
})
export class ProjectsService {
  private projects: Observable<IProject[] | undefined> = new Observable<IProject[]>(undefined);
  private selectedProject: BehaviorSubject<IProject | undefined> = new BehaviorSubject<IProject | undefined>(undefined);
  private isLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private hasError: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  constructor(@Inject(RESOURCE_KEY) private resourceService: ResourceService, private store: Store) {}

  public getIsLoading(): Observable<boolean> {
    return this.isLoading.asObservable();
  }

  compareModifiedAt(a: IProject, b: IProject): number {
    if (!a.modifiedAt && !b.modifiedAt) {
      return 0;
    }
    if (!b.modifiedAt) {
      return -1;
    }
    if (!a.modifiedAt) {
      return 1;
    }
    if (a.modifiedAt > b.modifiedAt) {
      return -1;
    }
    if (a.modifiedAt < b.modifiedAt) {
      return 1;
    }
    return 0;
  }

  // fills the given project with the users roles and resolves it after finished
  public setRolesForProject(project: IProject): Promise<IProject> {
    return new Promise<IProject>((resolve, reject) => {
      this.resourceService.getRoleForProject(project).then(role => {
        project.role = role;
        resolve(project);
      });
    });
  }

  public getProjects(): Observable<IProject[] | undefined> {
    return this.projects;
  }

  public selectProject(project: IProject) {
    this.selectedProject.next(project);
  }

  public getSelectedProject(): Observable<IProject | undefined> {
    return this.selectedProject.asObservable();
  }

  public unselectProject() {
    this.store.dispatch(new UnselectProject());
    this.store.dispatch(new ResetEntriesState());
    this.store.dispatch(new ResetImagesState());
    //this.selectedProject.next(undefined)
  }

  public getDateString(date: Date): string {
    return date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
  }

  /**
   *
   * @param authority the authority to be checked
   * @returns true if the user has the authority, otherwise false
   */
  public hasAuthorityOnSelectedProject(authority: string): boolean {
    authority = authority.trim().toLowerCase();
    const role: IRole = this.selectedProject.getValue()?.role!;
    if (!role) {
      return false;
    }
    return false;
  }

  getEmptyProject(): TestProject {
    return {
      id: '',
      number: '',
      commissioningAuthority: '',
      supervisoryAuthority: '',
      name: '',
      description: '',
      constructionTask: '',
      startDate: new Date(),
      endDate: new Date(),
      measurementsLocation: '',
      documentsLocation: '',
      geoTag: '',
      createdAt: undefined,
      modifiedAt: undefined,
      companyIds: [],
      interruptionIds: []
    };
  }

  public getHasError(): Observable<boolean> {
    return this.hasError.asObservable();
  }
}
