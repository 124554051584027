import { Component, EventEmitter, Input, Output } from '@angular/core';

export type MessageType = 'success' | 'info' | 'warn' | 'error';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent {
  @Input() type: MessageType = 'error';
  @Input() summary = '';
  @Input() detail = '';
  @Input() icon?: string;
  @Input() showCloseButton = true;
  @Output() close = new EventEmitter<void>();

  onClose() {
    this.close.emit();
  }
}
