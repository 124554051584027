import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors } from '@angular/forms';

export function validateWordCount(control: AbstractControl): ValidationErrors | null {
  const words = control.value.split(' ');
  const wordCount = words.reduce(function (accumulator: number, currentValue: string): number {
    //filter out multiple white spaces
    if (currentValue.trim() != '') {
      return accumulator + 1;
    }
    return accumulator;
  }, 0);
  if (wordCount < 3) {
    return { wordCountError: true };
  }
  return null;
}

@Directive({
  selector: '[validateWordCount]',
  providers: [{ provide: NG_VALIDATORS, useExisting: WordCountValidator, multi: true }]
})
export class WordCountValidator {
  validate(control: AbstractControl): ValidationErrors | null {
    const words = control.value ? control.value.split(' ') : [];
    const wordCount = words.reduce(function (accumulator: number, currentValue: string): number {
      //filter out multiple white spaces
      if (currentValue.trim() != '') {
        return accumulator + 1;
      }
      return accumulator;
    }, 0);
    if (wordCount < 3) {
      return { wordCountError: true };
    }
    return null;
  }
}
