import { Directive, ElementRef, Renderer2, AfterViewInit, Input } from '@angular/core';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Directive({
  selector: '[appScrollablePadding]'
})
export class ScrollablePaddingDirective implements AfterViewInit {
  @Input() appScrollablePaddingValue?: string = '0.6rem';
  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngAfterViewInit(): void {
    this.checkScrollbar();

    // Listen for window resize events to handle dynamic content changes
    fromEvent(window, 'resize')
      .pipe(debounceTime(100))
      .subscribe(() => this.checkScrollbar());

    // Observe DOM changes to detect content changes
    const observer = new MutationObserver(() => this.checkScrollbar());
    observer.observe(this.el.nativeElement, { childList: true, subtree: true });
  }

  private checkScrollbar(): void {
    const element = this.el.nativeElement;
    const hasVerticalScrollbar = element.scrollHeight > element.clientHeight;

    if (hasVerticalScrollbar) {
      this.renderer.setStyle(element, 'padding-right', this.appScrollablePaddingValue);
    } else {
      this.renderer.removeStyle(element, 'padding-right');
    }
  }
}
