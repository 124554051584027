import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { map, Observable, of } from 'rxjs';
import { selectAllOrganizations, selectAllOrganizationsLoaded, selectSelectedOrganization } from 'data-access';
import { IOrganization } from 'shared';

@Component({
  selector: 'app-customer-selector',
  templateUrl: './customer-selector.component.html',
  styleUrls: ['./customer-selector.component.scss']
})
export class CustomerSelectorComponent implements OnInit {
  organizations: Observable<IOrganization[]> = of([]);
  selectedOrganization: Observable<IOrganization | undefined> = of(undefined);
  isLoading: Observable<boolean> = of(false);
  popoverActive = false;

  constructor(private store: Store) {}

  ngOnInit() {
    this.organizations = this.store.pipe(select(selectAllOrganizations));
    this.isLoading = this.store.pipe(select(selectAllOrganizationsLoaded)).pipe(map(isLoaded => !isLoaded));
    this.selectedOrganization = this.store.pipe(select(selectSelectedOrganization));
  }
}
