import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ComponentType } from '@angular/cdk/overlay';
import { DialogComponent } from './dialog/dialog.component';
import { DialogContainerData } from './dialog-container/dialog-container-data';

@Injectable({ providedIn: 'root' })
export abstract class DialogService {
  openDialog<C extends DialogComponent<D, R>, D, R>(
    component: ComponentType<C>,
    config?: DialogConfig<D> | undefined
  ): DialogContainerRef<R> {
    const dialogContainerData: DialogContainerData<C, D> = {
      componentType: component,
      data: config?.data ?? undefined
    };
    // open right dialog type
    switch (config?.type) {
      case 'dialog':
        return this.openRegularDialog(dialogContainerData);
      case 'sheet':
        return this.openSheet(dialogContainerData);
      case 'modal':
        return this.openModal(dialogContainerData);
      default:
        // if no explicit type is set open dialog for screen size
        if (window.innerWidth >= 1024) {
          return this.openRegularDialog(dialogContainerData);
        }
        return this.openSheet(dialogContainerData);
    }
  }

  protected openSheet<C, D, R>(dialogContainerData: DialogContainerData<C, D>): DialogContainerRef<R> {
    throw new Error('Sheet dialog not implemented on this platform');
  }

  protected openRegularDialog<C, D, R>(dialogContainerData: DialogContainerData<C, D>): DialogContainerRef<R> {
    throw new Error('Regular Dialog not implemented on this platform');
  }

  protected openModal<C, D, R>(dialogContainerData: DialogContainerData<C, D>): DialogContainerRef<R> {
    throw new Error('Modal dialog not implemented on this platform');
  }
}

export abstract class DialogContainerRef<R> {
  abstract close(): void;
  abstract afterDismissed(): Observable<R | undefined>;
}

export interface DialogConfig<D> {
  type?: 'modal' | 'sheet' | 'dialog';
  data?: D | undefined;
}
