<div class="page-content flex flex-column">
  <div class="flex flex-row justify-content-between align-items-start">
    <div>
      <h1>{{ 'members' | translate }}</h1>
      <h3>{{ 'active-organisation-members' | translate }}</h3>
    </div>
    <button
      pButton
      class="p-button-sm"
      [routerLink]="['./invites']"
      routerLinkActive="active-link"
      [routerLinkActiveOptions]="{ exact: true }"
      [label]="'add' | translate"></button>
  </div>
  <div class="page-searchbar">
    <div class="field">
      <div class="p-float-label">
        <input pInputText [formControl]="memberSearchForm" id="memberSearchForm" class="p-filled w-full" touchedDirty />
        <label for="memberSearchForm">{{ 'search' | translate }}</label>
      </div>
    </div>
  </div>

  <div class="table-responsive" style="margin-bottom: 1rem">
    <table mat-table [dataSource]="filteredUsers">
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>{{ 'member' | translate }}</th>
        <td mat-cell *matCellDef="let user">
          <div class="member-display">
            <img class="member-avatar" [attr.src]="user?.picture" />
            <div class="member-info">
              <h3>{{ user?.name }}</h3>
              <p>{{ user?.email }}</p>
            </div>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="membership">
        <th mat-header-cell *matHeaderCellDef>{{ 'affiliation' | translate }}</th>
        <td mat-cell *matCellDef="let user">
          {{ getMembership(user.license) }}
        </td>
      </ng-container>

      <ng-container matColumnDef="control">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let user">
          <button
            mat-icon-button
            color="primary"
            [popoverTrigger]="controlMenuTemplate"
            [popoverContext]="{ user: user }"
            [positionX]="['end', 'end']"
            [positionY]="['bottom', 'top']">
            <i-feather name="more-vertical"></i-feather>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="['name', 'membership', 'control']"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: ['name', 'membership', 'control']"
        [ngClass]="{ highlighted: row.id === loggedInUser?.id }"></tr>
    </table>
  </div>

  <error-display-component *ngIf="!loading && hasError" (onRetry)="loadPageData()"></error-display-component>
  <mat-spinner style="margin-left: 1.4rem" *ngIf="loading" diameter="30"></mat-spinner>
  <h3 style="margin-left: 1.4rem" *ngIf="!filteredUsers?.length && !loading && !hasError">
    {{ 'no-users-found' | translate }}
  </h3>
</div>

<ng-template #controlMenuTemplate let-user="user">
  <popover-container>
    <div class="control-menu">
      <button pButton class="p-button-sm p-button-text" autoClosePopover [routerLink]="['./', user.id]">
        <span class="mat-outlined">visibility</span>
        <span>{{ 'details' | translate }}</span>
      </button>

      <button pButton class="p-button-sm p-button-text" autoClosePopover [routerLink]="['./', user.id, 'roles']">
        <span class="mat-outlined">key</span>
        <span>Rollen</span>
      </button>

      <button
        [hidden]="isProduction"
        pButton
        class="p-button-sm p-button-text p-button-warning"
        autoClosePopover
        (click)="assignLicense(user)"
        *ngIf="!hasLicense(user)"
        [disabled]="loggedInUser?.id === user.id || true">
        <span class="mat-outlined">credit_card</span>
        <span>
          {{ 'assign-license' | translate }}
        </span>
      </button>

      <hr />

      <button
        pButton
        class="p-button-sm p-button-text p-button-warning"
        autoClosePopover
        (click)="removeLicense(user)"
        *ngIf="hasLicense(user)"
        [disabled]="loggedInUser?.id === user.id || true">
        <span class="mat-outlined">credit_card</span>
        <span>
          {{ 'delete-license' | translate }}
        </span>
      </button>

      <button
        pButton
        class="p-button-sm p-button-text p-button-warning"
        autoClosePopover
        (click)="blockUser(user)"
        disabled>
        <span class="mat-outlined">close</span>
        <span *ngIf="loggedInUser?.id === user.id">{{ 'leave-organisation' | translate }}</span>
        <span *ngIf="loggedInUser?.id !== user.id">{{ 'block-user' | translate }} </span>
      </button>
    </div>
  </popover-container>
</ng-template>
