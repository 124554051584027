import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { EMPTY, of, switchMap } from 'rxjs';
import { catchError, filter, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import { IProject, RESOURCE_KEY } from 'shared';
import {
  AddProjectError,
  LoadAllProjects,
  LoadAllProjectsError,
  LoadAllProjectsSuccess,
  LoadProjectSuccess,
  ProjectActionTypes,
  ReloadAllProjects,
  ResetSelectedProjectState,
  RestoreLastProject,
  RestoreLastProjectError,
  RestoreLastProjectSuccess,
  SelectProject,
  UnselectProject
} from './project.actions';
import { selectLastProjectRestored, selectProjectsLoaded } from './project.selectors';
import { ResetExportState, selectSelectedOrganization } from 'data-access';
import { ErrorService, ResourceService } from 'data-access';
import { LoadProjectCompanies, ResetCompaniesState } from 'data-access';
import { LoadAllProjectMembers, ResetProjectMemberState } from 'data-access';
import { ResetEntriesState } from 'data-access';

@Injectable()
export class ProjectEffects {
  constructor(
    private actions$: Actions,
    @Inject(RESOURCE_KEY) private resourceService: ResourceService,
    private store: Store,
    private errorService: ErrorService,
    private router: Router
  ) {}

  loadAllProjects$ = createEffect(() =>
    this.actions$.pipe(
      ofType<LoadAllProjects>(ProjectActionTypes.LoadAllProjects),
      withLatestFrom(this.store.pipe(select(selectProjectsLoaded))),
      filter(([action, AllProjectsLoaded]) => !AllProjectsLoaded),
      mergeMap(() =>
        this.store.pipe(select(selectSelectedOrganization)).pipe(
          filter(org => org != undefined),
          mergeMap(organization =>
            this.resourceService.getOrganizationProjects(organization!).pipe(
              map(projects => {
                let selectedProject = projects.find(org => org.id == localStorage.getItem('last_project'));
                if (selectedProject) {
                  this.store.dispatch(new SelectProject({ projectId: selectedProject.id! }));
                }
                return new LoadAllProjectsSuccess({ projects: projects });
              }),
              catchError(err => {
                return of(new LoadAllProjectsError(err));
              })
            )
          )
        )
      )
    )
  );

  reloadAllProjects$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReloadAllProjects>(ProjectActionTypes.ReloadAllProjects),
      mergeMap(() =>
        this.store.pipe(select(selectSelectedOrganization)).pipe(
          filter(org => org != undefined),
          mergeMap(organization =>
            this.resourceService.getOrganizationProjects(organization!).pipe(
              map(projects => {
                let selectedProject = projects.find(org => org.id == localStorage.getItem('last_project'));
                if (selectedProject) {
                  this.store.dispatch(new SelectProject({ projectId: selectedProject.id! }));
                }

                return new LoadAllProjectsSuccess({ projects: projects });
              }),
              catchError(err => {
                return of(new LoadAllProjectsError(err));
              })
            )
          )
        )
      )
    )
  );

  addProjectError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<AddProjectError>(ProjectActionTypes.AddProjectError),
        map(action => {
          this.errorService.showError('Projekt konnte nicht erstellt werden', action.payload.error);
          return EMPTY;
        })
      ),
    { dispatch: false }
  );

  // write new selected project id to local storage
  selectProjectEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SelectProject>(ProjectActionTypes.SelectProject),
      tap(action => {}),
      switchMap(action => {
        if (localStorage.getItem('last_project') == action.payload.projectId) {
          return [new LoadProjectCompanies(), new LoadAllProjectMembers()];
        } else {
          localStorage.setItem('last_project', action.payload.projectId);
          return [new ResetSelectedProjectState(), new LoadProjectCompanies(), new LoadAllProjectMembers()];
        }
      })
    )
  );

  // write new selected project id to local storage
  unselectProjectEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UnselectProject>(ProjectActionTypes.UnselectProject),
      map(action => {
        localStorage.removeItem('last_project');
        return new ResetSelectedProjectState();
      })
    )
  );

  resetSelectedProjectEffect = createEffect(() =>
    this.actions$.pipe(
      ofType<ResetSelectedProjectState>(ProjectActionTypes.ResetSelectedProjectState),
      mergeMap(() => [
        new ResetCompaniesState(),
        new ResetProjectMemberState(),
        new ResetEntriesState(),
        new ResetExportState()
        // new ResetImagesState()
      ])
    )
  );

  restoreLastProjectEffect = createEffect(() =>
    this.actions$.pipe(
      ofType<RestoreLastProject>(ProjectActionTypes.RestoreLastProject),
      withLatestFrom(
        this.store.pipe(select(selectLastProjectRestored)),
        this.store.pipe(select(selectSelectedOrganization))
      ),
      filter(
        ([action, lastProjectRestored, organization]) => lastProjectRestored == false && organization != undefined
      ),
      mergeMap(([action, lastProjectRestored, organization]) => {
        let projectId = localStorage.getItem('last_project');
        if (projectId != undefined && projectId.trim() != '') {
          return this.resourceService.getProjectObs(organization!, projectId);
        }
        return EMPTY;
      }),
      mergeMap((project: IProject | undefined) => {
        if (project) {
          return [new LoadProjectSuccess({ project: project }), new RestoreLastProjectSuccess({ project: project })];
        }
        return of(new RestoreLastProjectError());
      })
    )
  );

  restoreLastProjectSuccessEffect = createEffect(() =>
    this.actions$.pipe(
      ofType<RestoreLastProjectSuccess>(ProjectActionTypes.RestoreLastProjectSuccess),
      map(action => new SelectProject({ projectId: action.payload.project.id || '' }))
    )
  );
}
