import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, tap } from 'rxjs';

export interface IAuth0TokenResponse {
  access_token: string;
  id_token: string;
  expires_in: number;
  refresh_token: string;
  scope: string;
  token_type: string;
}

@Injectable({
  providedIn: 'root'
})
export abstract class TokenService {
  protected readonly _accessToken = new BehaviorSubject<string | undefined>(undefined);
  protected tokenRefreshInProgress = false;

  protected constructor() {
    this.initiate();
  }

  get accessToken(): string | undefined {
    return this._accessToken.getValue();
  }

  set accessToken(value: string | undefined) {
    if (!value) {
      this._accessToken.next(undefined);
    } else {
      this._accessToken.next(value);
    }
  }

  abstract reAuthorize(): Observable<string | undefined>;

  abstract initiate(): void;

  public getAccessToken(): Observable<string | undefined> {
    return this._accessToken.asObservable().pipe(
      tap(token => {
        if (token == undefined && !this.tokenRefreshInProgress) {
          this.reAuthorize();
        }
      })
    );
  }
}
