import { Component, Inject } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { User } from '@auth0/auth0-angular';
import { Store } from '@ngrx/store';
import { PermissionService, UserService } from 'data-access';
import { selectSelectedOrganization } from 'data-access';
import { selectSelectedProject, UnselectProject } from 'data-access';
import { combineLatest, filter, map, Observable, tap } from 'rxjs';
import {
  getMenuRoutes,
  getProjectRoutes,
  getUtilRoutes,
  IPermission,
  IRoute,
  ISubRoute,
  MAXIMUM_MOBILE_WIDTH,
  PopoverService,
  USER_KEY
} from 'shared';
import { AnnouncementsService } from '../../organizations/modules/announcements/announcements.service';

@Component({ selector: 'app-layout', template: '' })
export class LayoutComponent {
  isMenuOpen = false;
  activeRoute?: IRoute;
  activeUrl = '';
  activeSubRoute?: ISubRoute;
  uploadInProgress = false;
  uploadFailed = false;
  user?: User;
  permissions?: IPermission[];

  constructor(
    private router: Router,
    private permissionService: PermissionService,
    private popoverService: PopoverService,
    public store: Store,
    @Inject(USER_KEY) private _userService: UserService,
    private announcementService: AnnouncementsService
  ) {
    this.activeUrlObs.subscribe(url => (this.activeUrl = url));
    this.activeRouteObs.subscribe(route => (this.activeRoute = route));
    this.activeSubRouteObs.subscribe(route => (this.activeSubRoute = route));
    this._userService.getLoggedUser().subscribe(user => (this.user = user));
    this.permissionService.getPermissions().subscribe(permissions => (this.permissions = permissions));
  }

  unselectProject() {
    this.store.dispatch(new UnselectProject());
  }

  private get activeUrlObs(): Observable<string> {
    return this.router.events.pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd),
      tap(() => {
        if (window.innerWidth <= MAXIMUM_MOBILE_WIDTH) {
          this.isMenuOpen = false;
          this.popoverService.setState(true);
        }
      }),
      map((ev: Event) => {
        return (ev as NavigationEnd).urlAfterRedirects;
      })
    );
  }

  private get activeRouteObs(): Observable<IRoute | undefined> {
    return combineLatest([this.activeUrlObs, this.menuRoutesObs, this.projectRoutesObs]).pipe(
      map(([url, menuRoutes, projectRoutes]) => {
        const routes: IRoute[] = (menuRoutes ?? [])
          .concat(projectRoutes ?? [])
          .concat(getUtilRoutes())
          .filter(route => route.regEx.test(url));
        // find longest route
        return routes.reduce((acc: IRoute | undefined, route) => {
          if (!acc) {
            return route;
          }
          if (route.url.length > acc.url.length) {
            return route;
          }
          return acc;
        }, undefined);
      })
    );
  }

  private get activeSubRouteObs(): Observable<ISubRoute | undefined> {
    return combineLatest([this.activeUrlObs, this.activeRouteObs]).pipe(
      map(([activeUrl, activeRoute]) => {
        if (activeRoute) {
          return activeRoute.subRoutes.find(subRoute => subRoute.regEx.test(activeUrl));
        }
        return undefined;
      })
    );
  }

  private get projectRoutesObs(): Observable<IRoute[] | undefined> {
    return combineLatest([
      this.store.select(selectSelectedOrganization),
      this.store.select(selectSelectedProject)
    ]).pipe(map(([organization, project]) => getProjectRoutes(organization, project)));
  }

  private get menuRoutesObs(): Observable<IRoute[] | undefined> {
    return combineLatest([
      this.store.select(selectSelectedOrganization),
      this.announcementService.hasNewAnnouncement()
    ]).pipe(
      map(([org, hasNewAnnouncement]) => {
        if (org) {
          return getMenuRoutes(org, hasNewAnnouncement);
        }
        return [];
      })
    );
  }
}
