<div class="flex flex-row align-items-center mb-3 text-color-secondary text-500" *ngIf="data?.id">
  <span class="mat-outlined mat-info"></span>
  <p class="mb-0 ml-2">Diese Änderung wirkt sich auf <strong>alle Projekte</strong> aus</p>
</div>

<form *ngIf="formGroup" [formGroup]="formGroup" name="companyForm" class="mb-2">
  <div class="flex">
    <div class="field">
      <div class="p-float-label">
        <input
          type="text"
          pInputText
          formControlName="name"
          id="nameInput"
          aria-describedby="nameHelp"
          touchedDirty
          class="p-filled w-full" />
        <label for="nameInput">{{ 'name' | translate }}</label>
      </div>
      <small id="nameHelp" *ngIf="formGroup.get('name')?.invalid && formGroup.get('name')?.touched" class="p-error">{{
        'invalid-input' | translate
      }}</small>
    </div>
  </div>

  <div class="flex">
    <div class="field">
      <div class="p-float-label">
        <textarea
          pInputTextarea
          formControlName="description"
          [autoResize]="true"
          id="descriptionInput"
          class="p-filled w-full"
          aria-describedby="descriptionHelp"
          touchedDirty
          rows="1"></textarea>
        <label for="descriptionInput">{{ 'description' | translate }}</label>
      </div>
      <small
        id="descriptionHelp"
        *ngIf="formGroup.get('description')?.invalid && formGroup.get('description')?.touched"
        class="p-error"
        >{{ 'invalid-input' | translate }}</small
      >
    </div>
  </div>

  <div class="flex">
    <div class="field">
      <div class="p-float-label">
        <input
          type="text"
          pInputText
          formControlName="email"
          id="emailInput"
          aria-describedby="emailHelp"
          touchedDirty
          class="p-filled w-full" />
        <label for="emailInput">{{ 'email' | translate }}</label>
      </div>
      <small
        id="emailHelp"
        *ngIf="formGroup.get('email')?.invalid && formGroup.get('email')?.touched"
        class="p-error"
        >{{ 'invalid-input' | translate }}</small
      >
    </div>
  </div>

  <div class="flex">
    <div class="field">
      <div class="p-float-label">
        <input
          type="text"
          pInputText
          formControlName="phone"
          id="phoneInput"
          aria-describedby="phoneHelp"
          touchedDirty
          class="p-filled w-full" />
        <label for="phoneInput">{{ 'tel-nr' | translate }}</label>
      </div>
      <small
        id="phoneHelp"
        *ngIf="formGroup.get('phone')?.invalid && formGroup.get('phone')?.touched"
        class="p-error"
        >{{ 'invalid-input' | translate }}</small
      >
    </div>
  </div>
</form>
