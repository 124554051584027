import { Injectable } from '@angular/core';
import { ImageInputService } from 'shared';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WebImageInputService implements ImageInputService {
  getImageInputFiles(): Promise<Blob[]> {
    return new Promise((resolve, reject) => {
      const input = document.createElement('input');
      input.type = 'file';
      input.accept = 'image/*';
      input.multiple = true;
      input.style.display = 'none';

      input.addEventListener('change', (event: Event) => {
        const target = event.target as HTMLInputElement;
        if (target && target.files) {
          const files: Blob[] = Array.from(target.files);
          resolve(files);
        } else {
          reject(new Error('No files selected'));
        }
      });

      input.addEventListener('error', (event: Event) => {
        reject(new Error('File input error'));
      });

      document.body.appendChild(input);
      input.click();
      document.body.removeChild(input);
    });
  }
}
