import { createFeatureSelector, createSelector } from '@ngrx/store';
import { organizationMemberAdapter, OrganizationMemberState } from './organization-member.state';

export const organizationMemberState = createFeatureSelector<OrganizationMemberState>('organizationMember');

const { selectAll, selectEntities, selectIds, selectTotal } = organizationMemberAdapter.getSelectors();

export const selectOrganizationMemberById = (memberId: string) =>
  createSelector(organizationMemberState, state => state.entities[memberId]);

export const selectAllOrganizationMembersLoaded = createSelector(
  organizationMemberState,
  state => state.allMembersLoaded
);
