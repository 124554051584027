import { createFeatureSelector, createSelector } from '@ngrx/store';
import { companiesAdapter, CompaniesState } from './company.state';

export const companiesState = createFeatureSelector<CompaniesState>('companies');

const { selectAll, selectEntities, selectIds, selectTotal } = companiesAdapter.getSelectors();

export const selectCompanyById = (companyId: string) =>
  createSelector(companiesState, state => state.entities[companyId]);

export const selectAllCompanies = createSelector(companiesState, selectAll);

export const selectAllCompaniesLoaded = createSelector(companiesState, state => state.allCompaniesLoaded);

export const selectCompaniesLoading = createSelector(companiesState, state => state.loading);
