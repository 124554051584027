import { Action } from '@ngrx/store';
import { IPdf, PdfStatusEnum } from 'shared';

export enum ExportActionTypes {
  AddPdfExports = '[PdfExports] Add PdfExports',
  AddPdfExport = '[PdfExports] Add PdfExport',
  UpdatePdfExport = '[PdfExport] Update PdfExport',
  UpdatePdfGenerationProgress = '[PdfExport] Update PdfExport GenerationProgress',
  ResetExportState = '[PdfExport] Reset State',
  RemovePdfExport = '[PdfExport] Remove PdfExport'
}

export class AddPdfExports implements Action {
  readonly type = ExportActionTypes.AddPdfExports;
  constructor(public pdfs: IPdf[]) {}
}

export class AddPdfExport implements Action {
  readonly type = ExportActionTypes.AddPdfExport;
  constructor(public pdf: IPdf) {}
}

export class RemovePdfExport implements Action {
  readonly type = ExportActionTypes.RemovePdfExport;
  constructor(public id: string) {}
}

export class UpdatePdfExport implements Action {
  readonly type = ExportActionTypes.UpdatePdfExport;
  constructor(public pdf: IPdf) {}
}
export class UpdatePdfGenerationProgress implements Action {
  readonly type = ExportActionTypes.UpdatePdfGenerationProgress;
  constructor(public id: string, public progress: PdfStatusEnum) {}
}

export class ResetExportState implements Action {
  readonly type = ExportActionTypes.ResetExportState;
}

export type ExportActions =
  | AddPdfExports
  | AddPdfExport
  | UpdatePdfExport
  | ResetExportState
  | UpdatePdfGenerationProgress
  | RemovePdfExport;
