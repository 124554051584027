import { Pipe, PipeTransform } from '@angular/core';
import { IPdfGenerationProgress } from '../interfaces/pdf-generation-message.interface';

@Pipe({
  name: 'castPdfProgress'
})
export class CastPdfProgressPipe implements PipeTransform {
  transform(value: object): IPdfGenerationProgress | undefined {
    if (value && 'stage' in value && 'message' in value) {
      return value as IPdfGenerationProgress;
    }
    return undefined;
  }
}
