import { Component, Inject, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  DiaryEntryType,
  IImageEntry,
  IImageEntryRequest,
  IProject,
  IUser,
  RESOURCE_KEY,
  USER_KEY,
  validateWordCount
} from 'shared';
import { ErrorService, selectEntriesById, selectEntryById, UserService } from 'data-access';
import { FormControl, FormGroup } from '@angular/forms';
import { selectSelectedProject } from 'data-access';
import { selectOrganizationMemberById, UpdateEntry } from 'data-access';
import { DatePipe } from '@angular/common';
import { Subscription } from 'rxjs';
import { ResourceService } from 'data-access';

@Component({
  selector: 'image-viewer-description-component',
  templateUrl: './image-viewer-description.component.html',
  styleUrls: ['./image-viewer-description.component.scss']
})
export class ImageViewerDescriptionComponent implements OnInit, OnChanges {
  @Input() visible: boolean = false;
  @Input() imageId!: string;

  image!: IImageEntry;

  editFormGroup: FormGroup = new FormGroup({
    description: new FormControl('', [validateWordCount])
  });

  saveInProgress = false;
  protected editMode = false;

  loggedUser?: IUser;
  project?: IProject;
  private imageSelectorSubscription: Subscription = new Subscription();

  constructor(
    private store: Store,
    @Inject(USER_KEY) private userService: UserService,
    @Inject(RESOURCE_KEY) private resourceService: ResourceService,
    private errorService: ErrorService,
    private datePipe: DatePipe
  ) {
    this.userService.getLoggedUser().subscribe(user => (this.loggedUser = user));
  }

  ngOnInit(): void {
    this.store.select(selectSelectedProject).subscribe(project => (this.project = project));
    this.loadImageEntry(this.imageId);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['imageId'] && !changes['imageId'].isFirstChange()) {
      this.loadImageEntry(changes['imageId'].currentValue);
      this.editMode = false;
    }
  }

  private loadImageEntry(imageId: string): void {
    this.imageSelectorSubscription.unsubscribe(); // Unsubscribe from previous observable
    this.imageSelectorSubscription = this.store.select(selectEntryById(imageId)).subscribe(image => {
      this.image = image as IImageEntry;
      this.editFormGroup.get('description')?.setValue(this.image.description);
    });
  }

  enableEditMode() {
    this.editMode = true;
  }

  get editable() {
    if (this.loggedUser) {
      return this.image.creator?.id == this.loggedUser!.id;
    }
    return false;
  }

  saveDescription() {
    this.saveInProgress = true;
    const body: IImageEntryRequest = {
      date: this.datePipe.transform(this.image.date, 'yyyy-MM-dd')!,
      type: DiaryEntryType.IMAGE,
      description: this.editFormGroup.get('description')?.value
    };

    this.resourceService
      .patchAbstractEntry<IImageEntryRequest, IImageEntry>(this.project!.id!, this.image.id!, body)
      .subscribe({
        next: value => {
          this.store.dispatch(new UpdateEntry(value));
          this.saveInProgress = false;
          this.editMode = false;
        },
        error: err => {
          this.errorService.showError('Beschreibung konnte nicht aktualisiert werden', err);
          this.saveInProgress = false;
        }
      });
  }
}
