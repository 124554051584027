import { createFeatureSelector, createSelector, State } from '@ngrx/store';
import { DiaryState } from './diary.state';
import { selectAllEntries } from 'data-access';
import { DiaryObjectService } from 'data-access';
import { Injectable } from '@angular/core';

export const diaryState = createFeatureSelector<DiaryState>('diary');

export const selectDiaryTopDate = createSelector(diaryState, state => state.topDate);

export const selectDiaryBottomDate = createSelector(diaryState, state => state.bottomDate);

// export const selectAllDiaryObjects = createSelector(
//   selectDiaryTopDate,
//   selectDiaryBottomDate,
//   selectAllEntries,
//   (topDate, bottomDate, entries) => {
//     let map = Map();
//     entries.forEach(entry => {
//       const dateStr = this.datePipe.transform(entry.date, 'yyyy-MM-dd')!;
//       const existingEntries = this.entries.get(dateStr) || [];
//       // check if entry is already in list (edge case)
//       if (!existingEntries.some(e => e.id == entry.id)) {
//         existingEntries.push(entry);
//       }
//       this.entries.set(dateStr, existingEntries);
//     });
//     return DiaryObjectService.buildDiaryObjects(entries, topDate, bottomDate);
//   }
// );
