<div class="page-content">
  <h1 style="margin-bottom: 0.2rem">{{ 'add-member' | translate }}</h1>
  <p style="margin-bottom: 1rem">{{ 'new-members-invited-via-email' | translate }}</p>

  <error-display-component *ngIf="!loading && hasError" (onRetry)="loadPageData()"></error-display-component>

  <mat-spinner *ngIf="loading && !hasError" [diameter]="32" class="mt-2 ml-2"></mat-spinner>

  <div *ngIf="!loading && !hasError" class="flex flex-column">
    <div class="field mt-4">
      <div class="p-float-label">
        <input
          pInputText
          [formControl]="inviteFormControl"
          id="emailInputInvites"
          class="p-filled w-full"
          touchedDirty />
        <label for="emailInputInvites">{{ 'email' | translate }}</label>
      </div>
      <small id="weatherHelp" *ngIf="inviteFormControl.invalid && inviteFormControl.touched" class="p-error">
        {{ 'invalid-input' | translate }}
      </small>
    </div>
    <button
      pButton
      class="p-button-raised p-button-sm mt-3 ml-auto mr-0"
      [loading]="createInProgress"
      (click)="createInvite()"
      [label]="'invite' | translate"></button>

    <h1 class="mt-4">{{ 'active-invitations' | translate }}</h1>

    <div class="table-responsive">
      <table
        aria-describedby="member invitation table"
        mat-table
        [dataSource]="invites"
        class="w-full"
        id="invitationTable"
        #invitationTable>
        <ng-container matColumnDef="invitee">
          <th mat-header-cell *matHeaderCellDef>{{ 'email' | translate }}</th>
          <td mat-cell *matCellDef="let invite">
            {{ invite.invitee.email }}
          </td>
        </ng-container>

        <ng-container matColumnDef="inviter">
          <th mat-header-cell *matHeaderCellDef>{{ 'invited-by' | translate }}</th>
          <td mat-cell *matCellDef="let invite">
            {{ invite.inviter.name }}
          </td>
        </ng-container>

        <ng-container matColumnDef="expiration">
          <th mat-header-cell *matHeaderCellDef>{{ 'date-of-expiry' | translate }}</th>
          <td mat-cell *matCellDef="let invite">
            {{ invite.expiresAt }}
          </td>
        </ng-container>

        <ng-container matColumnDef="control">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let invite">
            <button
              mat-icon-button
              color="warn"
              confirmTitle="Einladung widerrufen?"
              confirmMessage="Die bereits versendete Einladung zur {{
                organization?.displayName
              }} kann anschließend nicht mehr akzeptiert werden"
              confirmColor="warn"
              confirmLabel="Widerrufen"
              (onConfirmed)="revokeInvite(invite)">
              <i-feather name="trash-2"></i-feather>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="['invitee', 'inviter', 'expiration', 'control']"></tr>
        <tr mat-row *matRowDef="let row; columns: ['invitee', 'inviter', 'expiration', 'control']"></tr>
      </table>
    </div>

    <h3 *ngIf="!invites?.length" class="mt-3 ml-4">{{ 'no-active-invitations' | translate }}</h3>
  </div>
</div>
