import { Injectable } from '@angular/core';
import { FileDownloadService } from 'shared';

@Injectable({
  providedIn: 'root'
})
export class WebFileDownloadService extends FileDownloadService {
  interactionHandle(blob: Blob, filename: string): void {
    const url = window.URL.createObjectURL(blob);
    const anchor = document.createElement('a');
    anchor.download = '';
    anchor.href = url;
    anchor.download = filename;
    anchor.click();
  }
}
