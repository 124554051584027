<div class="field" style="flex-basis: 80%" *ngIf="control">
  <div [class.p-inputgroup]="icon" class="flex">
    <span class="p-float-label">
      <span class="p-inputgroup-addon" *ngIf="icon" [class.error]="control.invalid && control.touched">
        <i class="pi" [ngClass]="icon"></i>
      </span>

      <p-multiSelect
        appendTo="body"
        #multiSelect
        id="multiSelect"
        (onFilter)="searchStr$.next($event.filter)"
        (onPanelShow)="initData()"
        [filterMatchMode]="'isNot'"
        [filter]="filter"
        [lazy]="true"
        class="p-inputwrapper-filled"
        touchedDirty
        [placeholder]="placeHolder"
        [options]="options"
        [style]="{ width: '100%' }"
        (onClear)="multiSelect.show()"
        [formControl]="control"
        [showClear]="true"
        (onChange)="onSelectionChange.emit($event)"
        [virtualScroll]="true"
        [virtualScrollItemSize]="38">
        <ng-template let-option pTemplate="item">
          <ng-container
            [ngTemplateOutlet]="option.isLoader ? loaderItemTemplate : itemTemplate || baseItemTemplate"
            [ngTemplateOutletContext]="{ $implicit: option.value }"></ng-container>
        </ng-template>

        <ng-template let-option pTemplate="selectedItems">
          <span *ngIf="!hasItemSelected()">{{ placeHolder }}</span>
          <ng-container
            [ngTemplateOutlet]="selectedItemsTemplate || baseSelectedItemTemplate"
            [ngTemplateOutletContext]="{ $implicit: option }"></ng-container>
        </ng-template>
      </p-multiSelect>
      <label for="multiSelect">{{ label }}</label>
    </span>
  </div>
  <!--  <small class="p-error" *ngIf="control?.invalid && control?.touched">-->
  <!--    {{ 'invalid-argument' | translate }}-->
  <!--  </small>-->
</div>

<ng-template #loaderItemTemplate>
  <mat-spinner style="margin-left: 0.6rem" [diameter]="26"></mat-spinner>
</ng-template>

<ng-template #baseItemTemplate let-option>
  <div class="flex align-items-center">
    <span>{{ valueLabel ? option[valueLabel] : (option | json) }}</span>
  </div>
</ng-template>

<ng-template #baseSelectedItemTemplate let-selectedItems>
  <div class="flex flex-row gap-2 align-items-center">
    <span *ngFor="let item of selectedItems" class="listing-span">{{ valueLabel ? item[valueLabel] : item }}</span>
  </div>
</ng-template>
