import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-organizations',
  templateUrl: './organizations.page.html',
  styleUrls: ['./organizations.page.scss']
})
export class OrganizationsPage implements OnInit {
  constructor() {}

  ngOnInit() {}
}
