<div class="dialog-container">
  <div class="dialog-header">
    <ng-container *ngTemplateOutlet="headerTemplate"></ng-container>
    <ng-container [ngTemplateOutlet]="confirmSuccessMessage" *ngIf="confirmSuccess && isMobile"></ng-container>
    <ng-container [ngTemplateOutlet]="confirmErrorMessage" *ngIf="confirmError && isMobile"></ng-container>
  </div>
  <div class="dialog-content" appScrollablePadding>
    <ng-container [ngTemplateOutlet]="loadingTemplate" *ngIf="loadingInProgress"></ng-container>
    <ng-container [ngTemplateOutlet]="loadingErrorTemplate" *ngIf="loadingError && !loadingInProgress"></ng-container>
    <div [class.hidden]="loadingInProgress || loadingError">
      <ng-container #dialogContainer></ng-container>
    </div>
  </div>
  <div class="dialog-footer">
    <ng-container [ngTemplateOutlet]="confirmSuccessMessage" *ngIf="confirmSuccess && !isMobile"></ng-container>
    <ng-container [ngTemplateOutlet]="confirmErrorMessage" *ngIf="confirmError && !isMobile"></ng-container>
    <ng-container *ngTemplateOutlet="footerTemplate"></ng-container>
  </div>
</div>

<ng-template #loadingTemplate>
  <p-progressSpinner class="mb-2 ml-3"></p-progressSpinner>
</ng-template>

<ng-template #loadingErrorTemplate>
  <app-message
    *ngIf="loadingError"
    [detail]="config?.loadingErrorMessage ?? 'Daten konnten nicht geladen werden'"
    (close)="clearLoadingError()"></app-message>
</ng-template>

<ng-template #headerTemplate [ngSwitch]="isMobile">
  <ng-container [ngTemplateOutlet]="regularHeaderTemplate" *ngSwitchCase="false"></ng-container>
  <ng-container [ngTemplateOutlet]="mobileHeaderTemplate" *ngSwitchCase="true"></ng-container>
</ng-template>

<ng-template #mobileHeaderTemplate>
  <div class="flex flex-row justify-content-between align-items-center">
    <div class="flex flex-row align-items-center gap-1">
      <ng-container [ngTemplateOutlet]="cancelTemplate" *ngIf="!config?.hideCancelButton"></ng-container>
      <h2>{{ config?.header }}</h2>
    </div>
    <ng-container [ngTemplateOutlet]="confirmTemplate" *ngIf="!config?.hideConfirmButton"></ng-container>
  </div>
</ng-template>

<ng-template #regularHeaderTemplate>
  <h2>{{ config?.header }}</h2>
</ng-template>

<ng-template #footerTemplate [ngSwitch]="isMobile">
  <div class="flex flex-row justify-content-end gap-2">
    <ng-container [ngTemplateOutlet]="regularFooterTemplate" *ngSwitchCase="false"></ng-container>
    <ng-container [ngTemplateOutlet]="mobileFooterTemplate" *ngSwitchCase="true"></ng-container>
  </div>
</ng-template>

<ng-template #regularFooterTemplate>
  <ng-container [ngTemplateOutlet]="cancelTemplate" *ngIf="!config?.hideCancelButton"></ng-container>
  <ng-container [ngTemplateOutlet]="confirmTemplate" *ngIf="!config?.hideConfirmButton"></ng-container>
</ng-template>

<ng-template #mobileFooterTemplate></ng-template>

<ng-template #cancelTemplate [ngSwitch]="isMobile">
  <ng-container [ngTemplateOutlet]="regularCancelTemplate" *ngSwitchCase="false"></ng-container>
  <ng-container [ngTemplateOutlet]="mobileCancelTemplate" *ngSwitchCase="true"></ng-container>
</ng-template>

<ng-template #mobileCancelTemplate>
  <button
    class="p-button-text p-button-plain mr-1"
    [ngClass]="config?.cancelButtonClass"
    pButton
    type="button"
    [icon]="cancelIcon"
    (click)="close()"
    [disabled]="confirmInProgress"></button>
</ng-template>

<ng-template #regularCancelTemplate>
  <button
    pButton
    [label]="config?.cancelLabel ?? 'Abbrechen'"
    (click)="close()"
    [disabled]="confirmInProgress"
    [icon]="config?.showConfirmIcon ? confirmIcon : ''"
    class="p-button-text p-button-sm"
    [ngClass]="config?.cancelButtonClass"></button>
</ng-template>

<ng-template #confirmTemplate [ngSwitch]="isMobile">
  <ng-container [ngTemplateOutlet]="regularConfirmTemplate" *ngSwitchCase="false"></ng-container>
  <ng-container [ngTemplateOutlet]="mobileConfirmTemplate" *ngSwitchCase="true"></ng-container>
</ng-template>

<ng-template #mobileConfirmTemplate>
  <button
    pButton
    [ngClass]="config?.confirmButtonClass"
    loadingIcon="pi pi-spin pi-spinner"
    [icon]="confirmIcon"
    (click)="confirm()"
    [loading]="confirmInProgress"></button>
</ng-template>

<ng-template #regularConfirmTemplate>
  <button
    pButton
    class="p-button-sm"
    [ngClass]="config?.confirmButtonClass"
    [label]="config?.confirmLabel ?? 'Bestätigen'"
    [icon]="config?.showConfirmIcon ? confirmIcon : ''"
    (click)="confirm()"
    loadingIcon="pi pi-spin pi-spinner"
    [loading]="confirmInProgress"></button>
</ng-template>

<ng-template #confirmSuccessMessage>
  <app-message
    type="success"
    [detail]="config?.confirmSuccessMessage ?? 'Aktion erfolgreich ausgeführt'"
    (close)="clearConfirmSuccess()"></app-message>
</ng-template>

<ng-template #confirmErrorMessage>
  <app-message
    *ngIf="confirmError"
    [detail]="config?.confirmErrorMessage ?? 'Aktion konnte nicht abgeschlossen werden'"
    (close)="clearConfirmError()"></app-message>
</ng-template>
