import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { ResourceService, UpdateProject } from 'data-access';
import { IProject, RESOURCE_KEY, FormDialogComponent, ComponentConfig, IPatchProjectRequest } from 'shared';
import { Observable } from 'rxjs';
import { DatePipe } from '@angular/common';

export interface ProjectDetailsForm {
  name: FormControl<string | null | undefined>;
  startDate: FormControl<Date | null | undefined>;
  endDate: FormControl<Date | null | undefined>;
  commissioningAuthority: FormControl<string | null | undefined>;
  supervisoryAuthority: FormControl<string | null | undefined>;
  constructionTask: FormControl<string | null | undefined>;
  number: FormControl<string | null | undefined>;
  description: FormControl<string | null | undefined>;
}

export interface ProjectDetailsDialogData {
  project?: IProject;
}

@Component({
  selector: 'app-project-edit-dialog',
  templateUrl: './project-edit-dialog.component.html',
  styleUrls: ['./project-edit-dialog.component.scss']
})
export class ProjectEditDialogComponent extends FormDialogComponent<
  ProjectDetailsDialogData,
  IProject,
  ProjectDetailsForm
> {
  get project(): IProject | undefined {
    return this.data?.project;
  }

  constructor(
    private fb: FormBuilder,
    @Inject(RESOURCE_KEY) private resourceService: ResourceService,
    private store: Store,
    private datePipe: DatePipe
  ) {
    super();
  }

  buildFormGroup(): FormGroup<ProjectDetailsForm> {
    const projectFormGroup = this.fb.group({
      name: new FormControl<string | undefined>('', [Validators.required]),
      startDate: new FormControl<Date | undefined>(undefined, [Validators.required]),
      endDate: new FormControl<Date | undefined>(undefined, [Validators.required]),
      commissioningAuthority: new FormControl<string | undefined>('', []),
      supervisoryAuthority: new FormControl<string | undefined>('', []),
      constructionTask: new FormControl<string | undefined>('', []),
      number: new FormControl<string | undefined>('', []),
      description: new FormControl<string | undefined>('', [])
    });
    projectFormGroup?.controls.name.setValue(this.project?.name);
    projectFormGroup?.controls.startDate.setValue(this.project?.startDate);
    projectFormGroup?.controls.endDate.setValue(this.project?.endDate);
    projectFormGroup?.controls.commissioningAuthority.setValue(this.project?.commissioningAuthority);
    projectFormGroup?.controls.supervisoryAuthority.setValue(this.project?.supervisoryAuthority);
    projectFormGroup?.controls.constructionTask.setValue(this.project?.constructionTask);
    projectFormGroup?.controls.number.setValue(this.project?.number);
    projectFormGroup?.controls.description.setValue(this.project?.description);
    return projectFormGroup;
  }

  get config(): ComponentConfig {
    return {
      header: 'Projektdetails bearbeiten',
      confirmSuccessMessage: 'Projektdetails gespeichert',
      confirmErrorMessage: 'Projektdetails konnten nicht gespeichert werden',
      confirmLabel: 'Speichern'
    };
  }
  getSaveObs(): Observable<IProject> {
    const patchProjectData: IPatchProjectRequest = {
      name: this.formGroup?.controls.name.value!,
      startDate: this.datePipe.transform(this.formGroup?.controls.startDate.value, 'yyyy-MM-dd')!,
      endDate: this.datePipe.transform(this.formGroup?.controls.endDate.value, 'yyyy-MM-dd')!,
      commissioningAuthority: this.formGroup?.controls.commissioningAuthority.value ?? '',
      supervisoryAuthority: this.formGroup?.controls.supervisoryAuthority.value ?? '',
      constructionTask: this.formGroup?.controls.constructionTask.value ?? '',
      number: this.formGroup?.controls.number.value ?? '',
      description: this.formGroup?.controls.description.value ?? ''
    };

    return this.resourceService.patchProject(this.project!.id, patchProjectData);
  }

  override confirmSuccessHandle(project: IProject) {
    this.store.dispatch(new UpdateProject({ project: project }));
    super.confirmSuccessHandle(project);
  }
}
