import { Inject, Injectable } from '@angular/core';
import { IAbstractEntry, IDiaryObject, IEntryInterface, IImage, IProject, RESOURCE_KEY } from 'shared';
import { DatePipe } from '@angular/common';
import { Store } from '@ngrx/store';
import { selectSelectedProject } from 'data-access';
import { BehaviorSubject, Observable } from 'rxjs';
import { selectAllParentEntries } from 'data-access';
import { HttpErrorResponse } from '@angular/common/http';
import { ResourceService } from '../classes/resource-service.class';

@Injectable({
  providedIn: 'root'
})
export class DiaryObjectService {
  private project?: IProject;
  private diaryObjects: BehaviorSubject<IDiaryObject[]> = new BehaviorSubject<IDiaryObject[]>([]);
  topDate!: Date;
  bottomDate!: Date;

  topLoading: BehaviorSubject<boolean> = new BehaviorSubject(false);
  topLoaderActive: BehaviorSubject<boolean> = new BehaviorSubject(true);
  topError: BehaviorSubject<HttpErrorResponse | undefined> = new BehaviorSubject<HttpErrorResponse | undefined>(
    undefined
  );
  bottomLoading: BehaviorSubject<boolean> = new BehaviorSubject(false);
  bottomError: BehaviorSubject<HttpErrorResponse | undefined> = new BehaviorSubject<HttpErrorResponse | undefined>(
    undefined
  );
  bottomLoaderActive: BehaviorSubject<boolean> = new BehaviorSubject(true);

  upperPageCount = 0;
  lowerPageCount = 0;

  public getDiaryObjects(): Observable<IDiaryObject[]> {
    return this.diaryObjects.asObservable();
  }

  constructor(
    private datePipe: DatePipe,
    private store: Store,
    @Inject(RESOURCE_KEY) protected resourceService: ResourceService
  ) {
    this.store.select(selectSelectedProject).subscribe(project => (this.project = project));
    this.store.select(selectAllParentEntries).subscribe(entries => {
      let objects: IDiaryObject[] = [];
      objects = this.buildDiaryObjects(this.entriesToMap(entries), this.topDate, this.bottomDate);
      this.diaryObjects.next(objects);
    });
  }

  private entriesToMap(entries: IAbstractEntry[]): Map<string, IAbstractEntry[]> {
    return entries.reduce((map, entry) => {
      const dateStr = this.datePipe.transform(entry.date, 'yyyy-MM-dd')!;
      const existingEntries = map.get(dateStr) || [];
      existingEntries.push(entry);
      map.set(dateStr, existingEntries);
      return map;
    }, new Map<string, IAbstractEntry[]>());
  }

  // public buildDiaryObjects(
  //   entries: IEntryInterface[],
  //   images: IImage[],
  //   startDate?: Date,
  //   endDate?: Date
  // ): IDiaryObject[] {
  //   if (startDate && endDate) {
  //     const allDates: Date[] = DiaryObjectService.getDateArray(startDate, endDate);
  //     let objects = this.generateRecursiveDiaryObjects(allDates, entries, images);
  //     objects = DiaryObjectService.removeAllFutureElements(objects);
  //     objects = this.verifyTodayAndYesterday(objects);
  //     objects = this.cleanUpEmptyDiaryObjects(objects);
  //     return objects;
  //   }
  //
  //   return [];
  // }

  private static getDateArray(start: Date, end: Date): Date[] {
    if (start && end) {
      const dates: Date[] = [];
      const itStartDate: Date = new Date(start);
      const itEndDate: Date = new Date(end);
      itEndDate.setDate(itEndDate.getDate() - 1);
      // iterate over all days from today backwards
      for (let itDate = new Date(itStartDate); !compareDates(itDate, itEndDate); itDate.setDate(itDate.getDate() - 1)) {
        dates.push(new Date(itDate));
      }
      return dates;
    } else {
      return [];
    }
  }

  /**
   * makes sure today and yesterday got an separate diaryDate
   * in case it is not existing add an empty one
   * @param DiaryObjects
   */
  private verifyTodayAndYesterday(diaryObjects: IDiaryObject[]): IDiaryObject[] {
    const today = new Date();
    const todaysIndex = diaryObjects.findIndex(function (object) {
      return compareDates(object.date, today);
    });
    if (todaysIndex >= 0 && diaryObjects[todaysIndex].type === 'empty') {
      diaryObjects[todaysIndex] = {
        type: 'diaryDate',
        date: today,
        dateStr: this.datePipe.transform(today, 'yyyy-MM-dd')!,
        diaryDate: {
          date: today,
          entries: [],
          // images: [],
          isEmpty: true,
          isDateInterval: false,
          // entryIds: [],
          isLoaded: false
        },
        interruption: undefined,
        dateSpan: undefined
      };
    }
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    const yesterdaysIndex = diaryObjects.findIndex(function (object) {
      return compareDates(object.date, yesterday);
    });
    if (yesterdaysIndex >= 0 && diaryObjects[yesterdaysIndex].type === 'empty') {
      diaryObjects[yesterdaysIndex] = {
        type: 'diaryDate',
        date: yesterday,
        dateStr: this.datePipe.transform(yesterday, 'yyyy-MM-dd')!,
        diaryDate: {
          date: yesterday,
          entries: [],
          // images: [],
          isEmpty: true,
          isDateInterval: false,
          // entryIds: [],
          isLoaded: false
        },
        interruption: undefined,
        dateSpan: undefined
      };
    }
    return diaryObjects;
  }

  /**
   * in each recursion step another diaryObject is being added
   * @param allDates
   * @param entries
   * @param images
   * @returns
   */
  private generateRecursiveDiaryObjects(
    allDates: Date[],
    entries: IEntryInterface[],
    images: IImage[]
  ): IDiaryObject[] {
    // break condition for recursion
    if (!allDates.length) {
      return [];
    }

    const [dayImages, leftImages]: [IImage[], IImage[]] = images.reduce(
      ([pass, fail]: [IImage[], IImage[]], elem) => {
        return compareDates(allDates[0], elem.date) ? [[...pass, elem], fail] : [pass, [...fail, elem]];
      },
      [[], []]
    );

    const [dayEntries, leftEntries]: [IEntryInterface[], IEntryInterface[]] = entries.reduce(
      ([pass, fail]: [IEntryInterface[], IEntryInterface[]], elem) => {
        return compareDates(allDates[0], new Date(elem.date)) ? [[...pass, elem], fail] : [pass, [...fail, elem]];
      },
      [[], []]
    );

    const newObject: IDiaryObject = {
      type: dayImages.length > 0 || dayEntries.length > 0 ? 'diaryDate' : 'empty',
      date: allDates[0],
      dateStr: this.datePipe.transform(allDates[0], 'yyyy-MM-dd')!,
      diaryDate: {
        date: allDates[0],
        entries: dayEntries,
        // images: dayImages,
        isEmpty: true,
        isDateInterval: false,
        // entryIds: [],
        isLoaded: false
      },
      interruption: undefined,
      dateSpan: undefined
    };
    allDates.splice(0, 1);
    // next recursion step
    return [newObject].concat(this.generateRecursiveDiaryObjects(allDates, leftEntries, leftImages));
  }

  /**
   * removes all empty diaryObjects that are in the future
   * @param diaryObjects
   */
  private static removeAllFutureElements(diaryObjects: IDiaryObject[]): IDiaryObject[] {
    const today = new Date();
    diaryObjects = diaryObjects.filter(function (object) {
      return object.date <= today || object.type !== 'empty';
    });
    return diaryObjects;
  }

  /**
   * recursive function that clean up the emptyDiaryObjects
   * if they are "single" just replace with empty diaryDate
   * if they are in groups -> group them as an empty dateSpan
   */
  private cleanUpEmptyDiaryObjects(diaryObjects: IDiaryObject[]): IDiaryObject[] {
    // check if there are empty objects left
    for (let i = 0; i < diaryObjects.length; i++) {
      if (diaryObjects[i].type === 'empty') {
        // check how many empty objects are following
        let emptyObjectsCount = 1;
        while (emptyObjectsCount + i < diaryObjects.length && diaryObjects[i + emptyObjectsCount].type === 'empty') {
          emptyObjectsCount++;
        }
        // insert either datespan or empty diaryDate
        if (emptyObjectsCount === 1) {
          diaryObjects[i] = {
            type: 'diaryDate',
            date: diaryObjects[i].date,
            dateStr: this.datePipe.transform(diaryObjects[i].date, 'yyyy-MM-dd')!,
            diaryDate: {
              date: diaryObjects[i].date,
              entries: [],
              // images: [],
              isEmpty: true,
              isDateInterval: false,
              // entryIds: [],
              isLoaded: true
            },
            interruption: undefined,
            dateSpan: undefined
          };
        } else {
          // insert dateSpan and clear the following empty objects
          const spanStartDate = new Date(diaryObjects[i].date);
          spanStartDate.setDate(spanStartDate.getDate() - emptyObjectsCount + 1);
          diaryObjects[i] = {
            type: 'dateSpan',
            date: diaryObjects[i].date,
            dateStr: this.datePipe.transform(diaryObjects[i].date, 'yyyy-MM-dd')!,
            diaryDate: undefined,
            interruption: undefined,
            dateSpan: {
              endDate: diaryObjects[i].date,
              startDate: spanStartDate
            }
          };
          diaryObjects.splice(i + 1, emptyObjectsCount - 1);
        }

        return this.cleanUpEmptyDiaryObjects(diaryObjects);
      }
    }
    // recursion break if there are no more empty objects
    return diaryObjects;
  }

  /////////////////////////////////////////////////////
  /////////////////////////////////////////////////////
  /////////////////////////////////////////////////////
  /////////////////////////////////////////////////////

  // the start date will be included in the DiaryObjects List
  public buildDiaryObjectsNew(
    entries: IEntryInterface[],
    startDate: Date,
    endDate: Date,
    direction: BuildDirection
  ): IDiaryObject[] {
    // fill dateMap with dates from startDate till date of last entry in list
    let dateMap: Map<string, IEntryInterface[]> = new Map<string, IEntryInterface[]>();
    const dateList: Date[] = this.buildEmptyDateList(entries, startDate, direction);
    dateMap = entries.reduce((map, entry) => {
      const date = this.datePipe.transform(entry.date, this.DATE_FORMAT);
      if (date) {
        const existingEntries = map.get(date) || [];
        existingEntries.push(entry);
        map.set(date, existingEntries);
      }
      return map;
    }, new Map<string, IEntryInterface[]>());
    let objects = this.generateRecursiveDiaryObjectsNew(dateList, dateMap);
    objects = DiaryObjectService.removeAllFutureElements(objects);
    objects = this.verifyTodayAndYesterday(objects);
    objects = this.cleanUpEmptyDiaryObjects(objects);
    return objects;
  }

  DATE_FORMAT = 'yyyy-MM-dd';

  buildDiaryObjects(entries: Map<string, IAbstractEntry[]>, startDate: Date, endDate: Date) {
    const dateList: Date[] = this.buildEmptyDateListPlain(startDate, endDate);
    let objects = this.generateRecursiveDiaryObjectsNew(dateList, entries);
    // objects = DiaryObjectService.removeAllFutureElements(objects);
    objects = this.verifyTodayAndYesterday(objects);
    objects = this.cleanUpEmptyDiaryObjects(objects);
    return objects;
  }

  buildEmptyDateListPlain(upperDate: Date, lowerDate: Date) {
    const dates: Date[] = [];
    const currentDate = new Date(upperDate);
    // Iterate through each date until the end date
    while (currentDate >= lowerDate) {
      // Add the current date to the list
      dates.push(new Date(currentDate));
      // Move to the day before
      currentDate.setDate(currentDate.getDate() - 1);
    }
    return dates;
  }

  private buildEmptyDateList(entries: IEntryInterface[], date: Date, direction: BuildDirection): Date[] {
    const dates: Date[] = [];

    if (direction == BuildDirection.UP) {
      const start = date;
      const end = entries[entries.length - 1].date;
      const currentDate = new Date(start);
      // Iterate through each date until the end date
      while (currentDate <= end) {
        // Add the current date to the list
        dates.push(new Date(currentDate));
        // Move to the next day
        currentDate.setDate(currentDate.getDate() + 1);
      }
    } else {
      const start = date;
      const end = entries[entries.length - 1].date;
      const currentDate = new Date(start);
      // Iterate through each date until the end date
      while (currentDate >= end) {
        // Add the current date to the list
        dates.push(new Date(currentDate));
        // Move to the day before
        currentDate.setDate(currentDate.getDate() - 1);
      }
    }
    return dates;
  }

  generateRecursiveDiaryObjectsNew(allDates: Date[], entries: Map<string, IAbstractEntry[]>): IDiaryObject[] {
    // break condition for recursion
    if (!allDates.length) {
      return [];
    }

    const dateStr = this.datePipe.transform(allDates[0], this.DATE_FORMAT) || undefined;

    const newObject: IDiaryObject = {
      type: dateStr && entries.has(dateStr) ? 'diaryDate' : 'empty',
      date: allDates[0],
      dateStr: this.datePipe.transform(allDates[0], 'yyyy-MM-dd')!,

      diaryDate: {
        date: allDates[0],
        entries: (dateStr ? entries.get(dateStr) : []) ?? [],
        isEmpty: true,
        isDateInterval: false,
        // entryIds: [],
        isLoaded: false
      },
      interruption: undefined,
      dateSpan: undefined
    };
    allDates.splice(0, 1);

    // next recursion step
    return [newObject].concat(this.generateRecursiveDiaryObjectsNew(allDates, entries));
  }
}

function compareDates(firstDate: Date, secondDate: Date): boolean {
  return firstDate?.toLocaleDateString() === secondDate?.toLocaleDateString();
}

export enum BuildDirection {
  UP,
  DOWN
}
