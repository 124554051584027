import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors } from '@angular/forms';

@Directive({
  selector: '[atLeastOne]',
  providers: [{ provide: NG_VALIDATORS, useExisting: AtLeastOneValidator, multi: true }]
})
export class AtLeastOneValidator {
  constructor() {}

  validate(control: AbstractControl): ValidationErrors | null {
    if (control.value?.length < 1) {
      return { invalidCompany: null };
    } else {
      return null;
    }
  }
}
