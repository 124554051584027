import { Component, Inject, Input, OnInit } from '@angular/core';
import {
  FileDownloadService,
  IPdf,
  IPdfGenerationProgress,
  IPdfTemplate,
  IProject,
  PdfStatusEnum,
  RESOURCE_KEY
} from 'shared';
import {
  ErrorService,
  PdfService,
  ResourceService,
  selectPdfById,
  selectSelectedProject,
  selectTemplatePdfByTemplateId
} from 'data-access';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { BaseProjectExportItemComponent } from '../base-project-export-item/base-project-export-item.component';

@Component({
  selector: 'app-project-template-export-item',
  templateUrl: './../base-project-export-item/base-project-export-item.component.html',
  styleUrls: ['./../base-project-export-item/base-project-export-item.component.scss']
})
export class ProjectExportTemplateItemComponent extends BaseProjectExportItemComponent implements OnInit {
  @Input() template?: IPdfTemplate;

  constructor(
    @Inject(RESOURCE_KEY) protected override resourceService: ResourceService,
    protected override store: Store,
    protected override pdfService: PdfService,
    protected override errorService: ErrorService,
    protected override fileDownloadService: FileDownloadService
  ) {
    super(store, pdfService, resourceService, errorService, fileDownloadService);
  }

  refreshObs() {
    return this.resourceService.generateTemplatePdf(this.project!.id, this.template!.id);
  }

  get description(): string | undefined {
    return this.template?.description;
  }

  pdfSelector(): Observable<IPdf | undefined> {
    return this.store.select(selectTemplatePdfByTemplateId(this.template!.id));
  }

  get title(): string | undefined {
    return this.template?.name;
  }
}
