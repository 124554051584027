import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { IOrganization } from 'shared';

export const organizationAdapter: EntityAdapter<IOrganization> = createEntityAdapter<IOrganization>({});

export const initialOrganizationState: OrganizationsState = organizationAdapter.getInitialState({
  selectedOrganizationId: undefined,

  allOrganizationsLoaded: false,
  allOrganizationsLoadedError: false,

  organizationReady: false,
  organizationError: false
});

export interface OrganizationsState extends EntityState<IOrganization> {
  selectedOrganizationId: string | undefined;

  allOrganizationsLoaded: boolean;
  allOrganizationsLoadedError: boolean;

  organizationReady: boolean;
  organizationError: boolean;
}
