<div class="page">
  <nav style="display: flex" class="page-tab">
    <button
      pButton
      class="p-button-sm"
      [class.p-button-text]="!rla.isActive"
      #rla="routerLinkActive"
      [routerLink]="['./']"
      routerLinkActive=""
      label="Dynamisch"
      [routerLinkActiveOptions]="{ exact: true }"></button>
    <button
      pButton
      class="p-button-sm ml-1"
      [class.p-button-text]="!rla.isActive"
      [routerLink]="['template', template.id]"
      #rla="routerLinkActive"
      routerLinkActive=""
      *ngFor="let template of pdfTemplates"
      [label]="template.name"></button>
  </nav>
  <router-outlet></router-outlet>
</div>
