export interface IImage {
  id: string;
  path: string;
  title: string;
  projectId: string;
  entryId: string;
  description: string;
  date: Date;
  createdAt: Date;

  creatorId?: string;

  isActive?: boolean;
  cacheId?: string;
  isUploaded?: boolean;
  type?: string;
  preview?: Blob | undefined;
  collectionId?: string;
  file?: File;

  // native usage
  externalId?: string;
  entryExternalId?: string;
}

// export interface IImagePatchRequest extends Partial<IImage> {
//   description: string;
// }
