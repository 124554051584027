import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '@auth0/auth0-angular';
import { UserService } from 'data-access';
import { PopoverService, USER_KEY } from 'shared';

@Component({
  selector: 'account-menu-component',
  templateUrl: './account-menu.component.html',
  styleUrls: ['./account-menu.component.scss']
})
export class AccountMenuComponent {
  user: User | undefined = undefined;

  @Output() onLogout: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private router: Router,
    private popoverService: PopoverService,
    @Inject(USER_KEY) private _userService: UserService
  ) {
    this._userService.getLoggedUser().subscribe(user => {
      this.user = user;
    });
  }

  close() {
    this.router.navigateByUrl('/logout');
    this.popoverService.setState(true);
  }
}
