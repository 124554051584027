<div class="flex flex-column max-h-full min-h-0">
  <!--  <p class="mt-2 mb-2" *ngIf="uploadInProgress || uploadImagesDone > 0 || uploadError">-->
  <!--    Fotos hochgeladen: <span class="font-bold">{{ uploadImagesDone }} / {{ uploadImagesTotal }}</span>-->
  <!--  </p>-->

  <!--  <ng-container [ngTemplateOutlet]="uploadProgressTemplate" *ngIf="mobile"></ng-container>-->

  <div
    class="min-h-0 overflow-auto mb-3"
    dropzone
    #dropzone="dropzone"
    [validDropTypes]="validTypes"
    (onFilesDropped)="readImages($event)">
    <div class="flex flex-row flex-wrap">
      <div class="image-item" *ngFor="let image of images; index as index">
        <img [src]="image.file | toObjectUrl" draggable="false" />
        <div class="image-overlay image-waiting-overlay" *ngIf="confirmProgress && !image.uploadInProgress">
          <span class="mat-outlined">schedule</span>
        </div>
        <div class="image-overlay image-loading-overlay" *ngIf="confirmProgress && image.uploadInProgress">
          <p-progressSpinner></p-progressSpinner>
        </div>
        <div class="image-overlay image-error-overlay" *ngIf="!confirmProgress && image.uploadError">
          <span class="mat-outlined" [pTooltip]="'Konnte nicht hochgeladen werden'">error</span>
        </div>
        <button
          *ngIf="!confirmProgress"
          pButton
          class="p-button-icon-only p-button-text cancel-button"
          (click)="removeImage(index)"
          icon="mat-outlined mat-close"></button>
      </div>
      <div class="image-item">
        <button
          pButton
          class="p-button-raised"
          icon="mat-outlined mat-add"
          (click)="dropzone.triggerFileInput()"></button>
      </div>
    </div>
  </div>
  <div class="field">
    <div class="p-float-label">
      <textarea
        pInputTextarea
        [formControl]="inputForm"
        [autoResize]="true"
        id="imageDescriptionInput"
        class="p-filled w-full"
        touchedDirty
        rows="1"></textarea>
      <label for="imageDescriptionInput">Beschreibung</label>
    </div>
    <small id="imageDescriptionInputHelp" *ngIf="inputForm.invalid && inputForm.touched" class="p-error"
      >mindestens drei Wörter erforderlich</small
    >
    <!--  </div>-->

    <!--  <mat-form-field appearance="outline">-->
    <!--    <mat-label>{{ 'description' | translate }}</mat-label>-->
    <!--    <input [formControl]="inputForm" matInput placeholder="mindestens drei Wörter" type="text" />-->
    <!--    <mat-error *ngIf="inputForm.invalid">{{ 'min-3-words' | translate }}</mat-error>-->
    <!--  </mat-form-field>-->
    <!--  <ng-container [ngTemplateOutlet]="uploadProgressTemplate" *ngIf="!mobile"></ng-container>-->
    <!--  <ng-container [ngTemplateOutlet]="controls" *ngIf="!mobile"></ng-container>-->
  </div>

  <!--<ng-template #controls>-->
  <!--  <div class="flex flex-row justify-content-end">-->
  <!--    <button pButton class="p-button-sm p-button-text" (click)="closeDialog()" [label]="'cancel' | translate"></button>-->
  <!--    <button-->
  <!--      pButton-->
  <!--      [loading]="uploadInProgress"-->
  <!--      class="p-button-sm p-button-raised ml-2"-->
  <!--      (click)="save()"-->
  <!--      [label]="'add' | translate"></button>-->
  <!--  </div>-->
  <!--</ng-template>-->

  <!--<ng-template #uploadProgressTemplate>-->
  <!--  <app-message-->
  <!--    detail="Einige Datein konnten nicht hochgeladen werden"-->
  <!--    *ngIf="!uploadInProgress && uploadError"-->
  <!--    (close)="uploadError = undefined"></app-message>-->
  <!--  <app-message-->
  <!--    type="success"-->
  <!--    detail="Fotos hochgeladen"-->
  <!--    *ngIf="uploadFinished && !uploadError"-->
  <!--    (close)="uploadFinished = false"></app-message>-->
  <!--</ng-template>-->
</div>
