<div #dop_down_account class="menu-container">
  <div class="section">
    <h3>{{ user?.name }}</h3>
    <p>{{ user?.email }}</p>
  </div>
  <div class="section">
    <button mat-button disabled>
      <p>{{ 'profile' | translate }}</p>
    </button>
    <button disabled mat-button>
      <p>{{ 'notifications' | translate }}</p>
    </button>
    <button disabled mat-button>
      <p>{{ 'imprint' | translate }}</p>
    </button>
  </div>
  <div class="section">
    <button mat-button (click)="close()">
      <p>{{ 'logout' | translate }}</p>
    </button>
  </div>
</div>
