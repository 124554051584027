import { createFeatureSelector, createSelector } from '@ngrx/store';
import { projectAdapter, ProjectsState } from './project.state';

export const selectProjectsState = createFeatureSelector<ProjectsState>('projects');

const { selectAll, selectEntities, selectIds, selectTotal } = projectAdapter.getSelectors();

export const selectProjectById = (projectId: string) =>
  createSelector(selectProjectsState, projectsState => projectsState.entities[projectId]);

export const selectAllProjects = createSelector(selectProjectsState, selectAll);

export const selectProjectsLoaded = createSelector(
  selectProjectsState,
  projectsState => projectsState.allProjectsLoaded
);

export const selectLastProjectRestored = createSelector(
  selectProjectsState,
  projectsState => projectsState.lastProjectRestored
);

export const selectSelectedProject = createSelector(selectProjectsState, state =>
  state.selectedProjectId ? state.entities[state.selectedProjectId] : undefined
);

export const selectAllProjectsLoaded = createSelector(selectProjectsState, state => state.allProjectsLoaded);

export const selectIsProjectSelected = createSelector(
  selectProjectsState,
  state => state.selectedProjectId != undefined
);
