import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { ErrorService, ResourceHttpClient, UserService } from 'data-access';
import { selectSelectedProject } from 'data-access';
import { BehaviorSubject, combineLatest, map, mergeMap, Observable, of, share, startWith, switchMap, take } from 'rxjs';
import { IImageEntry, ImageViewerComponentData, IProject, IUser, PopoverService, RESOURCE_KEY, USER_KEY } from 'shared';
import { DeleteEntry, selectEntriesById } from 'data-access';
import { ResourceService } from 'data-access';

@Component({
  selector: 'app-image-popover',
  templateUrl: './image-viewer.component.html',
  styleUrls: ['./image-viewer.component.scss']
})
export class ImageViewerComponent implements OnInit {
  images: IImageEntry[] = [];
  selectedIndex: BehaviorSubject<number>;
  descriptionVisible = false;
  indexOfImage = 0;
  loggedUser?: IUser;
  project?: IProject;

  protected imageSM: Observable<SafeUrl | undefined>;
  protected imageLG: Observable<SafeUrl | undefined>;
  protected imageData: Observable<SafeUrl | undefined>;

  deleteInProgress = false;

  constructor(
    @Inject(RESOURCE_KEY) private resourceService: ResourceService,
    public dialogRef: MatDialogRef<ImageViewerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ImageViewerComponentData,
    private store: Store,
    private httpClient: ResourceHttpClient,
    private sanitizer: DomSanitizer,
    @Inject(USER_KEY) private userService: UserService,
    private popoverService: PopoverService,
    private errorService: ErrorService
  ) {
    this.selectedIndex = new BehaviorSubject<number>(data.initialIndex);
    this.selectedIndex.subscribe(value => {
      if (value) {
        this.indexOfImage = value;
      }
    });
    this.userService.getLoggedUser().subscribe(user => (this.loggedUser = user));

    this.imageSM = this.selectedIndex.pipe(
      switchMap(index => this.getImageData(index!, true).pipe(startWith(undefined))),
      share()
    );

    this.imageLG = combineLatest([this.selectedIndex, this.imageSM]).pipe(
      switchMap(([index, imageSM]) =>
        imageSM ? this.getImageData(index!, false).pipe(startWith(undefined)) : of(undefined)
      ),
      share()
    );

    this.imageData = combineLatest([this.imageSM, this.imageLG]).pipe(
      map(([small, large]) => (large ? large : small)),
      share()
    );
  }

  ngOnInit(): void {
    this.store.select(selectSelectedProject).subscribe(project => (this.project = project));
    this.store
      .select(selectEntriesById(this.data.imageIds))
      .subscribe(images => (this.images = images.map(image => image as IImageEntry)));
  }

  protected prewImage() {
    let nextIndex = this.selectedIndex.value! - 1;
    if (nextIndex < 0) {
      this.selectedIndex.next(this.images.length - 1);
    } else {
      this.selectedIndex.next(nextIndex);
    }
  }

  protected nextImage() {
    let nextIndex = this.selectedIndex.value! + 1;
    if (nextIndex == this.images.length) {
      this.selectedIndex.next(0);
    } else {
      this.selectedIndex.next(nextIndex);
    }
  }

  protected downloadImage() {
    this.resourceService
      .getEntryFile(this.project!.id, this.images[this.selectedIndex.value].id!, false)
      .subscribe(blob => {
        var url = window.URL.createObjectURL(blob);
        var anchor = document.createElement('a');
        anchor.download = '';
        anchor.href = url;
        anchor.click();
        this.popoverService.setState(true);
      });
  }

  private getImageData(index: number, preview: boolean): Observable<SafeUrl> {
    return this.getImageUri(index).pipe(
      mergeMap(uri => this.resourceService.getEntryFile(this.project!.id, this.images[index]!.id!, preview)),
      map(data => this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(data)))
    );
  }

  protected getSelectedImage(): Observable<IImageEntry> {
    return this.selectedIndex.pipe(map(index => this.images[index!]));
  }

  private getImageUri(index: number): Observable<string> {
    return this.store
      .select(selectSelectedProject)
      .pipe(map(project => `/projects/${project!.id}/diary/${this.images[index]?.id}/file`));
  }

  protected get editable(): boolean {
    return this.loggedUser?.id == this.images[this.indexOfImage!]?.creator?.id;
  }

  deleteImage(index: number) {
    this.deleteInProgress = true;
    this.store
      .select(selectSelectedProject)
      .pipe(
        take(1),
        switchMap(project => this.resourceService.deleteEntry(project?.id!, this.images[index!].id!))
      )
      .subscribe({
        next: value => {
          this.store.dispatch(new DeleteEntry(this.images[index!].id!));
          this.images.splice(index!, 1);
          this.popoverService.setState(true);
          this.deleteInProgress = false;
          this.dialogRef.close();
        },
        error: err => {
          this.errorService.showError('Foto konnte nicht gelöscht werden', err);
          this.popoverService.setState(true);
          this.deleteInProgress = false;
        }
      });
  }
}
