import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { ICompany, RESOURCE_KEY } from 'shared';
import { ResourceService } from 'data-access';
import { ComponentConfig } from 'shared';
import { FormDialogComponent } from 'shared';

export type stringFormControlType = string | null | undefined;

export interface ICompanyInputFields {
  name: FormControl<stringFormControlType>;
  description: FormControl<stringFormControlType>;
  phone: FormControl<stringFormControlType>;
  email: FormControl<stringFormControlType>;
}

@Component({
  selector: 'app-company-input',
  templateUrl: './company-input.component.html',
  styleUrls: ['./company-input.component.scss']
})
export class CompanyInputComponent
  extends FormDialogComponent<ICompany, ICompany, ICompanyInputFields>
  implements OnInit
{
  get config(): ComponentConfig {
    return {
      header: `Firma ${this.data?.id ? 'bearbeiten' : 'hinzufügen'}`,
      confirmErrorMessage: 'Firma konnte nicht gespeichert werden',
      confirmSuccessMessage: 'Firma gespeichert',
      confirmLabel: 'Speichern'
    };
  }

  constructor(@Inject(RESOURCE_KEY) private resourceService: ResourceService) {
    super();
  }

  buildFormGroup() {
    const formGroup = new FormGroup<ICompanyInputFields>({
      name: new FormControl('', [Validators.required]),
      description: new FormControl('', [Validators.maxLength(255)]),
      phone: new FormControl(''),
      email: new FormControl('')
    });

    formGroup.get('name')?.setValue(this.data?.name);
    formGroup.get('description')?.setValue(this.data?.description);
    formGroup.get('phone')?.setValue(this.data?.phone);
    formGroup.get('email')?.setValue(this.data?.email);

    return formGroup;
  }

  getSaveObs(): Observable<ICompany> {
    const company: Partial<ICompany> = {
      id: this.data?.id,
      name: this.formGroup!.controls.name.value!,
      description: this.formGroup!.controls.description.value!,
      email: this.formGroup!.controls.email.value!,
      phone: this.formGroup!.controls.phone.value!
    };

    return company.id
      ? this.resourceService.patchCompany(company as ICompany)
      : this.resourceService.postCompany(company as ICompany);
  }
}
