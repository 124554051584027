import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ProjectDetailsRoutingModule } from './project-details-routing.module';
import { ProjectDetailsComponent } from './project-details.component';
import { SharedFeatherModule } from 'libs/shared/src/lib/modules/SharedFeather.module';
import { ButtonModule } from 'primeng/button';
import { ProjectEditDialogModule } from './project-edit-dialog/project-edit.module';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ProjectDetailsRoutingModule,
    SharedFeatherModule,
    ButtonModule,
    ProjectEditDialogModule,
    ProgressSpinnerModule
  ],
  declarations: [ProjectDetailsComponent]
})
export class ProjectDetailsModule {}
