import { Component, forwardRef, Inject, Input, ViewChild } from '@angular/core';
import { ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR, NgForm, NgModel } from '@angular/forms';
import { IMember } from 'shared';
import { IRole } from 'shared';
import { IUser } from 'shared';
import { RESOURCE_KEY } from 'shared';
import { ResourceService } from 'data-access';

@Component({
  selector: 'project-user-input-component',
  templateUrl: './project-user-input.component.html',
  styleUrls: ['./project-user-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ProjectUserInputComponent),
      multi: true
    }
  ],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class ProjectUserInputComponent implements ControlValueAccessor {
  @ViewChild('userInput') userInput?: NgModel;
  @ViewChild('roleChipInput') rolesInput?: NgModel;
  @Input() roles: IRole[] | undefined = undefined;
  @Input() users: IUser[] | undefined = undefined;
  @Input() disabled = false;
  @Input() excludedUserIds: string[] = [];

  searchStr = '';
  selectedUser?: IUser;
  selectedRoles: IRole[] = [];

  constructor(@Inject(RESOURCE_KEY) private resourceService: ResourceService) {
    if (!this.roles) {
      this.resourceService.getAllRolesObs().subscribe(roles => (this.roles = roles));
    }
    if (!this.users) {
      this.resourceService.getUsers().subscribe(users => (this.users = users));
    }
  }

  public get model(): IMember {
    return {
      roles: this.selectedRoles,
      ...this.selectedUser
    };
  }

  public set model(newValue: IMember) {
    this.selectedUser = newValue;
    this.selectedRoles = newValue?.roles ?? [];
  }

  public onChange: any = () => {};

  public onTouched: any = () => {};

  public writeValue(obj: IMember): void {
    this.model = obj;
    this.onChange(this.model);
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public get filteredUsers(): IUser[] {
    return (
      this.users?.filter(user => {
        return user.id && !this.excludedUserIds?.includes(user.id);
      }) ?? []
    );
  }

  toggleRoleSelection(role: IRole) {
    if (!this.selectedRoles?.length) {
      this.selectedRoles = [];
    }
    const searchedIndex = this.selectedRoles.findIndex(r => r.id == role.id);
    if (searchedIndex >= 0) {
      this.selectedRoles.splice(searchedIndex, 1);
    } else {
      this.selectedRoles.push(role);
    }
    this.onChange(this.model);
  }

  setUntouched() {
    this.userInput?.control.markAsPristine();
    this.userInput?.control.markAsUntouched();
    this.rolesInput?.control.markAsPristine();
    this.rolesInput?.control.markAsUntouched();
  }

  public markAsTouched() {
    this.userInput?.control.markAsTouched();
    this.rolesInput?.control.markAsTouched();
  }
}
