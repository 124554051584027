import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { HttpErrorResponse, HttpEvent, HttpEventType } from '@angular/common/http';
import { FileDownloadDialogComponent } from './file-download-dialog/file-download-dialog.component';
import { DialogContainerRef, DialogService } from '../../modules/dialog/dialog.service';
import { DIALOG_SERVICE_KEY } from '../../inject-tokens/resource-inject-token';

@Injectable({
  providedIn: 'root'
})
export abstract class FileDownloadService {
  private error: BehaviorSubject<HttpErrorResponse | undefined> = new BehaviorSubject<HttpErrorResponse | undefined>(
    undefined
  );
  private progress: BehaviorSubject<number | undefined> = new BehaviorSubject<number | undefined>(0);
  private loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private dialogRef: DialogContainerRef<undefined> | undefined;
  private subscription?: Subscription;

  constructor(@Inject(DIALOG_SERVICE_KEY) protected dialogService: DialogService) {}

  public downloadFile(downloader: Observable<HttpEvent<Blob>>, filename: string): void {
    this.loading.next(true);
    this.progress.next(0);
    this.error.next(undefined);
    this.openFileDownloadDialog();
    this.subscription = downloader.subscribe({
      error: err => {
        this.error.next(err);
        this.loading.next(false);
      },
      next: event => {
        switch (event.type) {
          case HttpEventType.DownloadProgress:
            if (event.total && event.loaded) {
              this.progress.next(Math.round((event.loaded / event.total) * 100));
            } else {
              this.progress.next(undefined);
            }
            return;
          case HttpEventType.Response:
            this.loading.next(false);
            this.error.next(undefined);
            this.progress.next(100);
            if (event.body) {
              this.interactionHandle(event.body, filename);
            }
            this.closeFileDownloadDialog();
            return;
        }
      }
    });
  }

  abstract interactionHandle(blob: Blob, filename: string): any;

  private openFileDownloadDialog(): void {
    this.dialogRef = this.dialogService.openDialog<FileDownloadDialogComponent, undefined, undefined>(
      FileDownloadDialogComponent,
      { type: 'dialog' }
    );
    this.dialogRef.afterDismissed().subscribe(() => this.subscription?.unsubscribe());
  }

  private closeFileDownloadDialog(): void {
    this.dialogRef?.close();
  }

  public getProgress(): Observable<number | undefined> {
    return this.progress.asObservable();
  }

  public getError(): Observable<HttpErrorResponse | undefined> {
    return this.error.asObservable();
  }

  public getLoading(): Observable<boolean> {
    return this.loading.asObservable();
  }
}
