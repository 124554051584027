import { combineLatest, map, Observable, startWith } from 'rxjs';
import { UntypedFormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Component } from '@angular/core';
import { loadAllOrganizationsAction, selectAllOrganizations, selectSelectedOrganization } from 'data-access';
import { IOrganization } from 'shared';
import { selectUploadInProgress } from 'data-access';

@Component({
  selector: 'organization-menu',
  templateUrl: './select-menu.component.html',
  styleUrls: ['./select-menu.component.scss']
})
export class OrganizationSelectMenuComponent {
  selectedOrganization?: IOrganization;
  filteredOrgs: IOrganization[] = [];

  slided = false;
  uploadInProgress = false;

  inputForm: UntypedFormControl = new UntypedFormControl();

  constructor(private store: Store) {
    this.store.select(selectSelectedOrganization).subscribe(org => (this.selectedOrganization = org));
    this.store.dispatch(loadAllOrganizationsAction());
    this.store.select(selectUploadInProgress).subscribe(uploadInProgress => (this.uploadInProgress = uploadInProgress));
    this.getFilteredOrgs().subscribe(orgs => (this.filteredOrgs = orgs));
  }

  getFilteredOrgs(): Observable<IOrganization[]> {
    return combineLatest([
      this.store.select(selectAllOrganizations),
      this.inputForm.valueChanges.pipe(startWith(''))
    ]).pipe(
      map(([orgs, value]) =>
        orgs.filter(org => {
          if (value) {
            return org.displayName.toLowerCase().includes(value.toLowerCase());
          }
          return true;
        })
      )
    );
  }
}
