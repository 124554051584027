import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UploadInProgressGuard } from 'data-access';
import { OrganizationsPage } from './components/organizations/organizations.page';

const routes: Routes = [
  {
    path: '',
    component: OrganizationsPage,
    canDeactivate: [UploadInProgressGuard],
    children: [
      {
        path: '',
        redirectTo: 'projects',
        pathMatch: 'full'
      },
      {
        path: 'landing',
        loadChildren: () => import('./modules/landing/landing.module').then(m => m.LandingPageModule)
      },
      {
        path: 'announcements',
        loadChildren: () => import('./modules/announcements/announcements.module').then(m => m.AnnouncementsModule)
      },
      {
        path: 'projects',
        loadChildren: () => import('core').then(m => m.CoreProjectsModule)
      },
      {
        path: 'members',
        loadChildren: () => import('core').then(m => m.CoreMembersModule)
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CoreOrganizationsRoutingModule {}
