<div class="pdf-export-item">
  <div class="pdf-export-item-left">
    <div class="pdf-export-item-header">
      <h3 class="link" [routerLink]="pdf?.id">{{ title }}</h3>
      <span
        class="mat-outlined cursor-pointer"
        *ngIf="outdated"
        style="color: #f59e0b"
        pTooltip="Dieses Dokument enthält möglicherweise nicht den aktuellen Stand des Bautagebuchs"
        >history</span
      >
    </div>
    <p>{{ description }}</p>
  </div>
  <div class="pdf-export-item-right">
    <!--    <button-->
    <!--      *ngIf="isComplete"-->
    <!--      pButton-->
    <!--      disabled-->
    <!--      icon="mat-outlined mat-info"-->
    <!--      class="p-button-text ml-1"-->
    <!--      (click)="showPdfDetails()"></button>-->

    <button
      *ngIf="isComplete"
      pButton
      icon="mat-outlined mat-download"
      class="p-button-sm p-button-text ml-1"
      (click)="downloadFile()"></button>

    <button
      *ngIf="isComplete"
      pButton
      icon="mat-outlined mat-delete"
      class="p-button-sm p-button-text ml-1"
      (click)="deletePdf()"></button>

    <button
      *ngIf="isComplete && pdf"
      pButton
      icon="mat-outlined mat-visibility"
      class="p-button-sm p-button-text ml-1"
      [routerLink]="pdf.id"></button>

    <button
      *ngIf="generationFailed"
      pButton
      class="p-button-danger p-button-sm"
      icon="mat-outlined mat-error"
      pTooltip="PDF konnte nicht erzeugt werden"></button>

    <button
      *ngIf="(pdf && (pdf.outdated || !isComplete)) || generationFailed"
      pButton
      icon="mat-outlined mat-refresh"
      class="p-button-sm ml-1"
      loadingIcon="pi pi-spin pi-spinner"
      [loading]="loading"
      [label]="refreshButtonLabel"
      (click)="refresh()"></button>

    <button
      *ngIf="!pdf"
      pButton
      icon="mat-outlined mat-add"
      class="p-button-sm ml-1"
      loadingIcon="pi pi-spin pi-spinner"
      [loading]="loading"
      label="Erstellen"
      (click)="refresh()"></button>
  </div>
</div>
