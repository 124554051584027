import { InjectionToken } from '@angular/core';

export const RESOURCE_KEY = new InjectionToken('(RESOURCE_KEY)');
export const MANAGEMENT_TOKEN_SERVICE = new InjectionToken('(MANAGEMENT_TOKEN_SERVICE)');
export const ORGANIZATION_TOKEN_SERVICE = new InjectionToken('(ORGANIZATION_TOKEN_SERVICE)');

export const IMAGE_INPUT_SERVICE_KEY = new InjectionToken('(IMAGE_INPUT_SERVICE_KEY)');
export const IMAGE_VIEWER_SERVICE_KEY = new InjectionToken('(IMAGE_VIEWER_SERVICE_KEY)');

export const DIALOG_SERVICE_KEY = new InjectionToken('(DIALOG_SERVICE_KEY)');

export const CONFIRM_SERVICE_KEY = new InjectionToken('(CONFIRM_SERVICE_KEY)');
