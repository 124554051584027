import {
  DialogContainerData,
  DialogService,
  MatDialogDialogContainer,
  MaterialDialogContainerComponent,
  MatSheetDialogContainer
} from 'shared';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { DialogContainerRef } from 'shared';

@Injectable({ providedIn: 'root' })
export class WebDialogService extends DialogService {
  constructor(private matDialog: MatDialog, private matSheet: MatBottomSheet) {
    super();
  }

  protected override openSheet<C, D, R>(dialogContainerData: DialogContainerData<C, D>): DialogContainerRef<R> {
    const sheet = this.matSheet.open(MaterialDialogContainerComponent, {
      data: dialogContainerData
    });
    return new MatSheetDialogContainer(sheet);
  }

  protected override openRegularDialog<C, D, R>(dialogContainerData: DialogContainerData<C, D>): DialogContainerRef<R> {
    const dialog = this.matDialog.open(MaterialDialogContainerComponent, {
      maxWidth: '90vw',
      maxHeight: '90vh',
      data: dialogContainerData
    });
    return new MatDialogDialogContainer(dialog);
  }
}
