import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { ProjectDocumentsRoutingModule } from './project-documents-routing.module';

import { ProjectDocumentsComponent } from './project-documents.component';

@NgModule({
  imports: [CommonModule, FormsModule, ProjectDocumentsRoutingModule],
  declarations: [ProjectDocumentsComponent]
})
export class ProjectDocumentsModule {}
