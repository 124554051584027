import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, filter, map, Observable, ReplaySubject, shareReplay, switchMap } from 'rxjs';
import { IOrganization, IUser, RESOURCE_KEY } from 'shared';
import { ResourceService } from '../classes/resource-service.class';

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {
  private organizations: BehaviorSubject<IOrganization[]> = new BehaviorSubject<IOrganization[]>([]);
  private selectedOrganization: ReplaySubject<IOrganization> = new ReplaySubject<IOrganization>(1);
  private isLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private members: Observable<IUser[] | undefined> | undefined = undefined;

  constructor(@Inject(RESOURCE_KEY) private resourceService: ResourceService) {
    this.updateOrganizations();
  }

  public updateOrganizations() {
    this.isLoading.next(true);
    this.resourceService.getOrganizations().subscribe({
      error: err => {
        this.organizations.error(err);
        this.selectedOrganization.error(err);
        // this.hasError.next(true);
        this.isLoading.next(false);
      },
      next: organizations => {
        this.organizations.next(organizations);
        // if the last org was set in local storage use it
        let lastOrg = organizations.find(org => org.id == localStorage.getItem('last_org'));
        this.selectOrganization(lastOrg ?? organizations[0]);
        //this.hasError.next(false);
        this.isLoading.next(false);
      }
    });
  }

  public selectOrganization(organization: IOrganization) {
    this.selectedOrganization.next(organization);
    localStorage.setItem('last_org', organization.id);
  }

  public getOrganizations(): Observable<IOrganization[]> {
    return this.organizations.asObservable();
  }

  public getSelectedOrganization(): Observable<IOrganization> {
    return this.selectedOrganization.asObservable().pipe(filter(org => !!org));
  }

  public getIsLoading(): Observable<boolean> {
    return this.isLoading.asObservable();
  }

  public getOrganizationMembers(): Observable<IUser[] | undefined> {
    if (!this.members) {
      this.members = this.selectedOrganization.asObservable().pipe(
        filter(organization => organization != undefined),
        switchMap(organization => this.resourceService.getOrganizationMembers(organization!.id)),
        shareReplay(1)
      );
    }
    return this.members;
  }

  public getOrganizationMember(memberId: string): Observable<IUser | undefined> {
    return this.getOrganizationMembers().pipe(map(members => members?.find(member => member.id == memberId)));
  }
}
