import { MemberActions, MemberActionTypes } from './member.actions';
import { initialMembersState, memberAdapter, MemberState } from './member.state';

export function memberReducer(state = initialMembersState, action: MemberActions): MemberState {
  switch (action.type) {
    case MemberActionTypes.LoadAllProjectMembersSuccess:
      return memberAdapter.addMany(action.payload.members, {
        ...state,
        allMembersLoaded: true
      });

    case MemberActionTypes.ResetProjectMemberState:
      return memberAdapter.getInitialState(initialMembersState);

    case MemberActionTypes.AddProjectMember:
      return memberAdapter.addOne(action.payload.member, state);

    case MemberActionTypes.RemoveProjectMember:
      return memberAdapter.removeOne(action.payload.memberId, state);

    case MemberActionTypes.SetProjectMemberRoles:
      return memberAdapter.updateOne(
        {
          id: action.payload.member.id!,
          changes: {
            roles: action.payload.roles
          }
        },
        state
      );

    default:
      return state;
  }
}
