import { Component, HostListener, Inject, OnInit, ViewChild } from '@angular/core';
import * as uuid from 'uuid';
import { DatePipe } from '@angular/common';
import { FormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { MatTabGroup } from '@angular/material/tabs';
import {
  DialogComponent,
  DiaryEntryType,
  IImageEntryRequest,
  IProject,
  IRegularEntry,
  RESOURCE_KEY,
  validateWordCount
} from 'shared';
import { AddEntry, ResourceService, selectSelectedProject } from 'data-access';
import { catchError, concatMap, delay, EMPTY, from, last, map, Observable, tap, window } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { ComponentConfig } from '../../../../../../shared/src/lib/modules/dialog/dialog/dialog.component';

export interface ImageUploadDialogData {
  files: Blob[];
  entry: IRegularEntry | undefined;
  date: Date;
}

export interface IUploadImage {
  uploadInProgress: boolean;
  uploadProgress: number;
  uploadError: HttpErrorResponse | undefined;
  id: string;
  file: Blob;
  // fileName: string;
  fileSize: number;
}

@Component({
  selector: 'app-image-upload-popover',
  templateUrl: './image-upload-dialog.component.html',
  styleUrls: ['./image-upload-dialog.component.scss']
})
export class ImageUploadDialogComponent extends DialogComponent<ImageUploadDialogData, undefined> implements OnInit {
  constructor(
    private store: Store,
    private datePipe: DatePipe,
    @Inject(RESOURCE_KEY) private resourceService: ResourceService
  ) {
    super();
  }

  @ViewChild('mobileTabsGroup') mobileTabsGroup?: MatTabGroup;

  images: IUploadImage[] = [];
  validTypes: string[] = ['image/png', 'image/jpeg'];
  inputForm: FormControl = new FormControl('', [validateWordCount]);
  loadingImages = false;
  project!: IProject;

  // uploadInProgress = false;
  uploadImagesTotal = 0;
  uploadImagesDone = 0;

  public get config(): ComponentConfig {
    let title = '';
    if (this.data?.date) {
      title = this.data.date.toLocaleDateString();
      if (this.data?.entry) {
        title = title + ` - ${this.data.entry.company?.name}`;
      }
    }

    return {
      header: title,
      confirmLabel: 'Speichern',
      confirmSuccessMessage: 'Fotos erfolgreich hochgeladen',
      confirmErrorMessage: 'Foto konnte nicht hochgeladen werden'
    };
  }

  override ngOnInit() {
    super.ngOnInit();
    this.readImages(this.data!.files.filter(file => this.validTypes.includes(file.type)));
    this.store.select(selectSelectedProject).subscribe(project => (this.project = project!));
  }

  readImage(file: Blob): Promise<IUploadImage> {
    return new Promise<IUploadImage>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = e => {
        const blob = new Blob([e.target?.result as BlobPart], { type: file.type });
        resolve({
          id: uuid.v4(),
          file: blob,
          fileSize: file.size,
          uploadProgress: 0,
          uploadInProgress: false,
          uploadError: undefined
        });
      };
      reader.onerror = () => {
        reject();
      };
      reader.readAsArrayBuffer(file);
    });
  }

  readImages(files: Blob[]) {
    this.loadingImages = true;
    const promises: Promise<IUploadImage>[] = [];
    for (const file of files) {
      promises.push(
        this.readImage(file).then(image => {
          this.images.push(image);
          return image;
        })
      );
    }
    Promise.allSettled(promises).then(() => (this.loadingImages = false));
  }

  removeImage(index: number) {
    this.images.splice(index, 1);
  }

  getSaveObs(): Observable<undefined> {
    this.uploadImagesDone = 0;
    this.uploadImagesTotal = this.images.length;
    this.inputForm.disable();
    return from(this.images).pipe(
      concatMap(image => {
        this.images.find(i => i.id === image.id)!.uploadInProgress = true;
        const imageRequest: IImageEntryRequest = {
          date: this.datePipe.transform(this.data!.date, 'yyyy-MM-dd')!,
          description: this.inputForm.value,
          type: DiaryEntryType.IMAGE
        };
        return this.resourceService.postImage(this.project.id, imageRequest, image.file, this.data?.entry?.id).pipe(
          tap(response => {
            console.log('uploaded: ', response.id);
            const index = this.images.findIndex(i => i.id === image.id);
            this.images.splice(index, 1);
            this.uploadImagesDone = this.uploadImagesDone + 1;
            this.store.dispatch(new AddEntry(response));
          }),
          catchError((err: HttpErrorResponse) => {
            this.images.find(i => i.id === image.id)!.uploadError = err;
            this.images.find(i => i.id === image.id)!.uploadInProgress = false;
            return EMPTY;
          })
        );
      }),
      last(),
      map(() => undefined)
    );
  }
}
