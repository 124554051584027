<p *ngIf="confirmMessage != undefined" [innerHTML]="confirmMessage"></p>

<form *ngIf="confirmControl" [formGroup]="confirmControl">
  <p *ngIf="confirmChallenge" class="mt-3 mb-1">
    Aktion bestätigen:
    <span class="font-italic font-bold">{{ confirmChallenge }}</span>
  </p>

  <div class="field mb-2" *ngIf="confirmChallenge">
    <div class="p-float-label">
      <input
        type="text"
        pInputText
        formControlName="challenge"
        touchedDirty
        id="confirmTextChallengeInput"
        class="p-filled w-full"
        [placeholder]="confirmChallenge" />
      <label for="confirmTextChallengeInput">Bestätigung</label>
    </div>
    <small
      id="weatherHelp"
      *ngIf="confirmControl.get('challenge')?.invalid && confirmControl.get('challenge')?.touched"
      class="p-error"
      >Bestätigung wiederholen</small
    >
  </div>
  <p-checkbox
    *ngIf="confirmToggle"
    touchedDirty
    class="mt-3 mb-2"
    formControlName="toggle"
    [binary]="true"
    [label]="confirmTitle"></p-checkbox>
</form>
