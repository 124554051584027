import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { selectFailedUploads, selectUploadInProgress } from 'data-access';
import { Observable, combineLatest, map } from 'rxjs';

export interface ComponentCanDeactivate {
  canDeactivate: () => boolean | Observable<boolean>;
}

@Injectable({
  providedIn: 'root'
})
export class UploadInProgressGuard implements CanDeactivate<ComponentCanDeactivate> {
  constructor(private store: Store) {}
  canDeactivate(component: ComponentCanDeactivate): boolean | Observable<boolean> {
    return combineLatest([
      this.store.pipe(select(selectUploadInProgress)),
      this.store.pipe(select(selectFailedUploads))
    ]).pipe(
      map(result => {
        if (!result[0] && !result[1]?.length) {
          return true;
        } else {
          confirm(
            'WARNING: You have unsaved changes. Press Cancel to go back and save these changes, or OK to lose these changes.'
          );
          return false;
        }
      })
    );
  }
}
