import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { NotRespondingPageRoutingModule } from './not-responding-routing.module';

import { NotRespondingPage } from './not-responding.page';

@NgModule({
  imports: [CommonModule, FormsModule, NotRespondingPageRoutingModule],
  declarations: [NotRespondingPage]
})
export class NotRespondingPageModule {}
