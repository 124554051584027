import { createReducer, on } from '@ngrx/store';
import * as OrganizationActions from './organization.actions';
import { initialOrganizationState, organizationAdapter } from './organization.state';

export const organizationReducer = createReducer(
  initialOrganizationState,
  on(OrganizationActions.selectOrganizationSuccessAction, (state, { organizationId }) => {
    return {
      ...state,
      selectedOrganizationId: organizationId,
      organizationReady: true,
      organizationError: false
    };
  }),
  on(OrganizationActions.selectOrganizationErrorAction, state => {
    return {
      ...state,
      selectedOrganizationId: undefined,
      organizationReady: false,
      organizationError: true
    };
  }),
  on(OrganizationActions.loadAllSuccessAction, (state, { organizations }) => {
    return organizationAdapter.addMany(organizations, {
      ...state,
      allOrganizationsLoaded: true
    });
  }),
  on(OrganizationActions.restoreOrganizationSuccessAction, (state, { organization }) => {
    return organizationAdapter.addOne(organization, { ...state });
  }),
  on(OrganizationActions.restoreOrganizationErrorAction, state => {
    return { ...state, organizationReady: false, organizationError: true };
  })
);
