import { initialProjectsState, initialState, projectAdapter, ProjectsState } from './project.state';
import { ProjectActions, ProjectActionTypes } from './project.actions';

export function projectsReducer(state = initialProjectsState, action: ProjectActions): ProjectsState {
  switch (action.type) {
    case ProjectActionTypes.AddProjectSuccess:
      return projectAdapter.addOne(action.payload.project, state);

    case ProjectActionTypes.UpdateProject:
      return projectAdapter.updateOne({ id: action.payload.project.id!, changes: action.payload.project }, state);

    case ProjectActionTypes.LoadProjectSuccess:
      return projectAdapter.addOne(action.payload.project, state);

    case ProjectActionTypes.LoadAllProjectsSuccess:
      return projectAdapter.addMany(action.payload.projects, {
        ...state,
        allProjectsLoaded: true
      });

    case ProjectActionTypes.ResetProjectState:
      return projectAdapter.getInitialState(initialState);

    case ProjectActionTypes.SelectProject:
      return { ...state, selectedProjectId: action.payload.projectId };

    case ProjectActionTypes.UnselectProject:
      return { ...state, selectedProjectId: undefined };

    case ProjectActionTypes.RestoreLastProjectSuccess:
      return { ...state, lastProjectRestored: true };

    case ProjectActionTypes.RestoreLastProjectError:
      return { ...state, lastProjectRestored: true };

    default: {
      return state;
    }
  }
}
