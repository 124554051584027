import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[dragData]'
})
export class DraggableDirective implements OnInit {
  @Input('dragData') dragData: any = undefined;

  ngOnInit(): void {}

  constructor(private elementRef: ElementRef) {
    this.elementRef.nativeElement.addEventListener('dragstart', this.onDragStart.bind(this));
  }

  onDragStart(event: any) {
    event.dataTransfer.setData('object', this.dragData);
  }
}
