import { Component } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { ComponentConfig, DialogComponent, FileDownloadService } from 'shared';

@Component({
  selector: 'app-file-download-dialog',
  templateUrl: './file-download-dialog.component.html',
  styleUrls: ['./file-download-dialog.component.scss']
})
export class FileDownloadDialogComponent extends DialogComponent<any, any> {
  protected error: HttpErrorResponse | undefined;
  protected loadingFile: boolean = false;
  protected progress: number | undefined = undefined;

  constructor(private fileDownloadService: FileDownloadService) {
    super();
    this.fileDownloadService.getError().subscribe(err => (this.error = err));
    this.fileDownloadService.getLoading().subscribe(loading => (this.loadingFile = loading));
    this.fileDownloadService.getProgress().subscribe(progress => {
      console.log(progress);
      this.progress = progress;
    });
  }

  config: ComponentConfig = {
    header: 'Datei wird bereitgestellt',
    hideConfirmButton: true
  };

  getSaveObs(): Observable<undefined> {
    return of(undefined);
  }
}
