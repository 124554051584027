import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FeatherModule } from 'angular-feather';
import { allIcons } from 'angular-feather/icons';
import { DropdownModule } from 'primeng/dropdown';

import { LoadingComponent } from '../components/loading/loading.component';
import { ImageLazyLoadModule } from '../pipes/image/lazy-load-images.module';
import { ErrorModule } from './error/error.module';
import { SharedModule } from '../shared.module';
import { AppMessageModule } from './message/app-message.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    MatChipsModule,
    MatIconModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    FeatherModule.pick(allIcons),
    RouterModule,
    MatAutocompleteModule,
    MatTooltipModule,
    MatButtonModule,
    ErrorModule,
    DropdownModule,
    SharedModule,
    TranslateModule,
    AppMessageModule
  ],
  declarations: [LoadingComponent],
  providers: [],
  exports: [
    MatChipsModule,
    MatIconModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    FeatherModule,
    RouterModule,
    LoadingComponent,
    MatAutocompleteModule,
    MatTooltipModule,
    MatButtonModule,
    ErrorModule,
    ImageLazyLoadModule,
    SharedModule,
    TranslateModule
  ]
})
export class SharedPageModule {}

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}
