import { Directive, ElementRef, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { filter, fromEvent, switchMap } from 'rxjs';
import { ConfirmService } from './confirm.service';
import { CONFIRM_SERVICE_KEY } from '../../inject-tokens/resource-inject-token';

export interface ConfirmDialogData {
  confirmTitle: string;
  confirmMessage: string | undefined;
  confirmLabel: string;
  cancelLabel: string;
  confirmChallenge: string | undefined;
  confirmToggle: boolean;
  confirmDanger: boolean;
}

@Directive({
  selector: '[onConfirmed]'
})
export class ConfirmDirective implements OnInit {
  @Output() onConfirmed: EventEmitter<void> = new EventEmitter<void>();
  @Output() onCanceled: EventEmitter<void> = new EventEmitter<void>();
  @Input() confirmTitle = 'Aktion bestätigen';
  @Input() confirmMessage?: string;
  @Input() confirmLabel = 'Bestätigen';
  @Input() cancelLabel = 'Abbrechen';
  @Input() confirmChallenge?: string;
  @Input() confirmToggle = false;
  @Input() confirmDanger = false;
  @Input() confirmDisabled = false;

  constructor(private elementRef: ElementRef, private confirmService: ConfirmService) {}

  ngOnInit() {
    this.initListener();
  }

  initListener(): void {
    const dialogData: ConfirmDialogData = {
      confirmTitle: this.confirmTitle,
      confirmMessage: this.confirmMessage,
      confirmLabel: this.confirmLabel,
      cancelLabel: this.cancelLabel,
      confirmChallenge: this.confirmChallenge,
      confirmDanger: this.confirmDanger,
      confirmToggle: this.confirmToggle
    };
    fromEvent(this.elementRef.nativeElement, 'click')
      .pipe(
        filter(() => {
          return !this.confirmDisabled;
        }),
        switchMap(() => this.confirmService.confirm(dialogData))
      )
      .subscribe(confirmed => {
        if (confirmed) {
          this.onConfirmed.emit();
        }
      });
  }
}
