import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScrollablePaddingDirective } from '../../directives/srollable-padding.directive';
import { AppMessageModule } from '../message/app-message.module';
import { ButtonModule } from 'primeng/button';
import { MaterialDialogContainerComponent } from './dialog-container/web-dialog-container/material-dialog-container.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

@NgModule({
  declarations: [ScrollablePaddingDirective, MaterialDialogContainerComponent],
  imports: [CommonModule, ButtonModule, AppMessageModule, ProgressSpinnerModule],
  exports: [
    CommonModule,
    ButtonModule,
    AppMessageModule,
    ProgressSpinnerModule,
    ScrollablePaddingDirective,
    MaterialDialogContainerComponent
  ]
})
export class DialogModule {}
