<label>Organisation</label>
<button
  class="org-selector mt-1"
  [disabled]="(selectedOrganization | async) == undefined"
  [popoverTrigger]="organizationSelector"
  pButton
  [positionX]="['start', 'start']"
  [positionY]="['bottom', 'top']"
  (onChangePopoverState)="popoverActive = $event">
  <ng-container
    [ngTemplateOutlet]="(selectedOrganization | async)?.logoURL ? icon : noImageIcon"
    [ngTemplateOutletContext]="{
      organization: (selectedOrganization | async)
    }"></ng-container>
  <h3>{{ (selectedOrganization | async)?.displayName ?? '' }}</h3>
  <span class="mat-outlined" [class.-rotate-90]="popoverActive">chevron_left</span>
</button>

<ng-template #organizationSelector>
  <organization-menu></organization-menu>
</ng-template>

<ng-template #icon let-organization="organization">
  <div class="icon" *ngIf="organization?.logoURL">
    <img [attr.src]="organization?.logoURL" />
  </div>
</ng-template>

<ng-template #noImageIcon let-organization="organization">
  <div class="icon" id="noImageIcon">
    <span>{{ organization?.name?.substring(0, 1) ?? '' }}</span>
  </div>
</ng-template>
