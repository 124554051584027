<div class="page">
  <div class="outer-flex">
    <div id="col1" class="col">
      <div class="btb-card linked" id="details-card">
        <div class="btb-card-header" [routerLink]="['../details']">
          <h1>Projektdetails<span class="material-icons">trending_flat</span></h1>
        </div>
        <div class="btb-card-content" *ngIf="project; else loadingTemplate">
          <div class="details-col">
            <div class="details-entry">
              <label>Auftraggebende Stelle</label>
              <p>{{ castString(project.commissioningAuthority) }}</p>
            </div>
            <div class="details-entry">
              <label>Bauüberwachende Stelle</label>
              <p>{{ castString(project.supervisoryAuthority) }}</p>
            </div>
            <div class="details-entry">
              <label>Projekt</label>
              <p>{{ castString(project.name) }}</p>
            </div>
          </div>
          <div class="details-col">
            <div class="details-entry">
              <label>Bauauftrag</label>
              <p>{{ castString(project.constructionTask) }}</p>
            </div>
            <div class="details-entry">
              <label>Projektbezeichnung</label>
              <p>{{ castString(project.description) }}</p>
            </div>
            <div class="details-entry">
              <label>Projekt-Nr.</label>
              <p>{{ castString(project.number) }}</p>
            </div>
          </div>
        </div>
        <div class="btb-card-link" [routerLink]="['../details']">
          <p>mehr Details<span class="material-icons">navigate_next</span></p>
        </div>

        <!-- <div class="details-entry">
                    <h1>Bautagebuch</h1>
                  </div>

                  <div class="row">
                    <div class="details-entry">
                      <label>Baubeginn am:</label>
                      <p>{{project.start_date.toLocaleDateString()}}</p>
                    </div>
                    <div class="details-entry">
                      <label>Baufertigstellung am:</label>
                      <p>{{project.end_date.toLocaleDateString()}}</p>
                    </div>
                  </div>

                  <div class="details-entry divider-top divider-bottom">
                    <h2>Unterbrechungen</h2>
                    <div class="interruption-content" *ngIf="project.interruptions.length>0 else noInterruptionsTemplate">
                      <div class="details-entry" *ngFor="let interruption of project.interruptions">
                        <label>Grund:</label>
                        <p>{{interruption.cause}}</p>
                        <div class="row">
                          <div class="details-entry">
                            <label>Unterbrechunsbeginn:</label>
                            <p>{{interruption.start_date.toLocaleDateString()}}</p>
                          </div>
                          <div class="details-entry">
                            <label>Unterbrechunsende:</label>
                            <p>{{interruption.end_date.toLocaleDateString()}}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="details-entry">
                    <p [class.smaller]="true">Aufmaßblätter werden in den Ordnern Aufmaße Band 1 bis "{{castString(project.measurements_location)}}" gesammelt.</p>
                  </div>
                  <div class="details-entry">
                    <p [class.smaller]="true">Bautagesberichte, Stundenlohnzettel, Vermerke und Messdaten siehe Ordner "{{castString(project.documents_location)}}"</p>
                  </div>           -->
      </div>

      <div class="btb-card linked" id="diary-card">
        <div class="btb-card-header">
          <h1 [routerLink]="['../diary']">Bautagebuch<span class="material-icons">trending_flat</span></h1>
        </div>
        <div class="btb-card-content scrollable" *ngIf="project; else loadingTemplate">
          <div class="scroll-box">
            <div *ngFor="let diaryObject of previewDiaryObjects; let dateIndex = index">
              <diary-date-component
                [diaryObject]="diaryObject"
                [project]="project"
                [highlighted]="dateIndex % 2 == 0"
                [suppressBottomSpacer]="dateIndex == previewDiaryObjects.length - 1">
              </diary-date-component>
            </div>
          </div>
        </div>
        <div class="btb-card-link">
          <p [routerLink]="['../diary']">mehr Details<span class="material-icons">navigate_next</span></p>
        </div>
      </div>
    </div>
    <div id="col2" class="col">
      <div id="weather-card" class="btb-card">
        <div class="btb-card-header">
          <h1>Wetter</h1>
        </div>
        <div class="btb-card-content develop" *ngIf="project; else loadingTemplate">
          <span class="material-icons">construction</span>
          <p>Under Construction</p>
        </div>
      </div>

      <div class="btb-card linked">
        <div class="btb-card-header">
          <h1 [routerLink]="['../users']">Nutzer<span class="material-icons">trending_flat</span></h1>
        </div>
        <div class="btb-card-content develop" *ngIf="project; else loadingTemplate">
          <span class="material-icons">construction</span>
          <p>Under Construction</p>
        </div>
        <div class="btb-card-link">
          <p [routerLink]="['../users']">mehr Details<span class="material-icons">navigate_next</span></p>
        </div>
      </div>

      <div class="btb-card">
        <div class="btb-card-header">
          <h1>Abrechnung<span class="material-icons">trending_flat</span></h1>
        </div>
        <div class="btb-card-content develop" *ngIf="project; else loadingTemplate">
          <span class="material-icons">construction</span>
          <p>Under Construction</p>
        </div>
      </div>
    </div>
  </div>

  <ng-template #loadingTemplate>
    <div style="height: 100%; width: 100%; display: flex; align-items: center; justify-content: center"></div>
  </ng-template>

  <ng-template #noInterruptionsTemplate>
    <div class="details-entry interruption-content">
      <p [class.smaller]="true">Keine Unterbrechungen</p>
    </div>
  </ng-template>
</div>
