import { Directive, ElementRef, HostListener } from '@angular/core';

/**
 * This directive removes focus from the selectors after clicking on them
 */
@Directive({
  selector: '[loseFocusButton]'
})
export class LoseFocusDirective {
  constructor(private elRef: ElementRef) {}

  @HostListener('click') onClick() {
    setTimeout(() => this.elRef.nativeElement.blur(), 1);
  }
}
