import { BehaviorSubject, combineLatest, Observable, of, switchMap } from 'rxjs';
import { IPermission, OrganizationPermission, ProjectPermission, RESOURCE_KEY, USER_KEY } from 'shared';
import { Inject, Injectable } from '@angular/core';
import { ResourceService, selectSelectedOrganization, UserService } from 'data-access';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {
  private permissions: BehaviorSubject<IPermission[] | undefined> = new BehaviorSubject<IPermission[] | undefined>(
    undefined
  );

  constructor(
    @Inject(USER_KEY) private userService: UserService,
    @Inject(RESOURCE_KEY) private resourceService: ResourceService,
    private store: Store
  ) {
    this.getPermissionsObs().subscribe(permissions => {
      this.permissions.next(permissions);
    });
  }

  private getPermissionsObs(): Observable<IPermission[] | undefined> {
    return combineLatest([this.userService.getLoggedUser(), this.store.select(selectSelectedOrganization)]).pipe(
      switchMap(([user, organization]) => {
        if (user?.id && organization) {
          return this.resourceService.getUsersPermissions(user.id);
        } else {
          return of(undefined);
        }
      })
    );
  }

  private getPermissionsEnumObs(): Observable<OrganizationPermission[] | undefined> {
    return combineLatest([this.userService.getLoggedUser(), this.store.select(selectSelectedOrganization)]).pipe(
      switchMap(([user, organization]) => {
        if (user?.id && organization) {
          return this.resourceService.getUserPermissionsNew(user.id);
        } else {
          return of(undefined);
        }
      })
    );
  }

  public getPermissions(): Observable<IPermission[] | undefined> {
    return this.permissions.asObservable();
  }

  public getPermissionsEnums(): OrganizationPermission[] {
    return Object.values(OrganizationPermission);
  }

  public getOrganizationPermissionsMap(): Map<PermissionEntity, (OrganizationPermission | ProjectPermission)[]> {
    return new Map<PermissionEntity, (OrganizationPermission | ProjectPermission)[]>([
      [
        PermissionEntity.PROJECT,
        [
          OrganizationPermission.PROJECTS_LIST,
          OrganizationPermission.PROJECTS_CREATE,
          OrganizationPermission.PROJECTS_READ,
          OrganizationPermission.PROJECTS_UPDATE,
          OrganizationPermission.PROJECTS_DELETE
        ]
      ],
      [
        PermissionEntity.ROLES,
        [
          OrganizationPermission.ROLES_READ,
          OrganizationPermission.ROLES_CREATE,
          OrganizationPermission.ROLES_UPDATE,
          OrganizationPermission.ROLES_DELETE,
          OrganizationPermission.ROLES_ASSIGN,
          OrganizationPermission.ROLES_REVOKE
        ]
      ],
      [PermissionEntity.USERS, [OrganizationPermission.USERS_READ]],
      [
        PermissionEntity.INVITES,
        [
          OrganizationPermission.INVITES_READ,
          OrganizationPermission.INVITES_CREATE,
          OrganizationPermission.INVITES_DELETE
        ]
      ],
      [
        PermissionEntity.COMPANIES,
        [
          OrganizationPermission.COMPANIES_READ,
          OrganizationPermission.COMPANIES_CREATE,
          OrganizationPermission.COMPANIES_UPDATE,
          OrganizationPermission.COMPANIES_DELETE,
          OrganizationPermission.PROJECT_COMPANIES_ASSIGN,
          OrganizationPermission.PROJECT_COMPANIES_REVOKE
        ]
      ],
      [
        PermissionEntity.DIARY_ENTRIES,
        [
          OrganizationPermission.DIARY_ENTRIES_READ,
          OrganizationPermission.DIARY_ENTRIES_CREATE,
          OrganizationPermission.DIARY_ENTRIES_UPDATE,
          OrganizationPermission.DIARY_ENTRIES_DELETE
        ]
      ],
      [
        PermissionEntity.DIARY_EXPORT,
        [
          OrganizationPermission.DIARY_EXPORT_READ,
          OrganizationPermission.DIARY_EXPORT_CREATE,
          OrganizationPermission.DIARY_EXPORT_DELETE
        ]
      ]
    ]);
  }
}

export enum PermissionEntity {
  PROJECT = 'projects',
  INVITES = 'organization_invites',
  DIARY_ENTRIES = 'diary_entries',
  DIARY_EXPORT = 'diary_export',
  PROJECT_COMPANIES = 'project_company_register',
  COMPANIES = 'company_register',
  ROLES = 'roles',
  PROJECT_ROLES = 'project_roles',
  USERS = 'users'
}
