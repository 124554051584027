import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { WebLayoutComponent } from '@web/app/modules/layout/web-layout.component';
import { LayoutModule } from 'core';

@NgModule({
  imports: [CommonModule, LayoutModule, RouterModule],
  exports: [WebLayoutComponent, LayoutModule],
  declarations: [WebLayoutComponent],
  providers: []
})
export class WebLayoutModule {}
