import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { IProject } from 'shared';

export interface ProjectsState extends EntityState<IProject> {
  allProjectsLoaded: boolean;
  selectedProjectId: string | undefined;
  lastProjectRestored: boolean;
}

export const initialState = {
  allProjectsLoaded: false,
  selectedProjectId: undefined,
  lastProjectRestored: false
};

export const projectAdapter: EntityAdapter<IProject> = createEntityAdapter<IProject>();

export const initialProjectsState: ProjectsState = projectAdapter.getInitialState(initialState);
