import { NgModule } from '@angular/core';
import { DIALOG_SERVICE_KEY, DialogModule } from 'shared';
import { WebDialogService } from '@web/app/services/web-dialog.service';

@NgModule({
  imports: [DialogModule],
  exports: [],
  declarations: [],
  providers: [
    {
      provide: DIALOG_SERVICE_KEY,
      useClass: WebDialogService
    }
  ]
})
export class WebDialogModule {}
