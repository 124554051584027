import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import { IAbstractEntry, IEntryInterface } from 'shared';

export enum EntryActionTypes {
  // single project actions
  LoadEntry = '[Entry] Load',
  LoadEntrySuccess = '[Entry] Load Success',
  LoadEntryError = '[Entry] Load Error',

  OpenEntryDialog = '[Entry] Open Add Dialog',
  AddEntry = '[Entry] Add Trigger',
  AddEntrySuccess = '[Entry] Add Success',
  UpdateEntry = '[Entry] Update',
  CloseEntry = '[Entry] Close',
  CloseEntrySuccess = '[Entry] Close Success',
  CloseEntryError = '[Entry] Close Error',

  DeleteEntry = '[Entry] Delete',
  DeleteEntrySuccess = '[Entry] Delete Success',
  DeleteEntryError = '[Entry] Delete Error',

  // all project actions
  LoadAllEntries = '[Entries] Load All',
  LoadAllEntriesSuccess = '[Entries] All Loaded Success',
  LoadAllEntriesError = '[Entries] All Loaded Error',

  // state control
  ResetEntriesState = '[Entries] Reset State',

  AddEntries = '[Entries] Add'
}

export class AddEntries implements Action {
  readonly type = EntryActionTypes.AddEntries;
  constructor(public entries: IEntryInterface[]) {}
}

/** LOAD ENTRY */
export class LoadEntry implements Action {
  readonly type = EntryActionTypes.LoadEntry;
}

export class LoadEntrySuccess implements Action {
  readonly type = EntryActionTypes.LoadEntrySuccess;
  constructor(public payload: { entry: IEntryInterface }) {}
}

export class LoadEntryError implements Action {
  readonly type = EntryActionTypes.LoadEntryError;
  constructor(public payload: { error: HttpErrorResponse }) {}
}

/** ADD ENTRY */

export class OpenEntryDialog implements Action {
  readonly type = EntryActionTypes.OpenEntryDialog;
  constructor(public payload: { entry?: IEntryInterface; date?: Date; dateSpan?: [Date, Date] }) {}
}

export class AddEntry implements Action {
  readonly type = EntryActionTypes.AddEntry;
  constructor(public entry: IAbstractEntry) {}
}

export class UpdateEntry implements Action {
  readonly type = EntryActionTypes.UpdateEntry;
  constructor(public entry: IEntryInterface) {}
}

/** CLOSE ENTRY */

export class CloseEntry implements Action {
  readonly type = EntryActionTypes.CloseEntry;
  constructor(public payload: { id: string }) {}
}

export class CloseEntrySuccess implements Action {
  readonly type = EntryActionTypes.CloseEntrySuccess;
  constructor(public payload: { id: string }) {}
}

export class CloseEntryError implements Action {
  readonly type = EntryActionTypes.CloseEntryError;
  constructor(public payload: { error: HttpErrorResponse }) {}
}

/** DELETE ENTRY */

export class DeleteEntry implements Action {
  readonly type = EntryActionTypes.DeleteEntry;
  constructor(public id: string) {}
}

/** LOAD ENTRIES */

/** STATE CONTROL */

export class ResetEntriesState implements Action {
  readonly type = EntryActionTypes.ResetEntriesState;
}

export type EntryActions =
  | LoadEntry
  | LoadEntrySuccess
  | LoadEntryError
  | OpenEntryDialog
  | AddEntry
  | UpdateEntry
  | CloseEntry
  | CloseEntrySuccess
  | CloseEntryError
  | DeleteEntry
  | ResetEntriesState
  | AddEntries;
