import { createFeatureSelector, createSelector } from '@ngrx/store';
import { memberAdapter, MemberState } from './member.state';

export const memberState = createFeatureSelector<MemberState>('member');

const { selectAll, selectEntities, selectIds, selectTotal } = memberAdapter.getSelectors();

export const selectAllProjectMembers = createSelector(memberState, selectAll);

export const selectMemberById = (memberId: string) => createSelector(memberState, state => state.entities[memberId]);

export const selectAllProjectMembersLoaded = createSelector(memberState, state => state.allMembersLoaded);
