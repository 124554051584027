import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { IUser } from 'shared';

@Component({
  selector: 'app-creator-chip',
  templateUrl: './creator-chip.component.html',
  styleUrls: ['./creator-chip.component.scss']
})
export class CreatorChipComponent implements OnInit {
  @Input() creatorId!: string;
  @Input() creator?: IUser;

  constructor(private store: Store) {}

  ngOnInit() {
    // this.store.select(selectMemberById(this.creatorId)).subscribe(member => (this.member = member));
  }
}
