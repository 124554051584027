import { Inject, Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ErrorService, ResourceService } from 'data-access';
import { RESOURCE_KEY } from 'shared';

@Injectable()
export class EntryEffects {
  constructor(
    private actions$: Actions,
    @Inject(RESOURCE_KEY) private resourceService: ResourceService,
    private store: Store,
    private errorService: ErrorService
  ) {}

  // closeDiaryEntryEffect = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType<CloseEntry>(EntryActionTypes.CloseEntry),
  //     withLatestFrom(this.store.pipe(select(selectSelectedProject))),
  //     mergeMap(([action, project]) =>
  //       this.resourceService.closeEntry(project?.id!, action.payload.id).pipe(
  //         map(entry => new CloseEntrySuccess({ id: action.payload.id })),
  //         catchError(err => of(new CloseEntryError(err)))
  //       )
  //     )
  //   )
  // );

  // closeDiaryEntryErrorEffect = createEffect(
  //   () =>
  //     this.actions$.pipe(
  //       ofType<CloseEntryError>(EntryActionTypes.CloseEntryError),
  //       map(action => this.errorService.showError('Eintrag konnte nicht abgeschlossen werden', action.payload.error))
  //     ),
  //   { dispatch: false }
  // );

  // deleteDiaryEntryEffect = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType<DeleteEntry>(EntryActionTypes.DeleteEntry),
  //     withLatestFrom(this.store.pipe(select(selectSelectedProject))),
  //     tap(console.log),
  //     mergeMap(([action, project]) =>
  //       this.resourceService.deleteEntry(project.id, action.payload.id).pipe(
  //         map(entry => new DeleteEntrySuccess({ id: action.payload.id })),
  //         catchError(err => of(new DeleteEntryError(err)))
  //       )
  //     )
  //   )
  // );

  // deleteDiaryEntryErrorEffect = createEffect(
  //   () =>
  //     this.actions$.pipe(
  //       ofType<DeleteEntryError>(EntryActionTypes.DeleteEntryError),
  //       map(action => this.errorService.showError('Eintrag konnte nicht gelöscht werden', action.payload.error))
  //     ),
  //   { dispatch: false }
  // );
}
