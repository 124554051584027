import { NgModule } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { CalendarModule } from 'primeng/calendar';
import { InputNumberModule } from 'primeng/inputnumber';
import { RadioButtonModule } from 'primeng/radiobutton';
import { MessagesModule } from 'primeng/messages';
import { SkeletonModule } from 'primeng/skeleton';

const MODULES = [
  ButtonModule,
  InputTextareaModule,
  DropdownModule,
  InputTextModule,
  CalendarModule,
  InputNumberModule,
  RadioButtonModule,
  MessagesModule,
  SkeletonModule
];

@NgModule({
  imports: [...MODULES],
  exports: [...MODULES]
})
export class PrimeSharedModule {}
