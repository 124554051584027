import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ProjectPlansRoutingModule } from './project-plans-routing.module';
import { ProjectPlansComponent } from './project-plans.component';

@NgModule({
  imports: [CommonModule, FormsModule, ProjectPlansRoutingModule],
  declarations: [ProjectPlansComponent]
})
export class ProjectPlansModule {}
