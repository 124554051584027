import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ICompany } from 'shared';

export interface CompaniesState extends EntityState<ICompany> {
  allCompaniesLoaded: boolean;
  loading: boolean;
}

export const companiesAdapter: EntityAdapter<ICompany> = createEntityAdapter<ICompany>();

export const initialCompaniesState: CompaniesState = companiesAdapter.getInitialState({
  allCompaniesLoaded: false,
  loading: false
});
