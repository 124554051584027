import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

export interface DiaryState extends EntityState<void> {
  topDate: Date | undefined;
  bottomDate: Date | undefined;
}

export const diaryAdapter: EntityAdapter<any> = createEntityAdapter<any>({});

export const initialDiaryState: DiaryState = diaryAdapter.getInitialState({
  topDate: undefined,
  bottomDate: undefined
});
